import DOMPurify from "dompurify";
import React, { Fragment, PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFActionStatus } from "../../../../constants/Common/Constants";
import { getTaskDisplayDateAndBadge } from "../TaskHelper";
import { TaskRow } from "../TaskRow";

interface IProps {
  taskRow?: TaskRow;
  onClickEditTask?: () => void;
}

function RAFTaskCardContent({ taskRow, ...props }: PropsWithChildren<IProps>) {
  const onClickEditTask = () => {
    if (props.onClickEditTask) {
      props.onClickEditTask();
    }
  };

  if (isNotNullAndUndefined(taskRow) && isNotNullAndUndefined(taskRow.UID)) {
    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
      isNotNullAndUndefined(taskRow) ? taskRow.TaskDate : null,
      isNotNullAndUndefined(taskRow) ? taskRow.TaskType : null
    );
    const taskDate = taskDisplayDateAndBadge.taskDate;
    const dateBadge = taskDisplayDateAndBadge.dateBadge;
    const itemHeaderClass =
      "itemHeader ecllipseFirstLine text-decoration-none px-1";

    let activityItems = "activityItems flex-row";
    if (taskRow.IsNextTask) {
      activityItems = "activityItems flex-row teamNextAction";
    }

    return (
      <div
        className={activityItems}
        // onClick={() => {
        //   taskRow.TaskStatus === RAFActionStatus.Planned
        //     ? onClickEditTask()
        //     : showWarningToast("This task has been completed");
        // }}
        onClick={onClickEditTask}
      >
        <div className="w-100 d-flex align-items-center justify-space-around">
          {/* {taskRow.TaskStatus !== RAFActionStatus.Planned ?
                        <div className="md-checkbox ms-2 me-2 e-info d-flex align-items-center mt-0">
                            <input type="checkbox" className="" id={taskRow.UID} name={'Task_' + taskRow.UID} style={{ cursor: "pointer" }} disabled checked />
                            <label className="" htmlFor={taskRow.UID} style={{ cursor: "pointer" }}></label>
                        </div>
                        :
                        null
                    } */}
          <div className="text-nowrap">
            {taskRow.TaskStatus === RAFActionStatus.Completed ? (
              <span className="activityDateSpanSection ms-2 badge badge-completed">
                Completed
              </span>
            ) : (
              <span
                className={"activityDateSpanSection ms-2 badge " + dateBadge}
              >
                {taskDate}
              </span>
            )}
          </div>
          <span
            className={itemHeaderClass}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(taskRow.Title),
            }}
          ></span>
        </div>
        <div className="hidden">
          <div className="d-flex align-items-center taskActionBtns">
            <span
              className="fal fa-pencil me-2"
              onClick={() => onClickEditTask()}
            ></span>
          </div>
        </div>
      </div>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

export default React.memo(RAFTaskCardContent);
