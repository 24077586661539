import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

// export class UserTeamCategory {
//     UID?: string;
//     Value?: string;
//     Catagory?: string;
// }

//export class UserRow extends RAFEntityBase implements ILookupRow {
export class IUserRow {
    //Id?: number;
    UID?: string;
    FirstName?: string;
    LastName?: string;
    IsActive?: boolean;
    UserName?: string;
    Gender?: string;
    EmailInfoWork?: string;
    EmailInfoPersonal?: string;
    PhonePersonal?: string;
    PhoneWork?: string;
    PhoneHome?: string;
    PhoneTollFree?: string;
    PhoneFax?: string;
    AddressLine1?: string;
    AddressLine2?: string;
    AddressPostalCode?: string;
    AddressCity?: string;
    AddressState?: string;
    AddressCountry?: string;
    LoginStatus?: string;
    MysqlRefId?: number;
    RoleListJson?: string;
    RoleListJsonText?: any;
    UserId?: string;
    UserIdNumber?: number;
    UserDisplayName?: string;
    PermissionGroupUID?: string;
    PermissionGroup?: string;
    UserType?: string;
    RelatedToType?: string;
    RelatedToUID?: string;
    RelatedTo?: string;

    //AddressCityUID?: string;
    //AddressStateUID?: string;
    //AddressCountryUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;

    InviteUser?: boolean;
}

export class UserRow extends IUserRow implements RAFEntityBase, ILookupRow {
    getClassName?() { //use entityname
        return 'user';
    }
    getIdField?(): string {
        return propertyOf<UserRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "User/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "User/Save";
    }
    getGroupByUrl?(): string {
        return "User/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "User/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}
