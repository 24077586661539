import { ContentType } from "../../../constants/Common/Constants";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RelatedTo } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { UserGroupRow } from "./UserGroupRow";

export class TeamMembersRetrieveRequest {
    EntityId?: string;
    EntityName?: string;
    RecordUID?: string;
}

export function getMyTeams() {
    return new Promise<UserGroupRow[]>((resolve) => {
        // let listServiceRequest = new ListServiceRequest();
        const listServiceRequest = new ListServiceRequest();
        repositoryActions
            .postDataAndGetResponse(
                "Team/MyTeams",
                listServiceRequest,
                null,
                ContentType.applicationJson
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)
                ) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => error);
    });
}

export function getAllTeams() {
    return new Promise<UserGroupRow[]>((resolve) => {
        // let listServiceRequest = new ListServiceRequest();
        const listServiceRequest = new ListServiceRequest();
        repositoryActions
            .postDataAndGetResponse(
                "Team/List",
                listServiceRequest,
                null,
                ContentType.applicationJson
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)
                ) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => error);
    });
}

export const getAllTeamMembersByEntityName = (entityName: string, relatedToUID: string) => {
    return new Promise<RelatedTo[]>((resolve) => {
        if (isNotNullAndUndefined(entityName) && isNotNullAndUndefined(relatedToUID)) {
            let retrieveRequest: TeamMembersRetrieveRequest = new TeamMembersRetrieveRequest();
            retrieveRequest.EntityName = entityName;
            retrieveRequest.RecordUID = relatedToUID;

            repositoryActions.postDataAndGetResponse('Team/TeamMembersByObjectGuid', retrieveRequest, null, ContentType.applicationJson)
                .then((response) => {
                    if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
                        resolve(response.data.Entities);
                    } else {
                        resolve(null);
                    }
                }).catch(error => error);
        } else {
            resolve(null);
        }
    });

};