import {
  RadioButtonComponent
} from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { SetGridState } from "../../../../RAFComponents/helpers/AppHelper";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import {
  completedTaskFilteredValue, myTaskFilteredValue, selectedTaskView
} from "../TaskHelper";
import {
  TaskDropdownItems,
  completedTaskDropdownItems, myTaskDropdownItems
} from "../TaskRow";

interface IProps {
  relatedToUID?: string;
  listPageGridID?: string;
  relatedTo?: string;
  relatedToType?: string;
  selectedTaskDropdownValue?: string;
}

function RAFTaskViewDropdown({ ...props }: PropsWithChildren<IProps>) {
  const selectedTaskDropdownValue = isNotNullAndUndefined(props.selectedTaskDropdownValue) ? props.selectedTaskDropdownValue : useRecoilValue(selectedTaskView);
  const [myTaskTabSelectedFilter, setmyTaskTaskFilteredValue] = useRecoilState(myTaskFilteredValue);
  const [completedTabfilterValue, setCompletedTaskFilteredValue] = useRecoilState(completedTaskFilteredValue);
  //const selectedTagNameStateValue: TagRow = useRecoilValue(atomSelectedTagName(RAFEntityName.Task));

  //const setSelectedTaskView = useSetRecoilState(selectedTaskView);
  //const setmyTaskTaskFilteredValue = useSetRecoilState(myTaskFilteredValue);
  // const setCompletedTaskFilteredValue = useSetRecoilState(
  // completedTaskFilteredValue
  //);

  //const selectedTaskDropdownValue = useRecoilValue(selectedTaskView);
  //const completedTabfilterValue = useRecoilValue(completedTaskFilteredValue);
  //const myTaskTabSelectedFilter = useRecoilValue(myTaskFilteredValue);

  //const rafObject = React.useContext(RAFObjectContext);
  //const entityRow = React.useContext(RAFEntityContext);
  //const entity = entityRow.entity;

  //const [taskSummaryDataCount, setTaskSummaryDataCount] = useState(null);

  //let indexVal = Guid.newGuid();

  // useEffect(() => {
  //   refresh(rafObject.objectId);
  // }, [rafObject.objectId]);

  // const refresh = (relatedToUID) => {
  //   if (
  //     selectedTaskDropdownValue === TaskDropdownItems.MyTask &&
  //     myTaskTabSelectedFilter.includes(MyTaskOptions.All)
  //   ) {
  //     getMyTaskCount(relatedToUID).then((taskSummaryDataCount) => {
  //       setTaskSummaryDataCount(taskSummaryDataCount);
  //     });
  //   }
  // };

  // function setTaskFilterClickEvents(selectedTaskDropdownItem) {
  //   setSelectedTaskView(selectedTaskDropdownItem);
  //   if (Browser.isDevice === true) {
  //     if (selectedTaskDropdownItem === TaskDropdownItems.MyTask) {
  //       for (var i = 0; i < myTaskDropdownItems.length; i++) {
  //         const objItem = myTaskDropdownItems[i];
  //         const objElement: HTMLElement = document.getElementById(
  //           `myTaskDropdown_item_${ConvertSystemName(objItem.id)}`
  //         );
  //         if (isNotNullAndUndefined(objElement)) {
  //           objElement.onclick = () => {
  //             onMyTaskItemClicked(objItem);
  //           };
  //         }
  //       }
  //     } else if (selectedTaskDropdownItem === TaskDropdownItems.Completed) {
  //       for (var i = 0; i < completedTaskDropdownItems.length; i++) {
  //         const objItem = completedTaskDropdownItems[i];
  //         const objElement: HTMLElement = document.getElementById(
  //           `completedTaskDropdown_item_${ConvertSystemName(objItem.id)}`
  //         );
  //         if (isNotNullAndUndefined(objElement)) {
  //           objElement.onclick = () => {
  //             onCompletedTaskItemClicked(objItem);
  //           };
  //         }
  //       }
  //     }
  //   }
  // }

  // function onCompletedTaskItemClicked(objItem) {
  //   if (isNotNullAndUndefined(objItem.text)) {
  //     let customDropdownMenu = document.querySelectorAll(
  //       "." + "customViewDropdownBtn"
  //     );
  //     if (isNotNullAndUndefined(customDropdownMenu)) {
  //       customDropdownMenu.forEach((item) => {
  //         item.classList.remove("e-popup-open");
  //         item.classList.add("e-popup-close");
  //       });
  //     }
  //     if (completedTabfilterValue !== objItem.id)
  //       onChangeCompletedFilter(objItem.id);
  //   }
  // }

  // function onMyTaskItemClicked(objItem) {
  //   if (isNotNullAndUndefined(objItem.text)) {
  //     let customDropdownMenu = document.querySelectorAll(
  //       "." + "customViewDropdownBtn"
  //     );
  //     if (isNotNullAndUndefined(customDropdownMenu)) {
  //       customDropdownMenu.forEach((item) => {
  //         item.classList.remove("e-popup-open");
  //         item.classList.add("e-popup-close");
  //       });
  //     }
  //     onChangeMyTaskFilter(
  //       objItem.text,
  //       isNotNullAndUndefined(myTaskTabSelectedFilter) &&
  //         myTaskTabSelectedFilter.includes(objItem.text)
  //         ? false
  //         : true
  //     );
  //   }
  // }

  // const onChangeMyTaskFilter1 = (label, value?: boolean) => {
  //   let myTaskSelectedFilter = [...myTaskTabSelectedFilter];
  //   if (value === true) {
  //     myTaskSelectedFilter.push(label);
  //     if (label === MyTaskOptions.All) {
  //       myTaskSelectedFilter = [MyTaskOptions.All];
  //     } else if (
  //       !myTaskSelectedFilter.includes(MyTaskOptions.All) &&
  //       myTaskSelectedFilter.length === myTaskDropdownItems.length - 1
  //       // myTaskSelectedFilter.length - 1
  //     ) {
  //       myTaskSelectedFilter = [MyTaskOptions.All];
  //     } else {
  //       myTaskSelectedFilter = myTaskSelectedFilter.filter(
  //         (x) => x !== MyTaskOptions.All
  //       );
  //     }
  //   }
  //   else {
  //     if (myTaskSelectedFilter.includes(label)) {
  //       if (label === MyTaskOptions.All && myTaskSelectedFilter.length === 1) {
  //       }
  //       else if (myTaskSelectedFilter.length === 1) {
  //         myTaskSelectedFilter = myTaskSelectedFilter.filter(
  //           (x) => x !== label
  //         );
  //         myTaskSelectedFilter.push(MyTaskOptions.All);
  //       }
  //       else {
  //         myTaskSelectedFilter = myTaskTabSelectedFilter.filter(
  //           (x) => x !== label
  //         );
  //       }
  //     }
  //   }
  //   setTimeout(() => {
  //     setmyTaskTaskFilteredValue(myTaskSelectedFilter);
  //   }, 500);
  //   // console.log("myTaskSelectedFilter1", myTaskSelectedFilter)
  // };

  // const taskFilterItemClick = (e: ToolbarClickEventArgs) => {
  //   if (
  //     isNotNullAndUndefined(e) &&
  //     isNotNullAndUndefined(e.item) &&
  //     isNotNullAndUndefined(e.item.id)
  //   ) {
  //     if (selectedTaskDropdownValue !== e.item.id) {
  //       setTaskFilterClickEvents(e.item.id);
  //     }
  //   }
  // };

  //create task starts

  //let createTaskDialogRef = useRef<DialogComponent>(null);
  // function createTaskDialogOpen() {
  //   createTaskDialogRef && createTaskDialogRef.current.show();
  //   document.body.classList.add("overflow-hidden");
  // }

  // function createTaskDialogClose() {
  //   createTaskDialogRef && createTaskDialogRef.current.hide();
  //   document.body.classList.remove("overflow-hidden");
  // }

  // function refreshOnSaveTask() {
  //   createTaskDialogClose();
  //   //refresh(rafObject.objectId);
  //   setSelectedTaskView(TaskDropdownItems.RecentlyUpdated);
  // }

  // function createTaskContent() {
  //   return (
  //     <ManageTask
  //       onSave={refreshOnSaveTask.bind(this)}
  //       onClose={createTaskDialogClose.bind(this)}
  //       moduleName={entity ? entity.EntityName : "dashboard"}
  //       relatedToUID={rafObject ? rafObject.objectId : null}
  //       relatedTo={rafObject ? rafObject.objectName : null}
  //       relatedToType={entity ? entity.EntityName : null}
  //       isActive
  //       isCreateTask
  //     />
  //     // <div>Test</div>
  //   );
  // }

  // const showTaskFilterTab =
  //   isNotNullAndUndefined(selectedTagNameStateValue) &&
  //     isNotNullAndUndefined(selectedTagNameStateValue.TagName) ? false : true;
  // if (showTaskFilterTab === true) {

  const onChangeMyTaskFilter = (label, value?: boolean) => {
    if (isNotNullAndUndefined(props.listPageGridID)) {
      SetGridState(props.listPageGridID, null, null);
    }
    setmyTaskTaskFilteredValue([label]);
  };

  const onChangeCompletedFilter = (value) => {
    if (isNotNullAndUndefined(props.listPageGridID)) {
      SetGridState(props.listPageGridID, null, null);
    }
    setCompletedTaskFilteredValue(value);
  };

  return (
    <div className="row gx-2 align-items-center flex-nowrap" id="task_filterBtn"  >
      {/* <div className={!Browser.isDevice === true ? "col-auto" : "col col-md-auto"}>
        <DropDownButtonComponent
          items={taskFilterDropdownItem}
          cssClass={
            "primary-custom-button customViewDropdownBtn task-view-dropdownBtn btn-w-100 justify-content-between d-flex align-items-center"
          }
          select={taskFilterItemClick.bind(this)}
          id="taskFilterViewDropdownBtn"
        >
          <span className="ecllipseFirstLine">{selectedTaskDropdownValue}</span>
        </DropDownButtonComponent>
      </div> */}
      {/* {!Browser.isDevice === true && */}

      <div className="col" id={`${selectedTaskDropdownValue}`} >
        <div className="row g-2 tab-radio-btn">
          {(selectedTaskDropdownValue === TaskDropdownItems.MyTask ||
            selectedTaskDropdownValue === TaskDropdownItems.AssignedToMe
          ) && (
              myTaskDropdownItems.map((item, index) => {
                return (
                  <div key={index} className="col-auto" id={`${index}_${item.id}`} >
                    <RadioButtonComponent
                      id={`${item.id}_${index}`}
                      value={item.id}
                      label={item.text}
                      name="myTaskAdditionalfilter"
                      checked={
                        myTaskTabSelectedFilter.findIndex(x => x === item.id) >= 0
                          ? true
                          : false
                      }
                      change={(e) => {
                        onChangeMyTaskFilter(item.id);
                      }}
                    />
                  </div>
                );
              })
            )}
          {selectedTaskDropdownValue === TaskDropdownItems.Completed && (
            completedTaskDropdownItems.map((item, index) => {
              return (
                <div key={index} className="col-auto" id={`${index}_${item.id}`} >
                  <RadioButtonComponent
                    id={`${item.id}_${index}`}
                    value={item.id}
                    label={item.text}
                    name="completedAdditionalfilter"
                    checked={
                      completedTabfilterValue === item.id
                        ? true
                        : false
                    }
                    change={(e) => {
                      onChangeCompletedFilter(item.id);
                    }}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
  // } else {
  //   return (
  //     <div></div>
  //   );
  // }
}

export default React.memo(RAFTaskViewDropdown);
