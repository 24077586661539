import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import moment from "moment";
import React, { Fragment, PropsWithChildren, Reducer, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  hexToRGBA,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import {
  BrowserIsDevice,
  RAFActionStatus,
  RAFButtonConstant,
  RAFStatusNameWithColor
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import ManageTask from "../ManageTask";
import {
  completeTaskAndRemoveFromFocusList
} from "../TaskHelper";
import { TaskRow } from "../TaskRow";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";

interface IProps {
  taskRow?: TaskRow;
  onSave?: () => void;
  allowEdit?: boolean;
  allowDelete?: boolean;
  relatedToType?: string;
  relatedToUID?: string;
  shiftStatus?: string;
}

interface IState {
  showManageTaskContent: boolean;
  checkboxDivkey: number;
}

function RAFTaskCustomCardContent({
  taskRow,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showManageTaskContent: false,
      checkboxDivkey: Math.random(),
    }
  );

  //manage_task start
  const onClickManageTask = () => {
    if (isNotNullAndUndefined(props.shiftStatus)) {
      if (
        props.shiftStatus === RAFActionStatus.Unassigned ||
        props.shiftStatus === RAFActionStatus.Planned
      ) {
        showWarningToast(
          "The task cannot be completed until the shift has started."
        );
      } else {
        setState({
          showManageTaskContent: true,
        });
      }
    } else {
      setState({
        showManageTaskContent: true,
      });
    }
  };

  const manageTaskContent = () => {
    if (state.showManageTaskContent === true) {
      return (
        <ManageTask
          onSave={refreshOnTaskUpdate.bind(this)}
          onDelete={refreshOnTaskUpdate.bind(this)}
          onClose={onCloseManageTaskContent.bind(this)}
          isDashboard
          complete={(taskRow, taskStatus) =>
            completeTaskClicked(taskRow, taskStatus)
          }
          contextModuleName={props.relatedToType}
          isActive
          relatedCommentsUpdated={(value) => {
            refreshRelatedComments();
          }}
          isCreateTask={false}
          taskUID={taskRow.UID}
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnTaskUpdate = () => {
    onCloseManageTaskContent();
    if (isNotNullAndUndefined(props.onSave)) {
      props.onSave();
    }
  };

  const onCloseManageTaskContent = () => {
    setState({ showManageTaskContent: false, checkboxDivkey: Math.random() });
  };

  const refreshRelatedComments = () => {
    triggerRAFEvent(
      `${RAFEventName.ReloadCommentsContent}_${props.relatedToUID}`,
      {
        moduleName: `${props.relatedToType}`,
      }
    );
  };

  //manage_task

  //completeTask start
  const completeTaskClicked = (
    selectedTaskRow: TaskRow,
    taskStatus: string
  ) => {
    onCloseManageTaskContent();
    let progressDiv = showProgress("body", true);
    selectedTaskRow.TaskStatus = taskStatus;

    completeTaskAndRemoveFromFocusList(selectedTaskRow)
      .then((response) => {
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(props.onSave)) {
          props.onSave();
        }
      })
      .catch((error) => error);
  };
  //completeTask end

  const onChangeCheckBoxValue = (checked) => {
    if (checked === true) {
      onClickManageTask();
    }
  };

  const cardFooterTemplate = () => {
    let taskStatus = isNotNullAndUndefined(taskRow.TaskStatus)
      ? taskRow.TaskStatus
      : null;
    let statusNameWithColor =
      isNotNullAndUndefined(taskStatus) &&
        isNotNullAndUndefined(RAFStatusNameWithColor[taskStatus])
        ? RAFStatusNameWithColor[taskStatus]
        : "";
    if (
      !props.allowEdit &&
      !props.allowDelete
    ) {
      return;
    }
    return (
      <div className="care_shift_log_footer">
        <div>
          <span
            className="raf_badge raf_sm"
            style={{
              color: statusNameWithColor.Color,
              backgroundColor: hexToRGBA(statusNameWithColor.Color, 0.1),
              border: `1px solid ${hexToRGBA(
                statusNameWithColor.Color,
                0.5
              )}`,
            }}
          >
            {statusNameWithColor.DisplayName ?? taskStatus}
          </span>
        </div>
        <div className="ms-auto">
          <div className="row gx-2">
            {props.allowEdit && (
              <div className="col-auto">
                <RAFButtonComponent
                  action={RAFButtonConstant.Edit}
                  iconBtn
                  className="btn_state_warning white e-round"
                  id={`ViewShiftDetailsBtn${taskRow.UID}`}
                  {...(props.allowEdit === true
                    ? { onClick: onClickManageTask }
                    : {})}
                  enableToolTip={false}
                />
              </div>
            )}
            {/* {props.allowDelete && (
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Delete}
                      iconBtn
                      className="btn_state_danger outline e-round"
                      id={`ViewShiftDetailsBtn${ taskRow.UID}`}
                      onClick={() => onClickDeleteCareShiftLog()}
                      enableToolTip={false}
                    />
                  </div>
                )} */}
          </div>
        </div>
      </div>
    );
  };
  // const cardFooterTemplate = () => {
  //   let taskStatus = isNotNullAndUndefined(taskRow.TaskStatus)
  //     ? taskRow.TaskStatus
  //     : null;
  //   let statusNameWithColor =
  //     isNotNullAndUndefined(taskStatus) &&
  //       isNotNullAndUndefined(RAFStatusNameWithColor[taskStatus])
  //       ? RAFStatusNameWithColor[taskStatus]
  //       : "";

  //   return (
  //     <div className="section__secondary__header border-top border-bottom-0 raf-sm justify-content-between">
  //       <div>
  //         <span className="profile_card_secondary_text_value">Status</span>
  //       </div>
  //       <div className="d-flex align-items-center">
  //         <span
  //           className="profile_card_details_label text-nowrap"
  //           style={{ color: statusNameWithColor.Color }}
  //         >
  //           {statusNameWithColor.DisplayName ?? "Not set"}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };

  const careShiftLogTemplate = () => {
    return (
      <div className="row care_shift_log_header">
        <div className="col">
          <div className="d-flex align-items-center">
            <div className="care_shift_log_header_text">
              <span className="care_shift_log_header_text">{taskRow.Title}</span>
            </div>
            <div className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="3"
                height="4"
                viewBox="0 0 3 4"
                fill="none"
              >
                <circle cx="1.5" cy="2" r="1.5" fill="#A3A3A3" />
              </svg>
            </div>
            <RAFRecordInfo
              createdBy={taskRow.CreatedBy}
              createdDate={taskRow.CreatedDate}
              modifiedDate={taskRow.ModifiedDate}
              modifiedBy={taskRow.ModifiedBy}
              lastActivityDate={
                taskRow.LastActivityDate
              }
              {...!BrowserIsDevice ? { content: "Updated: ", hideClockIcon: true } : { hideClockIcon: false }}
              dropdownBtnClassName="timeline__item__recordInfo p-0"
              spanContentClassName="content_neutral_base medium recordInfo_text"
            />
          </div>
        </div>
      </div>
    );
  };

  if (isNotNullAndUndefined(taskRow) && isNotNullAndUndefined(taskRow.UID)) {

    let activityItems = "activityItems flex-row";
    if (taskRow.IsNextTask) {
      activityItems = "activityItems flex-row teamNextAction";
    }

    const taskDate = convertUTCDateToLocalTimezone(taskRow.TaskDate);
    const completedDate = convertUTCDateToLocalTimezone(taskRow.CompletedDate);

    const displayDate = isNotNullAndUndefined(completedDate) ? completedDate : taskDate;

    return (
      <>
        <div className="timeline__item" key={taskRow.UID}>
          <div className="row gx-2 gx-md-3">
            <div className="col-auto pe-0">
              <div className="timeline__date__container">
                <div className="timeline__time">
                  {IsNotNullOrWhiteSpace(displayDate) ? (
                    <>
                      <span>{moment(displayDate).format("hh:mm")}</span>
                      <span className="secondary-text">{` ${moment(
                        displayDate
                      ).format("A")}`}</span>
                    </>
                  ) : (
                    <span className="body_3_dark_bold">NA</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="timeline__item__circle"></div>
            </div>
            <div className="col">
              <CustomCardWidget
                {...(props.allowEdit === true
                  ? { onClick: onClickManageTask }
                  : {})}
                footerTemplate={cardFooterTemplate()}
                cardClassName={`overflow-hidden${props.allowEdit === true ? " pointer" : ""
                  }`}
              >
                <div className="row gy-1 gx-0">
                  <div className="col-12 d-flex align-items-center">
                    <div className="row gx-2">
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="col-auto" key={state.checkboxDivkey}>
                          <CheckBoxComponent
                            name={"UID"}
                            value={taskRow.UID}
                            // cssClass="e-checkbox-light e-checkbox-lg raf-round show-check-icon"
                            cssClass="e-checkbox-light"
                            change={(e) => onChangeCheckBoxValue(e.checked)}
                          />
                        </div>
                      )}
                      <div className="col">
                        {careShiftLogTemplate()}
                      </div>
                    </div>
                  </div>
                  <>
                    {isNotNullAndUndefined(taskRow.Description) ? (
                      <div className="col-12">
                        <div style={{ margin: "0 -0.5rem" }}>
                          <CustomCardWidget
                            cardContentClassName="p-2"
                          >
                            <div className="row g-3">
                              {IsNotNullOrWhiteSpace(taskRow.Description) ? (
                                <div className="col-12">
                                  <div className="row gx-0 gy-1">
                                    <div className="col-12">
                                      <span
                                        className={
                                          "profile_card_details_label ecllipseFirstLine"
                                        }
                                      >{`Description :`}</span>
                                    </div>

                                    <div className="col-12">
                                      <div
                                        className={`description-text details-value`}
                                      >
                                        <span
                                          className={"description-text details-value"}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              taskRow.Description !== null
                                                ? DOMPurify.sanitize(
                                                  taskRow.Description
                                                )
                                                : "Not set",
                                          }}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </CustomCardWidget>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {false && <> <div className="col-12">
                      <RAFDetailsValueWithSeparator outerClassName="h-26">
                        <RAFDetailFieldCustom
                          value={
                            isNotNullAndUndefined(taskRow.TaskType)
                              ? taskRow.TaskType
                              : "Not set"
                          }
                          title="Status"
                          moduleName={RAFEntityName.Task}
                          isColorOption
                          field="TaskType"
                          showLabel={false}
                          mode="outlineView"
                        />
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          hideClockIcon
                          dropdownBtnClassName="timeline__item__recordInfo"
                          spanContent={'updated:'}
                          spanContentClassName="content_neutral_base medium recordInfo_text"
                        />
                      </RAFDetailsValueWithSeparator>
                    </div></>
                    }
                  </>
                </div>
              </CustomCardWidget>
              {false && (
                <div className={`row gy-1 gx-0`}>
                  <div className="col-12">
                    <div className="d-flex align-items-baseline">
                      {taskRow.TaskStatus === RAFActionStatus.Planned && (
                        <div className="me-3">
                          <CheckBoxComponent
                            name={"UID"}
                            value={taskRow.UID}
                            // cssClass="e-checkbox-light e-checkbox-lg raf-round show-check-icon"
                            cssClass="e-checkbox-light"
                            change={(e) => onChangeCheckBoxValue(e.checked)}
                          />
                        </div>
                      )}
                      <span
                        className="timeline__title pointer"
                        {...(props.allowEdit === true
                          ? { onClick: onClickManageTask }
                          : {})}
                      >
                        {isNotNullAndUndefined(taskRow.Title)
                          ? taskRow.Title
                          : "Title Not set"}
                      </span>
                      {/* {taskRow.TaskType === RAFTaskType.ToDo && (
                      <div
                        className="custom_key_value e-badge"
                        style={{
                          background: "rgb(0, 150, 136)",
                          color: "white",
                        }}
                      >
                        <span>{RAFTaskType.ToDo} </span>
                      </div>
                    )}
                    {taskRow.TaskType === RAFTaskType.Appointment && (
                      <div
                        className="custom_key_value e-badge"
                        style={{
                          background: "rgb(236, 64, 122)",
                          color: "white",
                        }}
                      >
                        <span> {RAFTaskType.Appointment} </span>
                      </div>
                    )} */}
                    </div>
                  </div>

                  {IsNotNullOrWhiteSpace(taskRow.Description) ? (
                    <div className="col-12">
                      <div className="timeline__description">
                        <span
                          className="description-text"
                          dangerouslySetInnerHTML={{
                            __html:
                              taskRow !== null
                                ? DOMPurify.sanitize(taskRow.Description)
                                : undefined,
                          }}
                        ></span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-12">
                    <RAFDetailsValueWithSeparator outerClassName="h-26">
                      <RAFDetailFieldCustom
                        value={
                          isNotNullAndUndefined(taskRow.TaskType)
                            ? taskRow.TaskType
                            : "Not set"
                        }
                        title="Status"
                        moduleName={RAFEntityName.Task}
                        isColorOption
                        field="TaskType"
                        showLabel={false}
                      //mode="outlineView"
                      />
                      <RAFRecordInfo
                        createdBy={taskRow.CreatedBy}
                        createdDate={taskRow.CreatedDate}
                        modifiedDate={taskRow.ModifiedDate}
                        modifiedBy={taskRow.ModifiedBy}
                        lastActivityDate={taskRow.LastActivityDate}
                        hideClockIcon
                        dropdownBtnClassName="timeline__item__recordInfo"
                      />
                    </RAFDetailsValueWithSeparator>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {state.showManageTaskContent == true && (
          <DialogComponent
            visible={state.showManageTaskContent}
            cssClass="rightDialog createEditForm full-height"
            id="TaskDetailsSection"
            content={manageTaskContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={onCloseManageTaskContent.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </>
    );
  } else {
    return <Fragment></Fragment>;
  }
}

export default React.memo(RAFTaskCustomCardContent);
