import { Browser } from "@syncfusion/ej2-base";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  Suspense,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useRecoilValue } from "recoil";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFGrid4 from "../../../../RAFComponents/Grid/RAFGrid4";
//const RAFGrid4 = React.lazy(() => import('../../../../RAFComponents/Grid/RAFGrid4'));
import dashboard_upcoming_es from "../../../../assets/ES/dashboard_upcoming_es.svg";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { getGridColumnTemplatesByModule } from "../../../../helpers/RAFGridTemplates";
// import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGridColumn from "../../../../RAFComponents/Grid/RAFGridColumn";
import {
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
  lazyRetry,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { LookUpRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  Constants,
  RAFActionStatus,
  RAFDatePresets,
  RAFTaskType,
  SFColumnType,
} from "../../../../constants/Common/Constants";
// import RAFReportDataList from "../../../RAFComponents/List/RAFReportDataList";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { BusinessProcessRow } from "../../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import RAFProcessContextProvider from "../../../../RAFMaster/RMModules/BusinessProcess/Process/RAFProcessContextProvider";
import { BusinessProcessStepRow } from "../../../../RAFMaster/RMModules/BusinessProcess/Step/BusinessProcessStepRow";
import RAFBusinessProcessStepDetails from "../../../../RAFMaster/RMModules/BusinessProcess/Step/RAFBusinessProcessStepDetails";
import { BusinessProcessStepTemplateRow } from "../../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import {
  CompleteTaskAndStep,
  GetBPStepsAndTemplateStepsById,
  RetrieveBusinessProcessStepById,
} from "../../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import {
  HeaderTabItems,
  RAFHeaderTabItemsRow,
  atomSelectedLeftMenuTab,
} from "../../../../components/shared/NavigationMenu/NavigationHelper";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { RAFBPStepStatus } from "../../../../constants/Common/RMConstants";
import { changeGridContentHeight } from "../../../../helpers/ACutils";
import { RAFEntityContext } from "../../../Common/Providers/RAFEntityProvider";
import { RAFObjectContext } from "../../../Common/Providers/RAFObjectContextProvider";
import { atomSelectedTagName } from "../../Tag/TagHelper";
import ManageTask from "../ManageTask";
import {
  completeTaskAndRemoveFromFocusList,
  completedTaskFilteredValue,
  myTaskFilteredValue,
} from "../TaskHelper";
import { ConvertTaskRowToTaskDto } from "../TaskMappingRegister";
import { MyTaskOptions, TaskDropdownItems, TaskRow } from "../TaskRow";
import RAFTaskViewDropdown from "./RAFTaskViewDropdown";
import { useNavigate } from "react-router-dom";

const RAFGridTemplates = React.lazy(() =>
  lazyRetry(
    () => import("../../../../helpers/RAFGridTemplates"),
    "RAFGridTemplates"
  )
);

const RAFFieldStateProvider = React.lazy(() =>
  lazyRetry(
    () => import("../../../../RAFComponents/Grid/RAFFieldStateProvider"),
    "RAFFieldStateProvider"
  )
);

interface IProps {
  mode?: string;
  listPageGridID?: string;
  showRelatedToField?: boolean;
  refreshOnUpdateTask?: () => void;
  refreshOnDeleteTask?: () => void;
  relatedCommentsUpdated?: (relatedCommentsUpdated: boolean) => void;
  isRelatedSection?: boolean;
  selectedTaskDropdownValue: string;
  hideTabHeader?: boolean;
  secondaryRelatedToUID?: string;
}

interface IState {
  bpRequest: {
    currentBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
    businessProcessRow: BusinessProcessRow;
    bpTemplateSteps: BusinessProcessStepTemplateRow[];
  };
  selectedTaskUID: string;
  showUpdateTaskDialog: boolean;
  showBPStepContent: boolean;
}

function RAFTaskIndexContent({ ...props }: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      bpRequest: {
        currentBPStepRow: null,
        allbpStepRows: null,
        businessProcessRow: null,
        bpTemplateSteps: null,
      },
      selectedTaskUID: null,
      showUpdateTaskDialog: false,
      showBPStepContent: false,
    }
  );
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const selectedTagNameStateValue: LookUpRow = useRecoilValue(
    atomSelectedTagName(RAFEntityName.Task)
  );

  const selectedLeftMenuTabValue: RAFHeaderTabItemsRow = useRecoilValue(
    atomSelectedLeftMenuTab(RAFEntityName.Task)
  );

  const myTaskFilterValue = useRecoilValue(myTaskFilteredValue);

  const completedTaskFilterValue = useRecoilValue(completedTaskFilteredValue);

  const selectedTabValue = isNotNullAndUndefined(selectedLeftMenuTabValue)
    ? selectedLeftMenuTabValue.value
    : null;

  const rafObject = React.useContext(RAFObjectContext);
  const entityRow = React.useContext(RAFEntityContext);
  const entity = entityRow.entity;
  let navigate = useNavigate();

  //let manageTaskDialogRef = useRef<DialogComponent>(null);
  let taskIndexPageDialogRef = useRef<DialogComponent>(null);

  // const [selectedTaskDropdownValue, setSelectedTaskView] =
  //   useRecoilState(selectedTaskView);

  const rowClick = (rowData: Object) => {
    const taskRow: TaskRow = rowData as TaskRow;
    //rowActiveToggle(tableRow);
    if (isNotNullAndUndefined(taskRow)) {
      setState({ selectedTaskUID: taskRow.UID, showUpdateTaskDialog: true });
      setTimeout(() => {
        changeGridContentHeight();
      }, 200);
    }
  };

  const editClick = (rowData: Object) => {
    const taskRow: TaskRow = rowData as TaskRow;
    // rowActiveToggle(tableRow);
    if (isNotNullAndUndefined(taskRow)) {
      if (taskRow.RelatedToType === "Process") {
        navigate(`/Process/Update/` + taskRow.RelatedToUID, {
          state: {
            //formUID: inspectionData.FormLibraryUID,
            taskSecondaryRelatedToUID: taskRow.SecondaryRelatedToUID,
          },
        });
      } else {
        setState({ selectedTaskUID: taskRow.UID, showUpdateTaskDialog: true });
        document.body.classList.add("overflow-hidden");
        let divUpdateDialog = document.getElementById("taskUpdateDialog");
        if (isNotNullAndUndefined(divUpdateDialog)) {
          divUpdateDialog.classList.remove("fade-in");
        }
        setTimeout(() => {
          changeGridContentHeight();
        }, 200);
      }
    }
  };

  // const rowActiveToggle = (tableRow?: any) => {
  //     let tableRows = document.querySelectorAll("#taskTabContentOutter .e-row");
  //     if (isNotNullAndUndefined(tableRows)) {
  //         for (let i = 0; i < tableRows.length; i++) {
  //             tableRows[i].classList.remove("e-active");
  //         }
  //     }
  //     if (isNotNullAndUndefined(tableRow)) {
  //         tableRow.classList.add("e-active");
  //     }
  // };

  //let colmpleteTaskAlertDialog;

  // const onClickCompleteTask = (
  //   selectedTaskRow: TaskRow,
  //   taskStatus: string
  // ) => {
  //   let isMyTeamExist = isNotNullAndUndefined(selectedTaskRow.AssignTeamUID)
  //     ? props.myTeams.find((x) => x.UID === selectedTaskRow.AssignTeamUID)
  //     : null;

  //   if (
  //     (isNotNullAndUndefined(selectedTaskRow.AssigneeUID) &&
  //       selectedTaskRow.AssigneeUID === msalInstance.currentUserId) ||
  //     (isNotNullAndUndefined(selectedTaskRow.AssignTeamUID) &&
  //       isNotNullAndUndefined(isMyTeamExist))
  //   ) {
  //     CompleteTaskByStatus(selectedTaskRow, taskStatus);
  //   } else {
  //     ColmpleteTaskAlert(selectedTaskRow, taskStatus);
  //   }
  // };

  const CompleteTaskClicked = (
    selectedTaskRow: TaskRow,
    taskStatus: string
  ) => {
    taskDetailsDialogClose();
    let taskRow = ConvertTaskRowToTaskDto(selectedTaskRow);
    if (isNotNullAndUndefined(taskRow.BPStepUID)) {
      let progressDiv;
      if (props.mode) {
        progressDiv = showProgress("#tasksDealDetailsDialog");
      } else {
        progressDiv = showProgress("body", true);
      }
      RetrieveBusinessProcessStepById(taskRow.BPStepUID).then((bpStepRow) => {
        //get bpstep details and related data
        GetBPStepsAndTemplateStepsById(taskRow.BPStepUID).then((bpResponse) => {
          if (
            isNotNullAndUndefined(bpStepRow.FormUID) ||
            bpStepRow.IsApproval === true
          ) {
            //show bpstep dialog for complete bpstep
            businessProcessStepDialogOpen(bpResponse);
            hideProgress(progressDiv);
          } else {
            //complete bpstep and task
            let currentBPStepRow: BusinessProcessStepRow =
              bpResponse.currentBPStepRow;
            currentBPStepRow.StepStatus = RAFBPStepStatus.Completed;
            currentBPStepRow.CompletedDate = new Date();
            CompleteTaskAndStep(
              currentBPStepRow,
              bpResponse.allbpStepRows,
              bpResponse.businessProcessRow,
              bpResponse.bpTemplateSteps,
              isNotNullAndUndefined(rafObject) && rafObject.rafObject,
              taskStatus
            ).then((/*result*/) => {
              hideProgress(progressDiv);
              taskDetailsDialogClose();
              onTaskUpdated();
              // if (props.refreshOnCompleteTask)
              //   props.refreshOnCompleteTask();
            });
          }
        });
      });
    } else {
      CompleteTask(taskRow, taskStatus);
    }
  };

  const CompleteTask = (taskRow: TaskRow, taskStatus: string) => {
    let progressDiv;
    if (props.mode) {
      progressDiv = showProgress("#tasksDealDetailsDialog");
    } else {
      progressDiv = showProgress("body", true);
    }
    taskRow.TaskStatus = taskStatus;

    //taskRow.CompletedDate = new Date();
    // taskRow.CompletedBy = msalInstance.currentUserName;
    // taskRow.CompletedByUID = msalInstance.currentUserId;
    completeTaskAndRemoveFromFocusList(taskRow)
      .then((response) => {
        //if (isNotNullAndUndefined(response)) {
        taskDetailsDialogClose();
        onTaskUpdated();
        //if (response) {
        // if (this.props.refreshOnCompleteTask)
        //   this.props.refreshOnCompleteTask();
        //}
        //}
        hideProgress(progressDiv);
      })
      .catch((error) => error);
  };

  // function ColmpleteTaskAlert(taskRow: TaskRow, taskStatus: string) {
  //   colmpleteTaskAlertDialog = DialogUtility.confirm({
  //     animationSettings: { effect: "Fade" },
  //     cancelButton: { text: "No" },
  //     closeOnEscape: false,
  //     content:
  //       "Are you sure you want to complete this task assigned to another user?",
  //     okButton: {
  //       text: "Yes",
  //       click: CompleteTaskByStatus.bind(this, taskRow, taskStatus),
  //     },
  //     showCloseIcon: true,
  //     title: " Complete Task",
  //     position: { X: "center", Y: "center" },
  //     cssClass: "alert-dialog",
  //   });
  // }

  const businessProcessStepDialogOpen = (bpResponse) => {
    document.body.classList.add("overflow-hidden");
    setState({
      showBPStepContent: true,
      bpRequest: {
        currentBPStepRow: bpResponse.currentBPStepRow,
        allbpStepRows: bpResponse.allbpStepRows,
        businessProcessRow: bpResponse.businessProcessRow,
        bpTemplateSteps: bpResponse.bpTemplateSteps,
      },
    });
  };

  const businessProcessStepContent = () => {
    if (
      isNotNullAndUndefined(state.showBPStepContent) &&
      state.showBPStepContent === true &&
      isNotNullAndUndefined(state.bpRequest) &&
      isNotNullAndUndefined(state.bpRequest.businessProcessRow)
    ) {
      let { bpRequest } = state;
      let businessProcessStepRow: BusinessProcessStepRow =
        new BusinessProcessStepRow();
      if (isNotNullAndUndefined(bpRequest)) {
        businessProcessStepRow = bpRequest.currentBPStepRow;
      }
      return (
        <div>
          <RAFProcessContextProvider
            processUID={bpRequest.businessProcessRow.UID}
          >
            <RAFBusinessProcessStepDetails
              businessProcessStepRow={bpRequest.currentBPStepRow}
              businessProcessRow={bpRequest.businessProcessRow}
              businessProcessStepRows={bpRequest.allbpStepRows}
              bpTemplateStepsRow={bpRequest.bpTemplateSteps}
              onBPStepUpdated={
                (/*allBPRows, dependentBPRows, currentBPStepRow*/) =>
                  onBPStepSaved()
              }
              onBPStepSave={
                (/*allBPRows, dependentBPRows, currentBPStepRow*/) =>
                  onBPStepSaved()
              }
              onCloseBusinessProcessDialog={businessProcessStepDialogClose.bind(
                this
              )}
              mode="Dialog"
              hideRelatedCommentSections={
                businessProcessStepRow &&
                businessProcessStepRow.StepStatus ===
                  RAFBPStepStatus.AwaitingforApproval
                  ? false
                  : true
              }
              hideRelateTaskSections
              hideRelatedDoumentSections
              //hideRelatedSections
            />
          </RAFProcessContextProvider>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const onBPStepSaved = () => {
    setState({ showBPStepContent: false });
    document.body.classList.remove("overflow-hidden");
    // if (this.props.refreshOnCompleteTask)
    //       this.props.refreshOnCompleteTask();
  };

  const businessProcessStepDialogClose = () => {
    if (props.mode !== "dialog") {
      document.body.classList.remove("overflow-hidden");
    }
    setState({
      showBPStepContent: false,
      bpRequest: {
        currentBPStepRow: null,
        allbpStepRows: null,
        businessProcessRow: null,
        bpTemplateSteps: null,
      },
    });
  };

  const getAdditionalListFilter = (sortDate?: string) => {
    let relatedToUID = isNotNullAndUndefined(rafObject)
      ? rafObject.objectId
      : null;
    let secondaryRelatedToUID =
      isNotNullAndUndefined(props.secondaryRelatedToUID) &&
      isNotNullAndUndefined(props.secondaryRelatedToUID)
        ? props.secondaryRelatedToUID
        : null;

    let additionalListFilter: RAFCustomFilter;
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    if (isNotNullAndUndefined(relatedToUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];

      filterVal.push(relatedToUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(filter);

      if (isNotNullAndUndefined(sortDate)) {
        let filter2: RAFCustomFilter = {};
        let filterVal2: string[] = [];

        filterVal2.push(sortDate);
        filter2.Operator = RAFCustomOperator.Equal;
        filter2.Value = filterVal2;
        filter2.Field = propertyOf<TaskRow>("ModifiedDate");
        customFilter.Rules.push(filter2);
      }
      if (isNotNullAndUndefined(secondaryRelatedToUID)) {
        let secondaryRelatedToFilter: RAFCustomFilter = {};
        let secondaryRelatedToFilterVal: string[] = [];
        secondaryRelatedToFilterVal.push(secondaryRelatedToUID);
        secondaryRelatedToFilter.Operator = RAFCustomOperator.Equal;
        secondaryRelatedToFilter.Value = secondaryRelatedToFilterVal;
        secondaryRelatedToFilter.Field = propertyOf<TaskRow>(
          "SecondaryRelatedToUID"
        );
        customFilter.Rules.push(secondaryRelatedToFilter);
      }
    } else {
      if (
        selectedTabValue === HeaderTabItems.Tags.value &&
        isNotNullAndUndefined(selectedTagNameStateValue) &&
        isNotNullAndUndefined(selectedTagNameStateValue.Value)
      ) {
        let filter2: RAFCustomFilter = {};
        let filterVal2: string[] = [];
        filterVal2.push(selectedTagNameStateValue.Value);
        filter2.Operator = RAFCustomOperator.Equal;
        filter2.Value = filterVal2;
        filter2.Field = "TagsListJson";
        customFilter.Rules.push(filter2);
      }
    }

    let filterParentID: RAFCustomFilter = {};
    filterParentID.Operator = RAFCustomOperator.IsNull;
    filterParentID.Field = propertyOf<TaskRow>("ParentUID");
    customFilter.Rules.push(filterParentID);

    //const taskTypes = [RAFTaskType.Appointment, RAFTaskType.Event];
    const taskTypes = [RAFTaskType.Event];
    let customTaskFilter: RAFCustomFilter = {};
    customTaskFilter.Condition = "or";
    customTaskFilter.Rules = [];
    taskTypes.forEach((taskType) => {
      let taskTypeFilter: RAFCustomFilter = {};
      let taskTypeFilterVal: string[] = [];
      taskTypeFilterVal.push(taskType);
      taskTypeFilter.Operator = RAFCustomOperator.NotEqual;
      taskTypeFilter.Value = taskTypeFilterVal;
      taskTypeFilter.Field = propertyOf<TaskRow>("TaskType");
      customFilter.Rules.push(taskTypeFilter);
    });

    //customFilter.Rules.push(customTaskFilter);

    additionalListFilter = customFilter;
    return additionalListFilter;
  };

  const renderTaskTabContent = () => {
    const hasPermissionToEdit = hasPermission(
      permissionValue,
      PermissionConstants.TaskUpdate
    );

    const { selectedTaskDropdownValue } = props;
    //let selectedTaskTabItems = props.selectedTaskTabItems;

    let showRelatedToField = isNotNullAndUndefined(props.showRelatedToField)
      ? props.showRelatedToField
      : true;
    let relatedToUID = isNotNullAndUndefined(rafObject)
      ? rafObject.objectId
      : null;

    // const showTagGridContent = IsNullOrWhiteSpace(relatedToUID) &&
    //     isNotNullAndUndefined(selectedTagNameStateValue) &&
    //     isNotNullAndUndefined(selectedTagNameStateValue.TagName) ? true : false;
    const taskTagGridKey =
      IsNullOrWhiteSpace(relatedToUID) &&
      isNotNullAndUndefined(selectedTagNameStateValue) &&
      isNotNullAndUndefined(selectedTagNameStateValue.Value)
        ? `${selectedTagNameStateValue.Value}_${selectedTabValue}`
        : null;
    // console.log('selectedTaskDropdownValue', selectedTaskDropdownValue)
    // return

    if (
      //showTagGridContent === false &&
      selectedTaskDropdownValue === TaskDropdownItems.MyTask ||
      selectedTaskDropdownValue === TaskDropdownItems.AssignedToMe
    ) {
      const additionalParamsValue =
        selectedTaskDropdownValue === TaskDropdownItems.AssignedToMe
          ? [MyTaskOptions.AssignedToMe]
          : myTaskFilterValue;
      const gridKey = `${selectedTaskDropdownValue}_${
        isNotNullAndUndefined(additionalParamsValue)
          ? additionalParamsValue.toString()
          : ""
      }`;

      let url;
      if (
        isNotNullAndUndefined(props.hideTabHeader) &&
        props.hideTabHeader === true
      ) {
        url = `${Constants.baseAPIUrl}Task/List`;
      } else {
        if (props.isRelatedSection) {
          url = `${Constants.baseAPIUrl}Task/AllTasks`;
        } else {
          url = `${Constants.baseAPIUrl}Task/MyTasks`;
        }
      }

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="section__container">
            <div
              className={
                props.hideTabHeader === true
                  ? `bg-white px-2 px-xl-3 py-1${
                      props.isRelatedSection ? "" : " border-bottom"
                    } hidden`
                  : `bg-white px-2 px-xl-3 py-1${
                      props.isRelatedSection ? "" : " border-bottom"
                    }`
              }
            >
              <RAFTaskViewDropdown
                selectedTaskDropdownValue={selectedTaskDropdownValue}
                listPageGridID={props.listPageGridID}
              />
            </div>
            <div
              className={
                props.isRelatedSection
                  ? "section__div"
                  : "section__div p-1 p-md-2 p-xl-3"
              }
            >
              <RAFFieldStateProvider moduleName={RAFEntityName.Task}>
                <RAFGridTemplates>
                  <RAFGrid4
                    key={`${gridKey}_${taskTagGridKey}`}
                    gridId={props.listPageGridID}
                    url={url}
                    moduleName={RAFEntityName.Task}
                    isRemote
                    rowClick={
                      hasPermissionToEdit === true ? editClick : rowClick
                    }
                    showEditColumn={false}
                    allowEditing
                    allowSelection={false}
                    // allowFiltering={true}
                    allowFiltering
                    gridTemplates={getGridColumnTemplatesByModule(
                      RAFEntityName.Task
                    )}
                    emptyStateProps={{
                      title: "You don’t have any tasks or appointments",
                      body: "It looks like you have no pending tasks at the moment. Keep up the good work!",
                      image: dashboard_upcoming_es,
                    }}
                    cssClass="rafSFTaskGrid"
                    //showRelatedToField={showRelatedToField}
                    // sortBy={{
                    //   field: "TaskDate",
                    //   order: "descending",
                    // }}

                    additionalParams={[
                      {
                        key: "UITabs",
                        value: additionalParamsValue,
                      },
                    ]}
                    // additionalFilter={{
                    //     Condition: "and",
                    //     Rules: [
                    //         {
                    //             ...(isNotNullAndUndefined(relatedToUID) && {
                    //                 Operator: RAFCustomOperator.Equal,
                    //                 Value: [relatedToUID],
                    //                 Field: propertyOf<TaskRow>("RelatedToUID"),
                    //             }),
                    //         },
                    //     ],
                    // }}
                    additionalFilter={getAdditionalListFilter()}
                  >
                    <RAFGridColumn<TaskRow>
                      field="TaskDate"
                      headerText="Date"
                      width="100"
                      minWidth={"100"}
                      type={SFColumnType.date}
                    />
                    {!Browser.isDevice && (
                      <RAFGridColumn<TaskRow>
                        field="TaskType"
                        headerText="Type"
                        width="80"
                        minWidth={"80"}
                      />
                    )}
                    <RAFGridColumn<TaskRow> field="Title" headerText="Title" />
                    {showRelatedToField === true && (
                      <RAFGridColumn<TaskRow>
                        field="RelatedTo"
                        headerText="Related To"
                        width="150"
                        minWidth={"150"}
                      />
                    )}
                    <RAFGridColumn<TaskRow>
                      field="ModifiedDate"
                      headerText="Updated"
                      //width="100px"
                      minWidth={"80"}
                      type={SFColumnType.date}
                    />
                  </RAFGrid4>
                </RAFGridTemplates>
              </RAFFieldStateProvider>
            </div>
          </div>
        </Suspense>
      );
    } else if (
      //showTagGridContent === false &&
      selectedTaskDropdownValue === TaskDropdownItems.AssignedbyMe
    ) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="h-100 p-2 p-md-3">
            <RAFFieldStateProvider moduleName={RAFEntityName.Task}>
              <RAFGridTemplates>
                <RAFGrid4
                  key={`${selectedTaskDropdownValue}_${taskTagGridKey}`}
                  gridId={props.listPageGridID}
                  url={`${Constants.baseAPIUrl}Task/Delegated`}
                  moduleName={RAFEntityName.Task}
                  isRemote
                  rowClick={hasPermissionToEdit === true ? editClick : rowClick}
                  showEditColumn={false}
                  allowEditing
                  allowSelection={false}
                  // allowFiltering={true}
                  allowFiltering
                  gridTemplates={getGridColumnTemplatesByModule(
                    RAFEntityName.Task
                  )}
                  emptyStateProps={{
                    title: "No data available at the moment!",
                    body: "Please enjoy your day!",
                    image: dashboard_upcoming_es,
                  }}
                  //showRelatedToField={showRelatedToField}
                  // sortBy={{
                  //   field: "TaskDate",
                  //   order: "descending",
                  // }}
                  //viewId={props.ViewUID}

                  // additionalFilter={{
                  //     Condition: "and",
                  //     Rules: [
                  //         {
                  //             ...(isNotNullAndUndefined(relatedToUID) && {
                  //                 Operator: RAFCustomOperator.Equal,
                  //                 Value: [relatedToUID],
                  //                 Field: propertyOf<TaskRow>("RelatedToUID"),
                  //             }),
                  //         },
                  //     ],
                  // }}
                  additionalFilter={getAdditionalListFilter()}
                >
                  <RAFGridColumn<TaskRow>
                    field="TaskDate"
                    headerText="Date"
                    width="100"
                    minWidth={"100"}
                    type={SFColumnType.date}
                  />
                  {!Browser.isDevice && (
                    <RAFGridColumn<TaskRow>
                      field="TaskType"
                      headerText="Type"
                      width="80"
                      minWidth={"80"}
                    />
                  )}
                  <RAFGridColumn<TaskRow> field="Title" headerText="Title" />
                  {showRelatedToField === true && (
                    <RAFGridColumn<TaskRow>
                      field="RelatedTo"
                      headerText="Related To"
                      width="150"
                      minWidth={"150"}
                    />
                  )}
                  <RAFGridColumn<TaskRow>
                    field="Assignee"
                    headerText="Assigned To"
                    minWidth={"80"}
                  />
                  <RAFGridColumn<TaskRow>
                    field="ModifiedDate"
                    headerText="Updated"
                    minWidth={"80"}
                    type={SFColumnType.date}
                  />
                </RAFGrid4>
              </RAFGridTemplates>
            </RAFFieldStateProvider>
          </div>
        </Suspense>
      );
    } else if (
      //showTagGridContent === false &&
      selectedTaskDropdownValue === TaskDropdownItems.Completed
    ) {
      const additionalParamsValue = completedTaskFilterValue;
      const gridKey = `${selectedTaskDropdownValue}_${
        isNotNullAndUndefined(additionalParamsValue)
          ? additionalParamsValue.toString()
          : ""
      }`;
      const hideTabHeader = isNotNullAndUndefined(props.hideTabHeader)
        ? props.hideTabHeader
        : false;

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="section__container">
            {hideTabHeader === false && (
              <div className="bg-white px-2 px-xl-3 py-1 border-bottom">
                <RAFTaskViewDropdown
                  selectedTaskDropdownValue={selectedTaskDropdownValue}
                  listPageGridID={props.listPageGridID}
                />
              </div>
            )}
            <div
              className={
                props.isRelatedSection
                  ? "section__div"
                  : "section__div p-1 p-md-2 p-xl-3"
              }
            >
              <RAFFieldStateProvider moduleName={RAFEntityName.Task}>
                <RAFGridTemplates>
                  <RAFGrid4
                    key={`${gridKey}_${taskTagGridKey}`}
                    gridId={props.listPageGridID}
                    url={`${Constants.baseAPIUrl}Task/CompletedTasks`}
                    moduleName={RAFEntityName.Task}
                    isRemote
                    rowClick={rowClick}
                    showEditColumn={false}
                    allowEditing
                    allowSelection={false}
                    // allowFiltering={true}
                    allowFiltering
                    gridTemplates={getGridColumnTemplatesByModule(
                      RAFEntityName.Task
                    )}
                    emptyStateProps={{
                      title: "No data available at the moment!",
                      body: "Please enjoy your day!",
                      image: dashboard_upcoming_es,
                    }}
                    //showRelatedToField={showRelatedToField}
                    // sortBy={{
                    //   field: "TaskDate",
                    //   order: "descending",
                    // }}

                    additionalParams={[
                      { key: "UITabs", value: [completedTaskFilterValue] },
                    ]}
                    // additionalFilter={{
                    //     Condition: "and",
                    //     Rules: [
                    //         {
                    //             ...(isNotNullAndUndefined(relatedToUID) && {
                    //                 Operator: RAFCustomOperator.Equal,
                    //                 Value: [relatedToUID],
                    //                 Field: propertyOf<TaskRow>("RelatedToUID"),
                    //             }),
                    //         }
                    //     ],
                    // }}
                    additionalFilter={getAdditionalListFilter()}
                  >
                    <RAFGridColumn<TaskRow>
                      field="CompletedDate"
                      headerText="Date"
                      width="100"
                      minWidth={"100"}
                      type={SFColumnType.date}
                    />
                    {!Browser.isDevice && (
                      <RAFGridColumn<TaskRow>
                        field="TaskType"
                        headerText="Type"
                        width="80"
                        minWidth={"80"}
                      />
                    )}
                    <RAFGridColumn<TaskRow> field="Title" headerText="Title" />
                    {showRelatedToField === true && (
                      <RAFGridColumn<TaskRow>
                        field="RelatedTo"
                        headerText="Related To"
                        width="150"
                        minWidth={"150"}
                      />
                    )}
                    <RAFGridColumn<TaskRow>
                      field="Assignee"
                      headerText="Assigned to"
                      minWidth={"80px"}
                    />
                    <RAFGridColumn<TaskRow>
                      field="ModifiedDate"
                      headerText="Updated"
                      minWidth={"80px"}
                      type={SFColumnType.date}
                    />
                  </RAFGrid4>
                </RAFGridTemplates>
              </RAFFieldStateProvider>
            </div>
          </div>
        </Suspense>
      );
    } else if (
      //showTagGridContent === true ||
      selectedTaskDropdownValue === TaskDropdownItems.RecentlyUpdated
    ) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <RAFFieldStateProvider moduleName={RAFEntityName.Task}>
            <RAFGridTemplates>
              <RAFGrid4
                key={`${selectedTaskDropdownValue}_${taskTagGridKey}`}
                gridId={props.listPageGridID}
                url={`${Constants.baseAPIUrl}Task/List`}
                moduleName={RAFEntityName.Task}
                isRemote
                rowClick={(taskRow: TaskRow) => {
                  taskRow.TaskStatus !== RAFActionStatus.Completed &&
                  hasPermissionToEdit === true
                    ? editClick(taskRow)
                    : rowClick(taskRow);
                }}
                showEditColumn={false}
                allowEditing
                allowSelection={false}
                // allowFiltering={true}
                allowFiltering
                gridTemplates={getGridColumnTemplatesByModule(
                  RAFEntityName.Task
                )}
                emptyStateProps={{
                  title: "No data available at the moment!",
                  body: "Please enjoy your day!",
                  image: dashboard_upcoming_es,
                }}
                //showRelatedToField={showRelatedToField}
                // sortBy={{
                //   field: "ModifiedDate",
                //   order: "descending",
                // }}
                sortBy={{
                  field: propertyOf<TaskRow>("ModifiedDate"),
                  order: "descending",
                }}
                // additionalFilter={{
                //     Condition: "and",
                //     Rules: [
                //         {
                //             ...(isNotNullAndUndefined(relatedToUID) && {
                //                 Operator: RAFCustomOperator.Equal,
                //                 Value: [relatedToUID],
                //                 Field: propertyOf<TaskRow>("RelatedToUID"),
                //             }),
                //         },
                //         {
                //             Operator: RAFCustomOperator.Equal,
                //             Value: [RAFDatePresets.Last90Days],
                //             Field: propertyOf<TaskRow>("ModifiedDate"),
                //         },
                //     ],
                // }}
                additionalFilter={getAdditionalListFilter(
                  RAFDatePresets.Last90Days
                  //, showTagGridContent === true ? selectedTagNameStateValue : null
                )}
              >
                <RAFGridColumn<TaskRow>
                  field="TaskDate"
                  headerText="Date"
                  width="100"
                  minWidth={"100"}
                  type={SFColumnType.date}
                />
                {!Browser.isDevice && (
                  <RAFGridColumn<TaskRow>
                    field="TaskType"
                    headerText="Type"
                    width="80"
                    minWidth={"80"}
                  />
                )}
                <RAFGridColumn<TaskRow> field="Title" headerText="Title" />
                {showRelatedToField === true && (
                  <RAFGridColumn<TaskRow>
                    field="RelatedTo"
                    headerText="Related To"
                    width="150"
                    minWidth={"150"}
                  />
                )}
                {/* <RAFGridColumn<TaskRow>
                                    field='Assignee'
                                    headerText='Assigned To'
                                /> */}
                <RAFGridColumn<TaskRow>
                  field="ModifiedDate"
                  headerText="Updated"
                  minWidth={"80"}
                  type={SFColumnType.date}
                />
              </RAFGrid4>
            </RAFGridTemplates>
          </RAFFieldStateProvider>
        </Suspense>
      );
    } else {
      return <></>;
    }
  };

  const getTaskGridContent = useMemo(() => {
    return renderTaskTabContent();
  }, [
    props.listPageGridID,
    completedTaskFilterValue,
    myTaskFilterValue,
    props.selectedTaskDropdownValue,
    selectedTagNameStateValue,
    selectedTabValue,
  ]);

  const taskDetailsDialogClose = async () => {
    await RafClearDialogContent(taskIndexPageDialogRef);
    setState({ selectedTaskUID: null, showUpdateTaskDialog: false });

    // rowActiveToggle();
    if (props.mode !== "dialog") {
      document.body.classList.remove("overflow-hidden");
    }
    let divUpdateDialog = document.getElementById("taskUpdateDialog");
    if (isNotNullAndUndefined(divUpdateDialog)) {
      divUpdateDialog.classList.remove("fade-in");
    }
    setTimeout(() => {
      changeGridContentHeight();
    }, 200);
  };

  const onTaskUpdated = () => {
    refreshGridAndLeftContent();
    // taskDetailsDialogClose();
    //if (props.refreshOnUpdateTask) props.refreshOnUpdateTask();
  };

  // const onTaskUpdated1 = () => {
  //   if (selectedTaskDropdownValue === TaskDropdownItems.RecentlyUpdated) {
  //     triggerRAFEvent(RAFEventName.GridLoad, { GridId: props.listPageGridID });
  //   } else {
  //     setSelectedTaskView(TaskDropdownItems.RecentlyUpdated);
  //   }
  //   taskDetailsDialogClose();
  //   //if (props.refreshOnUpdateTask) props.refreshOnUpdateTask();
  // };

  const onTaskDeleted = () => {
    refreshGridAndLeftContent();
    taskDetailsDialogClose();
  };

  function refreshGridAndLeftContent() {
    triggerRAFEvent(RAFEventName.GridLoad, { GridId: props.listPageGridID });
    triggerRAFEvent(RAFEventName.ReloadTagMenu, null);
  }

  function taskDetailsContent() {
    if (state.showUpdateTaskDialog) {
      return (
        <ManageTask
          onSave={onTaskUpdated.bind(this)}
          onDelete={onTaskDeleted.bind(this)}
          onClose={taskDetailsDialogClose.bind(this)}
          //isEditTask={state.isEditTask}
          isDashboard
          complete={(taskRow, taskStatus) =>
            CompleteTaskClicked(taskRow, taskStatus)
          }
          contextModuleName={entity ? entity.EntityName : "dashboard"}
          taskUID={state.selectedTaskUID}
          isActive
          relatedCommentsUpdated={(isRelatedCommentsUpdated) => {
            if (props.relatedCommentsUpdated)
              props.relatedCommentsUpdated(isRelatedCommentsUpdated);
          }}
          {...props}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div className="h-100">
      <div className="h-100">{getTaskGridContent}</div>

      <div key={ConvertSystemName(props.selectedTaskDropdownValue)}>
        {state.showUpdateTaskDialog && (
          <DialogComponent
            visible={state.showUpdateTaskDialog}
            cssClass="rightDialog createEditForm full-height"
            id="update_task_dlg"
            content={taskDetailsContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={() => {
              console.log("close");
            }}
            ref={taskIndexPageDialogRef}
            zIndex={1200}
          ></DialogComponent>
        )}
        {state.showBPStepContent && (
          <DialogComponent
            visible={state.showBPStepContent}
            cssClass="rightDialog createEditForm full-height"
            id="bpStepUpdateDialog"
            content={businessProcessStepContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={businessProcessStepDialogClose.bind(this)}
            ref={taskIndexPageDialogRef}
          ></DialogComponent>
        )}
      </div>
    </div>
  );
}
//export default React.memo(RAFTaskIndexContent);
export default RAFTaskIndexContent;
