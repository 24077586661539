import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';

//export class StateRow extends RAFEntityBase {
export class IStateRow {
    //Id?: number;
    UID?: string;
    StateName?: string;
    CountryUID?: string;
    Country?: string;
    IsActive?: boolean;
    SortOrder?: number;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}
export class StateRow extends IStateRow implements RAFEntityBase, ILookupRow {
    getClassName?() { //use entityname
        return 'state';
    }
    getIdField?(): string {
        return propertyOf<StateRow>("UID");
    }
    getListUrl?(): string { //use route name(entity displayname)
        return "State/List";
    }
    getSaveUrl?(): string { //use route name(entity displayname)
        return "State/Save";
    }
    getGroupByUrl?(): string {
        return "Account/GroupBy";
    }
    getLookupUrl(): string { //use route name(entity displayname)
        return "State/Lookup";
    }
    isOptionCreatable?(): boolean {
        return true;
    }
}

