import { Browser, createElement, debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";

import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DateRangePicker } from "@syncfusion/ej2-react-calendars";
import {
    ComboBox,
    DropDownList,
    FilteringEventArgs
} from "@syncfusion/ej2-react-dropdowns";
import {
    Action,
    ActionEventArgs,
    Column,
    ColumnDirective,
    ColumnModel,
    ColumnsDirective,
    CommandClickEventArgs,
    CommandColumn,
    CommandModel,
    DataStateChangeEventArgs,
    Edit,
    EditSettingsModel,
    ExcelExport,
    Filter,
    FilterSettingsModel,
    Freeze,
    Grid,
    GridComponent,
    GridModel,
    Group,
    GroupSettingsModel,
    IFilterMUI,
    IFilterUI,
    Inject,
    Page,
    PageSettingsModel,
    PdfExport,
    PdfHeaderQueryCellInfoEventArgs,
    PredicateModel,
    RecordClickEventArgs,
    Resize,
    SelectionSettingsModel,
    Sort,
    SortSettingsModel,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import { TextBox } from "@syncfusion/ej2-react-inputs";
import { ClickEventArgs } from "@syncfusion/ej2-react-navigations";
import {
    Tooltip,
    TooltipComponent,
    TooltipEventArgs,
    createSpinner,
    hideSpinner,
    showSpinner
} from "@syncfusion/ej2-react-popups";
import moment from "moment";
import * as R from "ramda";
import React, { ReactNode } from "react";
import { msalInstance } from "../../..";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import { GetGridModelFromGridState, SetGridState } from "../../../RAFComponents/helpers/AppHelper";
import { presetDateModel } from "../../../RAFComponents/helpers/RAFDateRangeHelper";
import {
    formatSFDateOption,
    getCustomFilterBySFGridFilter,
    getCustomFilterBySFGridFilter1,
    getDisplayNameBySFOperator,
    getGridFilterByCustomFilter
} from "../../../RAFComponents/helpers/SFGridUtils";
import { RAFDataType, RAFUIType } from "../../../RAFComponents/models/Common/RAFDataType";
import loaderIcon from "../../../assets/LoaderIconCircle.svg";
import noRecordIcon from "../../../assets/NoRecordIcon.svg";
import {
    Constants,
    MomentFormats,
    RAFActionStatus,
    RAFDatePickerViewFormat,
    RAFTaskType,
    SFColumnType
} from "../../../constants/Common/Constants";
import {
    GridColumnHeader,
    GridColumnTemplates,
    GridColumnWidth,
    RAFGridColumnProps
} from "./../../../RAFComponents/Grid/RAFSFGridUtils";
import {
    RAFDataManager,
    RAFUrlAdaptor
} from "./../../../RAFComponents/Inputs/RAFUrlAdaptor";
import { RAFDataListAdditionalProps } from "./../../../RAFComponents/List/RAFReportDataList";
import { RAFEmptyStateProps } from "./../../../RAFComponents/Navigation/RAFEmptyState";
import { RAFCustomFilter } from "./../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { RAFSort } from "./../../../RAFComponents/helpers/types";
import {
    IsNullOrWhiteSpace,
    convertUTCDateToLocalTimezone,
    deepEqual, getSFColumnTypeByRAFDatatype,
    isNotNullAndUndefined,
    isNullOrUndefined,
    truncateAfter
} from "./../../../RAFComponents/helpers/utils";
import {
    QueryAttributeJM,
    ValueJson
} from "./../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFEntityBase } from "./../../../RAFComponents/models/Common/RAFEntityBase";
import { GroupByJM } from "./../../../RAFComponents/models/Common/RAFViewRow";
import { getTaskDisplayDateAndBadge } from "./TaskHelper";
import { TaskRow } from "./TaskRow";

export declare type ExportType = "Pdf" | "Csv" | "Excel";

export declare type RafGridFilterType = "Menu" | "Excel" | "FilterBar";

interface IProps {
    currentSort?: RAFSort;
    currentFilter?: RAFCustomFilter;
    pageNumber?: number;
    pageSize?: number;
    data?: any;
    actions?: any;

    gridId?: string;
    entityType?: typeof RAFEntityBase;
    isRemote?: boolean;
    rowClick?: (rowData: Object, e: any, isEditTask?: boolean) => void;
    editClick?: (rowData: Object) => void;
    customClick?: (rowData: Object) => void;
    customActionClick?: (rowData: Object) => void;
    allowSelection?: boolean;
    filterType?: RafGridFilterType;
    actionButtons?: CommandModel[];
    deleteClicked?: (selectedRows: Object[]) => void;
    showToolbar?: boolean;
    allowEditing?: boolean;
    showEditColumn?: boolean;
    allowFiltering?: boolean;
    rowSelected?: (selectedRows: Object[]) => void;
    hasClientTerms?: boolean;
    gridTemplates?: GridColumnTemplates[];
    gridColumnWidth?: GridColumnWidth[];
    gridColumnHeader?: GridColumnHeader[];
    showEdit?: boolean;
    rowTemplate?: any;
    groupByColumns?: GroupByJM[];
    emptyStateProps?: RAFEmptyStateProps;
    disableFirstColumnTemplate?: boolean;
    sortBy?: RAFSort;
    actionButtonClicked?: (id: string, selectedRow: Object) => void;
    pageSizes?: number[];
    moduleName?: string;
    idField?: string;
    typeName?: string;

    showHeader?: boolean;
    hasOtherViews?: boolean;
    fields?: QueryAttributeJM[];
    templateHelpers?: any;

    moduleClick?: (rowData: Object) => void;
    relatedModuleClick?: (taskRow: TaskRow) => void;
    showReAssignOption?: boolean;
    showAssignTeamField?: boolean;
    showAssigneeField?: boolean;
    showRelatedToField?: boolean;
    showDueDateField?: boolean;
    showCompletedDateField?: boolean;
    showStatusField?: boolean;
    onClickAssignToMe?: (taskUID: string) => void;
    completeClicked?: (taskRow: TaskRow, taskStatus: string) => void;
    onFocusClicked?: (relatedTo: string, relatedUID: string) => void;
    frozenColumns?: number;
    isDynamic?: boolean;
    isAdaptive?: boolean;
    mobileColCount?: number;
    children?: ReactNode;
}

interface IState {
    fields: QueryAttributeJM[];
    loadGrid: boolean;
    loading: boolean;
    allGridColumns: ColumnModel[];
    selectedGridColumns: ColumnModel[];
    data?: any;
}

class TaskCardView2 extends React.Component<
    IProps & RAFDataListAdditionalProps,
    IState
> {
    private grid: Grid | null;
    private tooltip: TooltipComponent;
    private selectedRecords: Object[];

    private typeName: string;
    private idField: string;
    private editSettings: EditSettingsModel = {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: true,
    };
    private hasClientTerms = false;
    private showDueDateField = true;
    private showCompletedDateField = false;

    private textboxObj: TextBox;
    private dropDownObj: DropDownList;
    private booleandropDownObj: DropDownList;
    private lookupObject: DropDownList;
    private daterangepickerObj: DateRangePicker;

    //console.log('RAFGrid1 props=', props);
    //private data;
    private pageNumber;
    private pageSize;
    private currentSort;
    private currentFilter;

    private allowFiltering = true;
    private showHeader = true;
    private isRemote = false;
    private allowSelection = true;
    private allowEditing = false;
    private showEditColumn = false;
    private hasOtherViews = false;
    private gridTemplates?: GridColumnTemplates[];
    private emptyStateProps?: RAFEmptyStateProps;
    private showToolbar = true;
    private disableFirstColumnTemplate = false;
    private pageSettings: PageSettingsModel = {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 25, 50, 100],
    };
    private sortingOptions: SortSettingsModel = { allowUnsort: true };
    private filterSettings: FilterSettingsModel = {
        showFilterBarStatus: false,
        mode: "Immediate",
        type: "FilterBar",
    };
    private filterOptions: FilterSettingsModel = { type: "Menu" };
    private groupSettings: GroupSettingsModel = {
        showDropArea: false,
        showGroupedColumn: false,
        showToggleButton: false,
        showUngroupButton: false,
    };
    private selectionsettingsModel: SelectionSettingsModel = {
        //checkboxOnly: true,
        //persistSelection: true
    };
    private stringOperators: Object[] = [
        { value: "startswith", text: "Starts With" },
        { value: "endswith", text: "Ends With" },
        { value: "contains", text: "Contains" },
        { value: "equal", text: "Equal" },
        { value: "notequal", text: "Not Equal" },
    ];
    private isDynamic = false;
    private isAdaptive = false;
    private mobileColCount = 0;

    constructor(props: IProps & RAFDataListAdditionalProps) {
        super(props);

        if (props.data) {
            //this.data = props.data.data;
            // console.log("props.data", props.data);
            this.pageNumber = props.data.pageNumber;
            this.pageSize = props.data.pageSize;
            this.currentSort = props.data.currentSort;
            this.currentFilter = props.data.currentFilter;
            this.pageSettings.currentPage = this.pageNumber ?? 1;
            this.pageSettings.pageSize = this.pageSize ?? 10;
            if (this.currentSort && !IsNullOrWhiteSpace(this.currentSort.field)) {
                this.sortingOptions.columns = [
                    { field: this.currentSort.field, direction: this.currentSort.order },
                ];
            }

            if (this.currentFilter) {
                this.filterSettings.columns = getGridFilterByCustomFilter(
                    this.currentFilter
                );
            }

            this.state = {
                fields: [],
                loadGrid: true,
                loading: false,
                allGridColumns: [],
                selectedGridColumns: [],
                data: { ...props.data.data },
            };
        } else {
            this.state = {
                fields: [],
                loadGrid: true,
                loading: false,
                allGridColumns: [],
                selectedGridColumns: [],
                data: null,
            };
        }

        if (isNotNullAndUndefined(props.entityType)) {
            let objInstance = createInstance(props.entityType);
            this.typeName = objInstance.getClassName();
            this.idField = objInstance.getIdField();
        }
        if (isNotNullAndUndefined(props.filterType))
            this.filterSettings.type = props.filterType;
        if (isNotNullAndUndefined(props.allowSelection))
            this.allowSelection = props.allowSelection;
        if (isNotNullAndUndefined(props.isRemote)) this.isRemote = props.isRemote;
        if (isNotNullAndUndefined(props.showToolbar))
            this.showToolbar = props.showToolbar;

        if (isNotNullAndUndefined(props.allowEditing))
            this.allowEditing = props.allowEditing;
        if (isNotNullAndUndefined(props.showEditColumn))
            this.showEditColumn = props.showEditColumn;
        this.editSettings.allowEditing = this.allowEditing;

        if (isNotNullAndUndefined(props.allowFiltering))
            this.allowFiltering = props.allowFiltering;

        if (isNotNullAndUndefined(props.hasClientTerms))
            this.hasClientTerms = props.hasClientTerms;

        if (isNotNullAndUndefined(props.showDueDateField))
            this.showDueDateField = props.showDueDateField;

        if (isNotNullAndUndefined(props.showCompletedDateField))
            this.showCompletedDateField = props.showCompletedDateField;

        //this.filterSettings.type = 'FilterBar'

        let model: GridModel = null;
        if (isNotNullAndUndefined(this.props.gridId)) {
            model = GetGridModelFromGridState(this.props.gridId);
            if (isNotNullAndUndefined(model)) {
                this.pageSettings = model.pageSettings;
                this.sortingOptions = model.sortSettings;
                this.filterSettings = model.filterSettings;
                this.groupSettings = model.groupSettings;
                this.editSettings = model.editSettings;
                //this.selectionsettingsModel = model.selectionSettings;
            }
        }
        this.allowFiltering = props.allowFiltering ?? true;
        this.showHeader = props.showHeader ?? true;
        this.isRemote = props.isRemote ?? true;
        this.allowSelection = props.allowSelection ?? true;
        this.allowEditing = props.allowEditing ?? false;
        this.showEditColumn = props.showEditColumn ?? false;
        this.hasOtherViews = props.hasOtherViews ?? false;
        this.gridTemplates = props.gridTemplates ?? [];
        this.emptyStateProps = props.emptyStateProps ?? null;
        this.showToolbar = props.showToolbar ?? true;
        this.disableFirstColumnTemplate = props.disableFirstColumnTemplate ?? false;
        this.isAdaptive = props.isAdaptive ?? false;
        this.mobileColCount = props.mobileColCount ?? 0;
        this.isDynamic = props.isDynamic ?? false;
    }

    //const { fetchData, filtering, paging, sorting } = props.actions;
    //const { gridId, moduleName, idField, fields, children, templateHelpers } = props;

    private hideToolbarMenu = () => {
        let toolbar = document.getElementById("toolbar-actions");
        if (isNotNullAndUndefined(toolbar)) {
            toolbar.classList.add("toolbarhidden");
            toolbar.classList.remove("toolbarVisible");
        }
    };

    private hideGridSpinner = () => {
        setTimeout(() => {
            let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
            if (this.grid && this.grid.element) {
                const gridParentDiv = this.grid.element.closest("#divGridParent");
                if (gridParentDiv) {
                    const customSpinner = gridParentDiv.querySelector(
                        "#customSpinner"
                    ) as HTMLElement;
                    if (
                        isNotNullAndUndefined(customSpinner) &&
                        !IsNullOrWhiteSpace(customSpinner.innerHTML)
                    ) {
                        spinnerTarget = gridParentDiv.querySelector(
                            "#customSpinner"
                        ) as HTMLElement;
                        customSpinner.classList.add('hidden');
                    }
                }
            }
            hideSpinner(spinnerTarget);
            if (this.grid) {
                //grid.hideSpinner();
                let mainElement: HTMLElement = document.documentElement;
                if (this.grid.element) {
                    mainElement = this.grid.element.parentElement;
                }
                const eSpinnerPane: HTMLElement =
                    mainElement.querySelector(".e-spinner-pane");
                if (isNotNullAndUndefined(eSpinnerPane)) {
                    eSpinnerPane.classList.add("hidden");
                    eSpinnerPane.classList.remove("e-spin-show");
                }
                const currentViewRecords = this.grid.getCurrentViewRecords();
                if (
                    isNotNullAndUndefined(currentViewRecords) &&
                    currentViewRecords.length > 0
                ) {
                    this.grid.element.classList.remove("hidden");
                    //grid.element.classList.add("fade-in");
                } else {
                    if (
                        isNotNullAndUndefined(this.grid.filterSettings) &&
                        isNotNullAndUndefined(this.grid.filterSettings.columns) &&
                        this.grid.filterSettings.columns.length > 0
                    ) {
                        //if condition added to show grid filterbar when filter is applied and also no records found
                        this.grid.element.classList.remove("hidden");
                    } else {
                        //grid.element.classList.add("hidden");
                    }
                }
            }
        }, 100);
    };

    private showGridSpinner = () => {
        setTimeout(() => {
            let mainElement: HTMLElement = document.documentElement;
            if (this.grid && this.grid.element) {
                mainElement = this.grid.element.parentElement;
            }
            const eSpinnerPane: HTMLElement =
                mainElement.querySelector(".e-spinner-pane");
            if (isNotNullAndUndefined(eSpinnerPane)) {
                eSpinnerPane.classList.remove("hidden");
                eSpinnerPane.classList.add("e-spin-show");
            }
        }, 100);
    };

    private ConstructFilterMessage = (
        filterColumnsModel: PredicateModel[]
    ): string => {
        let retVal = "";
        if (
            isNotNullAndUndefined(filterColumnsModel) &&
            filterColumnsModel.length > 0
        ) {
            for (var i = 0; i < filterColumnsModel.length; i++) {
                const col: PredicateModel = filterColumnsModel[i];
                let value = col.value;
                const operator: string = col.operator;
                const field: string = col.field;
                let displayName: string = field;
                let columnModel: ColumnModel = {};
                //if (isNotNullAndUndefined(state.selectedGridColumns)) {
                //    columnModel = state.selectedGridColumns.find(x => x.field === field);
                //    if (isNotNullAndUndefined(columnModel) && !IsNullOrWhiteSpace(columnModel.field)) {
                //        displayName = columnModel.headerText;
                //    }
                //}

                if (col.type === SFColumnType.date) {
                    value = moment(value as Date).format(MomentFormats.DATE);
                }

                // if (columnModel.type === SFColumnType.dropdown) {
                //     //let attributes = state.fields;
                //     //let selectedField = attributes && attributes.find(x => x.PropertyName === columnModel.field);
                //     //if (isNotNullAndUndefined(selectedField) && isNotNullAndUndefined(selectedField.ValueJson)) {
                //     //    let currentChoiceList = selectedField.ValueJson.find(x => x.DisplayName === value);
                //     //    value = currentChoiceList && currentChoiceList.DisplayName;
                //     //}
                // }

                if (value === true) {
                    value = "Yes";
                }
                if (value === false) {
                    value = "No";
                }

                if (isNotNullAndUndefined(value)) {
                    if (i === 0) {
                        retVal = `${displayName} ${getDisplayNameBySFOperator(
                            operator
                        )} '${value}'`;
                    } else {
                        retVal = `${retVal} and ${displayName} ${getDisplayNameBySFOperator(
                            operator
                        )} '${value}'`;
                    }
                }
            }
        }
        return retVal;
    };

    private refreshData = (resetGrid?: boolean) => {
        if (this.grid) {
            if (resetGrid) {
                let model: GridModel = null;
                if (isNotNullAndUndefined(this.props.gridId)) {
                    model = GetGridModelFromGridState(this.props.gridId);
                    if (isNotNullAndUndefined(model)) {
                        model.sortSettings.columns = [];
                        model.filterSettings.columns = [];
                        model.groupSettings.columns = [];
                        model.pageSettings.currentPage = 1;
                        model.pageSettings.pageSize = 10;
                        SetGridState(this.props.gridId, model, null);
                        this.pageSettings = model.pageSettings;
                        this.sortingOptions = model.sortSettings;
                        this.filterSettings = model.filterSettings;
                        this.groupSettings = model.groupSettings;
                    }
                }
            }

            setTimeout(() => {
                let sort: RAFSort = { field: "", order: "" };
                let objCustomFilter: RAFCustomFilter = {};
                let pageNumber = 1;
                let pageSize = 10;

                let model: GridModel = null;
                if (isNotNullAndUndefined(this.props.gridId)) {
                    model = GetGridModelFromGridState(this.props.gridId);
                }
                if (isNotNullAndUndefined(model)) {
                    if (
                        isNotNullAndUndefined(model.sortSettings) &&
                        isNotNullAndUndefined(model.sortSettings.columns) &&
                        model.sortSettings.columns.length > 0 &&
                        isNotNullAndUndefined(model.sortSettings.columns[0].field)
                    ) {
                        sort.field = model.sortSettings.columns[0].field;
                        sort.order = model.sortSettings.columns[0].direction;
                    } else {
                        if (
                            isNotNullAndUndefined(this.props.sortBy) &&
                            isNotNullAndUndefined(this.props.sortBy.field)
                        ) {
                            sort.field = this.props.sortBy.field;
                            sort.order = this.props.sortBy.order;
                        } else {
                            sort = null;
                        }
                    }
                    if (
                        isNotNullAndUndefined(model.filterSettings) &&
                        isNotNullAndUndefined(model.filterSettings.columns)
                    ) {
                        objCustomFilter = getCustomFilterBySFGridFilter1(
                            model.filterSettings.columns
                        );
                    }
                    pageNumber = isNotNullAndUndefined(model.pageSettings)
                        ? model.pageSettings.currentPage
                        : 1;
                    pageSize = isNotNullAndUndefined(model.pageSettings)
                        ? model.pageSettings.pageSize
                        : 10;
                }
                if (!this.isRemote) {
                    this.grid.dataSource = this.state.data;
                } else {
                    this.showGridSpinner();
                    this.props.actions.fetchData(
                        pageNumber,
                        pageSize,
                        sort,
                        objCustomFilter
                    );
                }
            }, 100);
        }
    };

    private changeGridContentHeight = () => {
        let eFilterbar = document.querySelector(".e-filterbar");
        let eGridpager = document.querySelector(".e-gridpager");
        let eColumnheader = document.querySelector(".e-columnheader");
        let filterMessageBar = document.querySelector(".filterMessageBar ");
        let eGridcontent = document.querySelector(
            ".e-gridcontent"
        ) as HTMLElement | null;
        let resToolbar = document.querySelector(".e-res-toolbar");

        let eGridpagerWidth = eGridpager && eGridpager.clientHeight;
        let eFilterbarWidth = eFilterbar && eFilterbar.clientHeight;
        let eColumnheaderWidth = eColumnheader && eColumnheader.clientHeight;
        let resToolbarHeight = resToolbar && resToolbar.clientHeight;

        let filterMessageBarWidth =
            filterMessageBar && filterMessageBar.clientHeight + 17;
        let contentHeight =
            eGridpagerWidth +
            eFilterbarWidth +
            eColumnheaderWidth +
            filterMessageBarWidth +
            resToolbarHeight;
        eGridcontent.style.height = "calc(100% - " + contentHeight + "px)";
    };

    private getColumnsDirective = (fields?: QueryAttributeJM[]) => {
        if (
            isNotNullAndUndefined(this.props.children) &&
            isNotNullAndUndefined(this.props.children["props"]) &&
            isNotNullAndUndefined(this.props.children["props"].children)
        ) {
            const children1 = React.Children.toArray(
                this.props.children["props"].children
            );
            //const children1 = React.Children.toArray(this.props.children);
            if (
                isNotNullAndUndefined(children1) &&
                children1.length > 0 &&
                isNotNullAndUndefined(children1[0]["props"]) &&
                isNotNullAndUndefined(children1[0]["props"]["field"])
            ) {
                let retval = children1.map((item, i) => {
                    const child = children1[i];
                    let rafGridColumnProps: RAFGridColumnProps<any> = child["props"];
                    let matchedAttribute: QueryAttributeJM =
                        fields &&
                        fields.find(
                            (x) => x.PropertyName === rafGridColumnProps.field.toString()
                        );
                    return (
                        <ColumnDirective
                            key={i}
                            field={rafGridColumnProps.field.toString()}
                            type={rafGridColumnProps.type}
                            format={
                                rafGridColumnProps.type === SFColumnType.date
                                    ? formatSFDateOption
                                    : null
                            }
                            headerText={
                                rafGridColumnProps.headerText ||
                                rafGridColumnProps.field.toString()
                            }
                            isPrimaryKey={rafGridColumnProps.isPrimaryKey}
                            minWidth={
                                isNotNullAndUndefined(rafGridColumnProps.minWidth)
                                    ? rafGridColumnProps.minWidth
                                    : '150'
                            }
                            maxWidth={
                                isNotNullAndUndefined(rafGridColumnProps.maxWidth)
                                    ? rafGridColumnProps.maxWidth
                                    : this.customMaxColumnWidthTemplate(matchedAttribute)
                            }
                            headerTemplate={this.customHeaderTemplate}
                            width={
                                isNotNullAndUndefined(rafGridColumnProps.width)
                                    ? rafGridColumnProps.width
                                    : this.customColumnWidthTemplate(matchedAttribute)
                            }
                            //autoFit
                            visible={rafGridColumnProps.visible}
                            allowEditing={
                                isNotNullAndUndefined(rafGridColumnProps.isEditable)
                                    ? rafGridColumnProps.isEditable
                                    : false
                            }
                            {...(matchedAttribute
                                ? { filterBarTemplate: this.filterTemplate(matchedAttribute) }
                                : {})}
                            {...(matchedAttribute
                                ? {
                                    filter: {
                                        type: "Menu",
                                        ui: this.filterMenuTemplate(matchedAttribute),
                                    },
                                }
                                : {})}
                            template={this.defaultColumnTemplate}
                            //{...(matchedAttribute ? { filterBarTemplate: this.filterTemplate(matchedAttribute) } : {})}
                            //{...(matchedAttribute ? { filter: { type: 'Menu', ui: this.filterMenuTemplate(matchedAttribute), }, } : {})}
                            clipMode={i === 0 ? "Clip" : "Ellipsis"}
                        ></ColumnDirective>
                    );
                });
                return retval;
            }
        }
        let retval =
            fields &&
            fields.map((item, i) => {
                return (
                    <ColumnDirective
                        key={i}
                        field={item.PropertyName}
                        type={getSFColumnTypeByRAFDatatype(item.DataType)}
                        //headerText={GetClientTerm(clientTerminologies, item.PropertyName, item.DisplayName)}
                        headerText={item.DisplayName}
                        isPrimaryKey={
                            isNotNullAndUndefined(this.props.idField) &&
                                this.props.idField === item.PropertyName
                                ? true
                                : false
                        }
                        minWidth={"150"}
                        maxWidth={this.customMaxColumnWidthTemplate(item)}
                        headerTemplate={this.customHeaderTemplate}
                        width={this.customColumnWidthTemplate(item)}
                        // autoFit
                        visible={
                            Browser.isDevice === true && (this.mobileColCount > 0 && i > this.mobileColCount - 1)
                                ? false
                                :
                                // (isNullOrUndefined(this.mobileColCount) ||
                                //   this.mobileColCount < 1 ||
                                //   this.isAdaptive) &&
                                true
                        }
                        filterBarTemplate={this.filterTemplate(item)}
                        filter={{ type: "Menu", ui: this.filterMenuTemplate(item) }}
                        allowEditing={
                            isNotNullAndUndefined(item.IsEditable) ? item.IsEditable : false
                        }
                        clipMode={i === 0 ? "Clip" : "Ellipsis"}
                        template={this.defaultColumnTemplate}
                    ></ColumnDirective>
                );
            });
        return retval;
    };

    onCheckboxClicked = (inputId, divId) => {
        let inputVal: HTMLInputElement = document.querySelector("#" + inputId);
        let additionalBtn = document.querySelector("#" + divId);
        if (isNotNullAndUndefined(additionalBtn)) {
            if (inputVal.checked) {
                additionalBtn.classList.add("active");
            } else {
                additionalBtn.classList.remove("active");
            }
        }
    };

    public rowTemplate(props): any {
        const {
            //index,
            ...taskData
        } = props;
        let taskRow: TaskRow = taskData as TaskRow;
        const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
            isNotNullAndUndefined(taskRow) ? taskRow.TaskDate : null,
            isNotNullAndUndefined(taskRow) ? taskRow.TaskType : null,
        );
        let taskDate = taskDisplayDateAndBadge.taskDate;
        let dateBadge = taskDisplayDateAndBadge.dateBadge;
        const loggedinUserId = msalInstance.currentUserId;
        /*let completeTaskRow: TaskRow = {};
            completeTaskRow.UID = taskRow.UID;
            completeTaskRow.BPStepUID = taskRow.BPStepUID;*/
        //let isFocused = false;
        //let isFocused: boolean = this.getFocusedByRelatedUID(taskRow.UID);

        // if (taskRow.TaskDate !== null) {
        //     const objTaskDate: Date = convertUTCDateToLocalTimezone(taskRow.TaskDate);
        //     if (Constants.MaxDate.valueOf() === objTaskDate.valueOf()) {
        //         dateBadge = "grey lighten-1 badge-grey";
        //         taskDate = "Unplanned";
        //     } else {
        //         taskDate = getDate(objTaskDate, "MMM DD");
        //         /*if (moment(objTaskDate).isSame(moment(Constants.MaxDate), "day")) {
        //                     dateBadge = 'danger badge-danger';
        //                     taskDate = 'ASAP';
        //                 }*/
        //         if (moment(objTaskDate).isAfter(new Date(), "day")) {
        //             dateBadge = "badge-info lighten-1";
        //         } else if (moment(objTaskDate).isBefore(new Date(), "day")) {
        //             dateBadge = "danger badge-danger";
        //         } else if (moment(objTaskDate).isSame(new Date(), "day")) {
        //             dateBadge = "orange lighten-1 badge-orange";
        //         }
        //     }
        // } else {
        //     dateBadge = "grey lighten-1 badge-grey";
        //     //taskDate = 'Queued';
        //     taskDate = "Unplanned";
        // }

        let iconName = "";
        let text = "";
        let className = "";
        let url = "";
        let avatarClassName = "";

        const fields = this.state.fields;
        const priority =
            fields && fields.find((x) => x.PropertyName === "Priority");
        const valueJson = priority && priority.ValueJson;
        let priorityRow =
            valueJson && valueJson.find((x) => x.DisplayName === taskRow.Priority);
        let priorityColor = priorityRow && priorityRow.ColorCode;

        const taskStatus =
            fields && fields.find((x) => x.PropertyName === "TaskStatus");
        const taskStatusJson = taskStatus && taskStatus.ValueJson;
        let statusRow =
            taskStatusJson &&
            taskStatusJson.find((x) => x.DisplayName === taskRow.TaskStatus);
        let statusColor = statusRow && statusRow.ColorCode;

        switch (taskRow.RelatedToType) {
            case "contact":
                iconName = "address-card";
                text = "Contact";
                className = "contact-badge";
                avatarClassName = "contact-avatar";
                url = "/Contact/View/";
                break;
            case "account":
                iconName = "building";
                text = "Company";
                className = "company-badge";
                avatarClassName = "company-avatar";
                url = "/Company/View/";
                break;
            case "deal":
                iconName = "circle-dollar-to-slot";
                text = "Deal";
                className = "deal-badge";
                avatarClassName = "deal-avatar";
                url = "/Deal/View/";
                break;
            case "ticket":
                iconName = "ticket-alt";
                text = "Ticket";
                className = "ticket-badge";
                avatarClassName = "ticket-avatar";
                url = "/Ticket/View/";
                break;
            case "business_process":
                iconName = "server";
                text = "Process";
                className = "ticket-badge";
                avatarClassName = "bp-avatar avatar-text";
                url = "/Process/Manage/";
                break;
            case "complaint":
                iconName = "exclamation-triangle";
                text = "Complaint";
                className = "grey lighten-1 badge-grey";
                avatarClassName = "company-avatar";
                url = "/Complaint/View/";
                break;
            default:
                iconName = "";
                text = "Not set";
                className = "grey lighten-1 badge-grey";
                avatarClassName = "";
                url = "";
                break;
        }

        let relatedTo = (
            <div>
                <div
                    className="d-flex align-items-center"
                    style={{ marginBottom: "2px" }}
                >
                    <TooltipComponent content={text} position="BottomCenter">
                        {iconName !== "" ? (
                            <span
                                className={
                                    "avatar-text-circle transparent-avatar " + avatarClassName
                                }
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    minWidth: "24px",
                                    maxWidth: "24px",
                                    fontSize: "14px",
                                }}
                            >
                                <i className={"fa fa-" + iconName + " "}></i>
                            </span>
                        ) : (
                            <span
                                className={
                                    "avatar-text-circle default-avatar transparent-avatar"
                                }
                                style={{
                                    background: "#bdbdbd",
                                    width: "24px",
                                    height: "24px",
                                    minWidth: "24px",
                                    maxWidth: "24px",
                                    fontSize: "12px",
                                }}
                            >
                                <span>N</span>
                            </span>
                        )}
                    </TooltipComponent>
                </div>
                {/*<div className="d-none d-md-flex align-items-center">
                <div><span className={"me-2 task-module-badge " + className}>{text}</span></div>
            </div>
            <div className="d-flex d-md-none align-items-center" style={{ marginBottom: "2px" }}>
                <span className="pe-2 grey-text">
                    <i className={"fa fa-" + iconName + " "} ></i>
                </span>
            </div>*/}
            </div>
        );

        //let modifiedDate = convertUTCDateToLocalTimezone(taskRow.ModifiedDate);
        let taskDueDate = isNotNullAndUndefined(taskRow.TaskDate)
            ? moment(new Date(taskRow.TaskDate + "Z"))
                .format("DD/MM/YYYY")
                .toString()
            : "No Date";
        //let tooltipModifiedDate = moment(new Date(taskRow.ModifiedDate + "Z")).format("DD/MM/YYYY hh:mm A").toString();
        /*const objTaskDate: Date = convertUTCDateToLocalTimezone(taskRow.TaskDate);
            if (moment(objTaskDate).isSame(moment(Constants.MaxDate), "day")) {
                taskDueDate = 'ASAP';
            }*/
        let titleTooltipDescription = isNotNullAndUndefined(taskRow.Description)
            ? taskRow.Description
            : "";
        let titleTooltipContent =
            "<h6>" +
            taskRow.Title +
            "</h6>" +
            "<p>" +
            titleTooltipDescription +
            "</p>";
        let completedDateLocalDate = convertUTCDateToLocalTimezone(
            taskRow.CompletedDate
        );
        let completedDate = isNotNullAndUndefined(completedDateLocalDate)
            ? moment(completedDateLocalDate).format("DD/MM/YYYY  hh:mm A")
            : null;
        return (
            <tr
                className="taskSectionRow hover-parent-div"
                id={"tr_" + taskRow.UID}
                onClick={() => this.rowClick(taskRow)}
            >
                {isNotNullAndUndefined(this.showDueDateField) &&
                    this.showDueDateField === true && (
                        <td
                            style={{
                                borderBottom: "1px solid #e3e3e7",
                                borderLeft: "1px solid #e3e3e7",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div className="d-flex justify-space-around mx-2">
                                {taskRow.TaskStatus === RAFActionStatus.Planned &&
                                    taskRow.AssigneeUID === loggedinUserId ? (
                                    <div className="md-checkbox ms-2 e-info d-flex align-items-center mt-0 completeTask">
                                        <input
                                            type="checkbox"
                                            className=""
                                            id={"Task_" + taskRow.UID}
                                            name={"Task_" + taskRow.UID}
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.onCheckboxClicked(
                                                    "Task_" + taskRow.UID,
                                                    "additional_btn_" + taskRow.UID
                                                );
                                            }}
                                        />
                                        <label
                                            className=""
                                            htmlFor={"Task_" + taskRow.UID}
                                            style={{ cursor: "pointer" }}
                                        ></label>
                                    </div>
                                ) : null}
                                <div className="text-nowrap d-flex align-items-center">
                                    <TooltipComponent
                                        content={taskDueDate}
                                        position="BottomCenter"
                                    >
                                        <span
                                            className={
                                                taskRow.TaskStatus !== RAFActionStatus.Planned
                                                    ? "hover-hide-child-div activityDateSpanSection badge grey lighten-1 badge-grey"
                                                    : "hide-child-div activityDateSpanSection badge " +
                                                    dateBadge
                                            }
                                        >
                                            {taskDate}
                                        </span>
                                    </TooltipComponent>
                                    <span
                                        className="activityDateSpanSection badge info-color-dark badge-grey hover-show-child-div"
                                    /* onClick={(e) => this.editClick(e, taskRow)}*/
                                    >
                                        <span
                                            className="fal fa-pencil text-white pe-1"
                                            style={{ fontSize: "12px" }}
                                        ></span>
                                        Edit
                                    </span>
                                </div>
                            </div>
                        </td>
                    )}
                <td
                    style={{
                        borderBottom: "1px solid #e3e3e7",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                    }}
                >
                    <div className="d-flex justify-space-around">
                        <div className="d-flex flex-column w-100 justify-content-center px-1 pb-1 ms-1">
                            <TooltipComponent
                                content={titleTooltipContent}
                                position="BottomCenter"
                            >
                                <span className="itemHeader ecllipseFirstLine">
                                    {taskRow.Title}
                                </span>
                            </TooltipComponent>
                        </div>
                        {isNotNullAndUndefined(this.props.showReAssignOption) &&
                            this.props.showReAssignOption === true && (
                                <div className="hover-td-child-div">
                                    <div className="d-flex" id={"additional_btn_" + taskRow.UID}>
                                        <TooltipComponent
                                            content="Assign To me"
                                            position="BottomCenter"
                                        >
                                            <ButtonComponent
                                                type="button"
                                                cssClass="ms-2 border-0 task-icon-btn"
                                                iconCss="fas fa-user"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.onClickAssignToMe(taskRow.UID);
                                                }}
                                            ></ButtonComponent>
                                        </TooltipComponent>
                                    </div>
                                </div>
                            )}
                        {/* {isNotNullAndUndefined(taskRow.Priority) && (
                            <div
                                className="d-flex align-items-center"
                                title={taskRow.Priority}
                            >
                                {taskRow.Priority !== null && (
                                    <i
                                        className="fa fa-flag ms-1 me-2"
                                        style={{
                                            color: priorityColor,
                                            lineHeight: "normal",
                                            fontSize: "12px",
                                        }}
                                    ></i>
                                )}
                            </div>
                        )} */}
                        {isNotNullAndUndefined(taskRow.TaskType) &&
                            taskRow.TaskType === RAFTaskType.Approval && (
                                <div
                                    className="d-flex align-items-center"
                                    title={taskRow.TaskType}
                                >
                                    <i
                                        className="fas fa-user-check ms-1 me-2"
                                        style={{ lineHeight: "normal", fontSize: "12px" }}
                                    ></i>
                                </div>
                            )}
                    </div>
                </td>
                {isNotNullAndUndefined(this.props.showRelatedToField) &&
                    this.props.showRelatedToField === true && (
                        <td
                            style={{
                                borderBottom: "1px solid #e3e3e7",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center mx-2 mx-2">
                                    <div className="swapTextwithHyperlink ms-2 d-flex align-items-center">
                                        {relatedTo}
                                        {isNotNullAndUndefined(taskRow.RelatedTo) ? (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.onRelatedModuleClick(taskRow);
                                                }}
                                            >
                                                <span className="blue-text pe-2 me-2 ecllipseFirstLine">
                                                    {taskRow.RelatedTo}
                                                </span>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="pe-2 me-2 ecllipseFirstLine">
                                                    Not set
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </td>
                    )}
                {isNotNullAndUndefined(this.props.showAssigneeField) &&
                    this.props.showAssigneeField === true && (
                        <td
                            style={{
                                borderBottom: "1px solid #e3e3e7",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center mx-2 mx-2">
                                    <div className="swapTextwithHyperlink ms-2 d-flex align-items-center">
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ marginBottom: "2px" }}
                                        >
                                            <span
                                                className={"avatar-text-circle user-avatar"}
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    minWidth: "24px",
                                                    maxWidth: "24px",
                                                    marginRight: "5px",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <i className={"fas fa-user"}></i>
                                            </span>
                                        </div>
                                        <div>
                                            {/* <div onClick={(e) => { e.stopPropagation(); this.props.relatedModuleClick(url + taskRow.RelatedToUID) }}>*/}
                                            <span className="blue-text pe-2 me-2 ecllipseFirstLine">
                                                {isNotNullAndUndefined(taskRow.Assignee)
                                                    ? taskRow.Assignee
                                                    : "Not set"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    )}
                {isNotNullAndUndefined(this.props.showStatusField) &&
                    this.props.showStatusField === true && (
                        <td
                            style={{
                                borderBottom: "1px solid #e3e3e7",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div className="d-flex justify-content-center">
                                <div
                                    style={{
                                        background: "#" + statusColor,
                                        color: isNotNullAndUndefined(statusColor) ? "white" : null,
                                        padding: "0px 10px",
                                    }}
                                    className="custom_key_value"
                                >
                                    {statusRow.DisplayName}
                                </div>
                            </div>
                        </td>
                    )}
                {isNotNullAndUndefined(this.props.showAssignTeamField) &&
                    this.props.showAssignTeamField === true && (
                        <td
                            style={{
                                borderBottom: "1px solid #e3e3e7",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div className="">
                                <div className="d-flex justify-content-between align-items-center mx-2 mx-2">
                                    <div className="swapTextwithHyperlink ms-2 d-flex align-items-center">
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ marginBottom: "2px" }}
                                        >
                                            <span
                                                className={"avatar-text-circle team-avatar"}
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    minWidth: "24px",
                                                    maxWidth: "24px",
                                                    marginRight: "5px",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                <i className={"fas fa-users"}></i>
                                            </span>
                                        </div>
                                        <div>
                                            {/* <div onClick={(e) => { e.stopPropagation(); this.props.relatedModuleClick(url + taskRow.RelatedToUID) }}>*/}
                                            <span className="blue-text pe-2 me-2 ecllipseFirstLine">
                                                {isNotNullAndUndefined(taskRow.AssignTeam)
                                                    ? taskRow.AssignTeam
                                                    : "Not set"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    )}
                {isNotNullAndUndefined(this.showCompletedDateField) &&
                    this.showCompletedDateField === true && (
                        <td
                            style={{
                                borderBottom: "1px solid #e3e3e7",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                            }}
                        >
                            <div>
                                <div style={{ padding: "0px 10px", fontSize: "13px" }}>
                                    {completedDate}
                                </div>
                            </div>
                        </td>
                    )}
                <td
                    style={{
                        borderBottom: "1px solid #e3e3e7",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {/* <div className="userProfile ">
                    <TooltipComponent content={tooltipModifiedDate} position="BottomCenter">
                        <span className="text-nowrap d-flex align-items-center" id="recordInfo">
                            <i className="activity-date far fa-clock pe-2"></i>
                            <span className="activity-date">{moment(modifiedDate).subtract(20, 's').fromNow()}</span>
                        </span>
                    </TooltipComponent>
                </div>*/}
                    <div className="">
                        <div className="recordInfomsg">
                            <RAFRecordInfo
                                createdBy={taskRow.CreatedBy}
                                createdDate={taskRow.CreatedDate}
                                modifiedDate={taskRow.ModifiedDate}
                                modifiedBy={taskRow.ModifiedBy}
                                lastActivityDate={taskRow.LastActivityDate}
                                content=""
                            ></RAFRecordInfo>
                        </div>
                    </div>
                </td>
                {/* <td style={{ borderBottom: '1px solid #e3e3e7', paddingTop: '15px', paddingBottom: '15px' }}>
                <div className="d-flex align-items-center mx-2">
                    <span className="avatar-text-circle user-avatar" style={{ width: '24px', height: '24px', minWidth: '24px', maxWidth: '24px', marginRight: '5px', fontSize: '12px' }}>
                        <span className="fas fa-user"></span>
                    </span>
                    <div className="gridTempleteTextOuter"><span className="ms-2" style={{ cursor: 'pointer' }}>{isNotNullAndUndefined(taskRow.Assignee) ? taskRow.Assignee : 'Not set'}</span></div>
                </div>
            </td>
            <td style={{ borderBottom: '1px solid #e3e3e7', paddingTop: '15px', paddingBottom: '15px' }}>
                <span className="itemHeader ecllipseFirstLine mx-2">
                    {taskRow.TaskStatus}
                </span>
            </td>*/}
            </tr>
        );
    }

    private renderGrid = (fields?: QueryAttributeJM[]) => {
        let gridData = { result: null, count: null };
        if (isNotNullAndUndefined(this.state.data)) {
            gridData = {
                result: this.state.data.result,
                count: this.state.data.count,
            };
        }
        /*
            let addIdColumn: boolean = true;
            if (isNotNullAndUndefined(fields)) {
                if (fields.findIndex(x => x.isPrimaryKey) >= 0) {
                    addIdColumn = false;
                }
            }
    
            const commandModel: CommandModel[] = getCommandModel();
            */

        return (
            <div className="e-bigger h-100">
                <GridComponent
                    dataSource={gridData}
                    ref={(g) => (this.grid = g)}
                    allowPaging
                    pageSettings={this.pageSettings}
                    allowSorting
                    sortSettings={this.sortingOptions}
                    allowFiltering={this.allowFiltering}
                    {...(this.allowFiltering === true
                        ? {
                            filterSettings: !Browser.isDevice
                                ? this.filterSettings
                                : this.filterOptions,
                        }
                        : {})}
                    allowGrouping
                    allowMultiSorting={false}
                    groupSettings={this.groupSettings}
                    allowSelection
                    selectionSettings={this.selectionsettingsModel}
                    allowResizing
                    resizeSettings={{ mode: "Normal" }}
                    //clipMode={"EllipsisWithTooltip"}
                    height="100%"
                    gridLines="Both"
                    className={
                        this.allowFiltering
                            ? "rafSFGrid hidden hover-table-div h-100 "
                            : "rafSFGrid hover-table-div h-100 " +
                            (this.isAdaptive ? "e-device" : " ")
                    }
                    enableAdaptiveUI
                    rowRenderingMode={
                        Browser.isDevice === true || this.isAdaptive
                            ? "Vertical"
                            : "Horizontal"
                    }
                    allowPdfExport
                    pdfHeaderQueryCellInfo={this.pdfHeaderQueryCellInfo}
                    allowExcelExport
                    commandClick={this.commandClicked.bind(this)}
                    recordClick={
                        this.allowEditing && this.showEditColumn
                            ? null
                            : this.recordClicked.bind(this)
                    }
                    created={this.created}
                    dataBound={this.dataBound.bind(this)}
                    dataStateChange={
                        this.isRemote ? this.dataStateChange.bind(this) : null
                    }
                    rowSelected={this.rowSelected.bind(this)}
                    rowDeselected={this.rowDeSelected.bind(this)}
                    actionBegin={this.actionBegin.bind(this)}
                    actionComplete={this.actionComplete.bind(this)}
                    //rowTemplate={!Browser.isDevice ? this.rowTemplate.bind(this) : null}
                    //rowTemplate={this.rowTemplate.bind(this)}

                    frozenColumns={
                        this.props.frozenColumns ? this.props.frozenColumns : null
                    }
                >
                    <ColumnsDirective>
                        {!Browser.isDevice && this.allowSelection && (
                            <ColumnDirective
                                type="checkbox"
                                width="40px"
                                maxWidth="40px"
                                minWidth="40px"
                                allowResizing={false}
                                autoFit
                                textAlign="Center"
                                customAttributes={{ class: "gridcheckboxclass" }}
                                allowEditing={false}
                            //editTemplate={this.emptyTemplate}
                            />
                        )}

                        {this.getColumnsDirective(fields)}
                    </ColumnsDirective>
                    <Inject
                        services={[
                            Page,
                            Sort,
                            Filter,
                            Group,
                            Edit,
                            Toolbar,
                            Freeze,
                            Resize,
                            PdfExport,
                            ExcelExport,
                            CommandColumn,
                        ]}
                    />
                </GridComponent>
            </div>
        );
    };

    private getEmptyRecordMessage = () => {
        let emptyRecord = "";
        if (this.emptyStateProps) {
            emptyRecord = "<div class='empty-state-container no-record-div'>";
            let stateClassName = isNotNullAndUndefined(this.emptyStateProps.image)
                ? "empty-state-image"
                : "empty-state-icon";
            if (this.emptyStateProps.image || this.emptyStateProps.iconClass) {
                emptyRecord = emptyRecord + "<div class=" + stateClassName + ">";
                if (this.emptyStateProps.image) {
                    emptyRecord =
                        emptyRecord +
                        "<img src='" +
                        this.emptyStateProps.image +
                        "' alt='Icon Image' class='' />";
                } else {
                    emptyRecord =
                        emptyRecord +
                        "<span class='" +
                        this.emptyStateProps.iconClass +
                        "'></span>";
                }
                emptyRecord = emptyRecord + "</div>";
            }
            if (this.emptyStateProps.title) {
                emptyRecord = emptyRecord + "<div class='empty-state-title'>";
                emptyRecord =
                    emptyRecord + "<span class='no-record-text1'>" + this.emptyStateProps.title + "</span>";
                emptyRecord = emptyRecord + "</div>";
            }
            if (this.emptyStateProps.body) {
                emptyRecord = emptyRecord + "<div class='empty-state-content'>";
                emptyRecord =
                    emptyRecord + "<span>" + this.emptyStateProps.body + "</span>";
                emptyRecord = emptyRecord + "</div>";
            }

            emptyRecord = emptyRecord + "</div>";
        } else {
            emptyRecord =
                '<div class="no-record-div" id="noRecordDiv11" style="text-align: center;"><img src="' +
                noRecordIcon +
                '" width="52px" class="my-3" /><span class="no-record-text">No data available at the moment!</span><span class="no-record-text1"></span></div>';
        }
        /*else {
                emptyRecord = '<div class="no-record-div" id="noRecordDiv11" style="text-align: center;"><img src="' +
                    noRecordIcon +
                    '" width="52px" class="my-3" /><span class="no-record-text">No data available at the moment!</span><span class="no-record-text1"></span></div>';
            }*/

        return emptyRecord;
    };

    private created = () => {
        let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
        if (this.grid && this.grid.element) {
            const gridParentDiv = this.grid.element.closest("#divGridParent");
            if (gridParentDiv) {
                const customSpinner = gridParentDiv.querySelector(
                    "#customSpinner"
                ) as HTMLElement;
                if (
                    isNotNullAndUndefined(customSpinner) &&
                    !IsNullOrWhiteSpace(customSpinner.innerHTML)
                ) {
                    spinnerTarget = gridParentDiv.querySelector(
                        "#customSpinner"
                    ) as HTMLElement;
                    customSpinner.classList.remove('hidden');
                }
            }
        }
        createSpinner({
            target: spinnerTarget,
            width: "20px",
            template:
                '<div class="row g-0 wrapper-content loadingPanelGrid"> <div class="d-flex flex-column align-items-center" > <img src=' +
                loaderIcon +
                ' width="52" /> <span class="loadingText">Loading...</span> </div> </div >',
        });
        showSpinner(spinnerTarget);

        let emptyRecord: string = this.getEmptyRecordMessage();

        if (!IsNullOrWhiteSpace(emptyRecord) && isNotNullAndUndefined(this.grid)) {
            if (this.grid["localeStrings"]) {
                this.grid["localeStrings"]["EmptyRecord"] = emptyRecord;
            }
            if (this.grid.localeObj["localeStrings"]) {
                this.grid.localeObj["localeStrings"]["EmptyRecord"] = emptyRecord;
            }
            this.grid.localeObj["currentLocale"] = {
                EmptyRecord: emptyRecord,
            };
        }
        /*else {
                this.grid.localeObj['currentLocale'] = {
                    EmptyRecord:
                        '<div class="no-record-div" id="noRecordDiv11" style="text-align: center;"><img src="' +
                        noRecordIcon +
                        '" width="52px" class="my-3" /><span class="no-record-text">No data available at the moment!</span><span class="no-record-text1"></span></div>',
                };
            }*/

        /*if (showHeader) {
    
            }
            else {
                grid.getHeaderTable().classList.add('hidden');
                grid.getHeaderTable().parentElement.parentElement.classList.add('borderTop0');
                grid.getHeaderTable().parentElement.parentElement.parentElement.classList.add('borderBottom0');
            }*/
    };

    private dataBound = (args) => {
        if (isNotNullAndUndefined(this.grid)) {
            const currentViewRecords = this.grid.getCurrentViewRecords();
            this.grid.element.classList.remove("hidden");
            if (
                isNotNullAndUndefined(currentViewRecords) &&
                currentViewRecords.length > 0
            ) {
                this.grid.element.classList.add("fade-in");
                document.querySelector(".e-spinner-pane").classList.add("hidden");
                if (!this.allowFiltering) {
                    this.grid.getHeaderContent().classList.remove("hidden");
                }
            } else {
                document.querySelector(".e-spinner-pane").classList.add("hidden");
                if (!this.allowFiltering) {
                    this.grid.getHeaderContent().classList.add("hidden");
                }
            }

            this.grid.getPager().classList.add("hidden");
            if (isNotNullAndUndefined(this.grid.dataSource)) {
                if (
                    isNotNullAndUndefined(this.grid.dataSource["count"]) &&
                    this.grid.dataSource["count"] > 10
                ) {
                    this.grid.getPager().classList.remove("hidden");
                }

                if (
                    isNotNullAndUndefined(this.grid.dataSource["count"]) &&
                    this.grid.dataSource["count"] > this.grid.pageSettings.pageSize
                ) {
                    this.grid.getPager().classList.remove("hidden");
                } else if (
                    isNotNullAndUndefined(this.grid.dataSource["length"]) &&
                    this.grid.dataSource["length"] > this.grid.pageSettings.pageSize
                ) {
                    this.grid.getPager().classList.remove("hidden");
                }
            }

            if (isNotNullAndUndefined(this.grid.filterModule)) {
                Object.assign(this.grid.filterModule.filterOperators, {
                    startsWith: "contains",
                });
            }
            const tgridWidth = this.grid.widthService.getTableWidth(
                this.grid.getColumns()
            );
            this.grid.widthService.setMinwidthBycalculation(Number(tgridWidth));
        }

        // no record table width
        // no record table width
        let rafGridElement = isNotNullAndUndefined(this.grid)
            ? this.grid.element.closest(".rafSFGrid")
            : document.querySelector(".rafSFGrid");
        let rafGridContentContainer = isNotNullAndUndefined(this.grid)
            ? this.grid.element.closest('.grid-content-container')
            : document.querySelector('.grid-content-container');
        let rafGridElementTableElement =
            rafGridElement && rafGridElement.querySelectorAll(".e-table");
        if (isNotNullAndUndefined(this.grid)) {
            let currentGridLength = this.grid.getCurrentViewRecords().length;
            // if (isNotNullAndUndefined(rafGridElementTableElement)) {
            //     rafGridElementTableElement.forEach((element) => {
            //         if (currentGridLength > 0) {
            //             element.classList.remove("no-record");
            //         } else {
            //             element.classList.add("no-record");
            //         }
            //     });
            // }
            if (isNotNullAndUndefined(rafGridContentContainer)) {
                if (currentGridLength > 0) {
                    rafGridContentContainer.classList.remove('empty-grid');
                } else {
                    rafGridContentContainer.classList.add('empty-grid');
                }
            }

            const emptyRow = rafGridElement.querySelector(".e-emptyrow>td");
            if (emptyRow) {
                // changing the message as default once data is loaded
                emptyRow.innerHTML = this.getEmptyRecordMessage();
            }
        }
        this.hideGridSpinner();
    };

    private dataStateChange = (state1: DataStateChangeEventArgs) => {
        //console.log('dataStateChange', state);
        if (this.grid) {
            let toolbarMenu = document.getElementById(
                this.grid.element.id + "_toolbarItems"
            );
            if (isNotNullAndUndefined(toolbarMenu))
                toolbarMenu.classList.add("hidden");

            const actionRequestType: string = state1.action.requestType;
            if (actionRequestType === "filtering") {
                let objCustomFilter: RAFCustomFilter = getCustomFilterBySFGridFilter(
                    state1 && state1.where
                );
                this.hideToolbarMenu();
                this.showGridSpinner();
                this.props.actions.filtering(objCustomFilter);
                this.grid.clearSelection();
            } else if (actionRequestType === "paging") {
                this.hideToolbarMenu();
                this.showGridSpinner();

                this.props.actions.paging(state1.skip / state1.take + 1, state1.take);
                this.grid && this.grid.clearSelection();
                /*this.setState({ loading: true }, () => {
        
                            this.props.actions.paging(state1.skip / state1.take + 1, state1.take);
                            this.grid && this.grid.clearSelection();
                        })*/
            } else if (actionRequestType === "sorting") {
                if (
                    isNotNullAndUndefined(state1.sorted) &&
                    state1.sorted.length > 0 &&
                    isNotNullAndUndefined(state1.sorted[0].name)
                ) {
                    this.hideToolbarMenu();
                    this.showGridSpinner();
                    this.props.actions.sorting({
                        field: state1.sorted[0].name,
                        order: state1.sorted[0].direction,
                    });
                    this.grid.clearSelection();
                } else {
                    this.hideToolbarMenu();
                    this.showGridSpinner();
                    this.props.actions.sorting(null);
                    this.grid.clearSelection();
                }
            } else {
                this.hideGridSpinner();
            }
        }
    };

    private pdfHeaderQueryCellInfo = (args: PdfHeaderQueryCellInfoEventArgs) => {
        (args.cell as any).row.pdfGrid.repeatHeader = true;
    };

    private commandClicked = (e: CommandClickEventArgs) => {
        //console.log('commandClicked', e);
        const classList = e.target.classList;
        if (classList.contains("e-editbutton")) {
            if (this.allowEditing && this.showEditColumn) {
                //default grid inline editing
            } else {
                if (this.props.editClick) {
                    this.props.editClick(e.rowData);
                }
            }
        }
    };

    private recordClicked = (e: RecordClickEventArgs) => {
        const classList = e.target.classList;
        if (
            classList.contains("e-gridchkbox") ||
            classList.contains("e-checkbox-wrapper") ||
            classList.contains("noRowclick") ||
            classList.contains("e-uncheck") ||
            classList.contains("e-check") ||
            (isNotNullAndUndefined(e.target.closest(".e-gridchkbox")) &&
                isNotNullAndUndefined(e.target.closest(".e-gridchkbox").innerHTML))
        )
            return;
        else if (
            isNotNullAndUndefined(e.target.closest(".e-unboundcell")) &&
            isNotNullAndUndefined(e.target.closest(".e-unboundcell").innerHTML)
        ) {
            return;
        } else if (
            isNotNullAndUndefined(e.target.closest(".e-editbutton")) &&
            isNotNullAndUndefined(e.target.closest(".e-editbutton").innerHTML)
        ) {
            this.editClick(e.rowData);
        } else if (
            isNotNullAndUndefined(e.target.closest(".e-completebutton")) &&
            isNotNullAndUndefined(e.target.closest(".e-completebutton").innerHTML)
        ) {
            this.props.completeClicked(e.rowData, RAFActionStatus.Completed);
        } else if (
            isNotNullAndUndefined(e.target.closest(".raf-reassignee")) &&
            isNotNullAndUndefined(e.target.closest(".raf-reassignee").innerHTML)
        ) {
            this.onClickAssignToMe(e.rowData);
        } else if (
            isNotNullAndUndefined(e.target.closest(".raf-relatedTo")) &&
            isNotNullAndUndefined(e.target.closest(".raf-relatedTo").innerHTML)
        ) {
            this.onRelatedModuleClick(e.rowData);
        } else {
            this.rowClick(e.rowData, e.row);
        }
    };

    private rowClick = (taskRow, e?: any) => {
        if (this.props.rowClick) {
            this.props.rowClick(taskRow, e);
        }
    };

    private editClick = (taskRow) => {
        if (this.props.editClick) {
            this.props.editClick(taskRow);
        }
    };

    private onClickAssignToMe = (taskRow) => {
        if (this.props.onClickAssignToMe) {
            this.props.onClickAssignToMe(taskRow.UID);
        }
    };

    private onRelatedModuleClick = (taskRow: TaskRow) => {
        if (this.props.relatedModuleClick) {
            this.props.relatedModuleClick(taskRow);
        }
    };

    private rowSelected = () => {
        if (isNotNullAndUndefined(this.grid)) {
            this.selectedRecords = this.grid.getSelectedRecords();
            if (this.props.rowSelected)
                this.props.rowSelected(this.grid.getSelectedRecords());
        }
    };

    private rowDeSelected = () => {
        if (isNotNullAndUndefined(this.grid)) {
            this.selectedRecords = this.grid.getSelectedRecords();
            if (this.props.rowSelected)
                this.props.rowSelected(this.grid.getSelectedRecords());
        }
    };

    private actionBegin = (args) => {
        const actionRequestType: Action = args.requestType;
        if (actionRequestType === "save") {
            const objData = args.data;
            args.cancel = true;
            this.grid.closeEdit(); //cancel the edit
            if (this.props.saveData) {
                this.props.saveData(objData, this.props.idField).then(() => {
                    this.grid.setRowData(objData[this.props.idField], objData); // update the edited data in Grid while click on the another cell
                });
            }
        }
        if (args.requestType === "filterbeforeopen") {
            args.filterModel.customFilterOperators.stringOperator =
                this.stringOperators;
            let allFields: QueryAttributeJM[] = this.props.fields;
            let selectedField: QueryAttributeJM =
                allFields && allFields.find((x) => x.PropertyName === args.columnName);
            if (selectedField && selectedField.DataType === RAFDataType.Date) {
                args.filterModel.customFilterOperators.dateOperator = [
                    { value: "equal", text: "Equal" },
                    { value: "notequal", text: "Not Equal" },
                ];
            }
            if (selectedField && selectedField.DataType === RAFDataType.Dropdown) {
                args.filterModel.customFilterOperators.stringOperator = [
                    { value: "equal", text: "Equal" },
                    { value: "notequal", text: "Not Equal" },
                ];
            }
            if (selectedField && selectedField.DataType === RAFDataType.Boolean) {
                args.filterModel.customFilterOperators.stringOperator = [
                    { value: "equal", text: "Equal" },
                    { value: "notequal", text: "Not Equal" },
                ];
            }
            if (selectedField && selectedField.DataType === RAFDataType.Lookup) {
                args.filterModel.customFilterOperators.stringOperator = [
                    { value: "equal", text: "Equal" },
                    { value: "notequal", text: "Not Equal" },
                ];
            }
        } else {
            const actionRequestType: Action = args.requestType;
            switch (actionRequestType) {
                case "filtering":
                case "grouping":
                case "paging":
                case "refresh":
                case "sorting":
                case "ungrouping":
                    if (isNotNullAndUndefined(this.props.gridId))
                        SetGridState(
                            this.props.gridId,
                            JSON.parse(this.grid.getPersistData()),
                            null
                        );
                    break;
                default:
                    break;
            }
        }
    };

    private actionComplete = (args: ActionEventArgs) => {
        if (this.grid) {
            const filterColumnsModel = this.grid.filterSettings.columns;
            const norecordsText1Element = this.grid.element.querySelector(
                ".no-record-div .no-record-text1"
            );
            if (isNotNullAndUndefined(norecordsText1Element)) {
                norecordsText1Element.textContent = "";
                if (
                    isNotNullAndUndefined(filterColumnsModel) &&
                    filterColumnsModel.length > 0
                ) {
                    norecordsText1Element.textContent =
                        "We're sorry. We cannot find any matches for your search criteria.";
                } else if (this.hasOtherViews && this.hasOtherViews === true) {
                    norecordsText1Element.textContent =
                        "This view may contain filters. Please try with a different view.";
                }
            }
            const filterMessage: string =
                this.ConstructFilterMessage(filterColumnsModel);
            const gridContentDiv = document.querySelector(".rafSFGrid");
            const filterMessageBar =
                gridContentDiv &&
                gridContentDiv.parentElement.querySelector(".filterMessageBar");

            let filterBarDiv = '<div class="filterMessageBar d-flex fade-in"> <div> ' +
                filterMessage +
                '</div> <div class="d-flex"><span style="cursor:pointer" title="Clear search criteria" class="fas fa-xmark clearFilter"></span> </div> </div>';
            if (isNotNullAndUndefined(gridContentDiv) !== null) {
                if (
                    isNotNullAndUndefined(filterMessage) &&
                    !IsNullOrWhiteSpace(filterMessage)
                ) {
                    if (isNotNullAndUndefined(filterMessageBar))
                        filterMessageBar.remove();
                    gridContentDiv.insertAdjacentHTML('afterbegin', filterBarDiv);
                    const clearFilter = document.querySelector(".clearFilter");
                    if (isNotNullAndUndefined(clearFilter))
                        clearFilter.addEventListener("click", () => {
                            this.grid.clearSelection();
                            this.grid.clearFiltering();
                            this.refreshData(true);
                            setTimeout(() => {
                                if (this.grid) {
                                    this.grid.refreshHeader();
                                }
                            }, 10);
                        });

                    setTimeout(() => {
                        this.changeGridContentHeight();
                    });
                } else {
                    if (isNotNullAndUndefined(filterMessageBar)) {
                        filterMessageBar.remove();
                    }
                    setTimeout(() => {
                        this.changeGridContentHeight();
                    });
                }
            }

            const actionRequestType: Action = args.requestType;
            switch (actionRequestType) {
                case "filtering":
                case "grouping":
                case "paging":
                case "refresh":
                case "sorting":
                case "ungrouping":
                    if (isNotNullAndUndefined(this.props.gridId))
                        SetGridState(
                            this.props.gridId,
                            JSON.parse(this.grid.getPersistData()),
                            null
                        );
                    break;
                case "cancel":
                    setTimeout(() => {
                        this.grid.clearRowSelection();
                    }, 10);
                    break;
                default:
                    break;
            }
        }
    };

    private toolbarActionClickHandler = (args: ClickEventArgs) => {
        if (args.item.text === "Delete") {
            if (this.props.deleteClicked) {
                this.props.deleteClicked(this.grid.getSelectedRecords());
                this.grid.clearSelection();
            }
        }
    };

    private beforeRender = (args) => {
        let innerText = args.target.closest("td").innerText;
        let closestTd = args.target.closest("td");
        this.tooltip.content = innerText;
        this.tooltip.cssClass = "";
        if (IsNullOrWhiteSpace(innerText)) {
            this.tooltip.cssClass = "d-none";
        }
        if (
            isNotNullAndUndefined(closestTd) &&
            isNotNullAndUndefined(closestTd.querySelector(".removeTooltip"))
        ) {
            this.tooltip.cssClass = "d-none";
        }
    };

    /* private emptyTemplate = (props1: any) => {
           return <div></div>;
       };*/

    /***/
    private filterTemplate = (props1: QueryAttributeJM) => {
        const objSuffix = `${Constants.idSuffix}${IsNullOrWhiteSpace(this.props.viewId)
            ? IsNullOrWhiteSpace(this.props.viewName)
                ? IsNullOrWhiteSpace(this.props.gridId)
                    ? ""
                    : this.props.gridId
                : this.props.viewName
            : this.props.viewId
            }`;
        let filterVal = null;
        let model: GridModel = null;
        if (isNotNullAndUndefined(this.props.gridId)) {
            model = GetGridModelFromGridState(this.props.gridId);
            if (isNotNullAndUndefined(model)) {
                const objfilterSettings: FilterSettingsModel = model.filterSettings;
                if (isNotNullAndUndefined(objfilterSettings) && isNotNullAndUndefined(objfilterSettings.columns)) {
                    const objFilterCol = objfilterSettings.columns.find(
                        (x) => x.field === props1.PropertyName
                    );
                    if (isNotNullAndUndefined(objFilterCol)) {
                        filterVal = objFilterCol.value;
                    }
                }
            }
        }
        let retVal: IFilterUI;
        if (props1) {
            if (props1.DataType === RAFDataType.Dropdown) {
                if (isNotNullAndUndefined(props1.UIType) && props1.UIType === RAFUIType.ComboBox) {
                    retVal = {
                        create: () => {
                            let valueElement = document.createElement("input");
                            valueElement.setAttribute("type", "text");
                            valueElement.id = props1.PropertyName + objSuffix;
                            return valueElement;
                        },
                        write: (args: {
                            element: Element;
                            values: string[] | string;
                            column: Column;
                        }) => {
                            let dataSource: { [key: string]: Object; }[] = [];
                            props1.ValueJson &&
                                props1.ValueJson.forEach((opt) => {
                                    dataSource.push({
                                        label: opt.DisplayName,
                                        value: opt.DisplayName,
                                    });
                                });

                            let fields: object = { text: "label", value: "value" };
                            let comboBox = new ComboBox({
                                dataSource: dataSource,
                                fields: fields,
                                value: args.values
                                    ? (args.values as string)
                                    : isNotNullAndUndefined(filterVal)
                                        ? filterVal
                                        : null,
                                change: (e: any) => {
                                    if (e.itemData)
                                        this.grid.filterByColumn(
                                            truncateAfter(args.element.id, Constants.idSuffix),
                                            "contains",
                                            e.itemData !== null ? e.itemData.value : null
                                        );
                                    else
                                        this.grid.removeFilteredColsByField(
                                            truncateAfter(args.element.id, Constants.idSuffix),
                                            true
                                        );
                                },
                                placeholder: props1.DisplayName,
                                showClearButton: true,
                            });
                            comboBox.appendTo("#" + args.element.id);
                        },
                    };

                } else {
                    retVal = {
                        create: () => {
                            let valueElement = document.createElement("input");
                            valueElement.setAttribute("type", "text");
                            valueElement.id = props1.PropertyName + objSuffix;
                            return valueElement;
                        },
                        write: (args: {
                            element: Element;
                            values: string[] | string;
                            column: Column;
                        }) => {
                            let dataSource: { [key: string]: Object; }[] = [];
                            if (props1.IsRequired === false) {
                                dataSource.push({
                                    label: "None",
                                    value: "None",
                                });
                            }
                            props1.ValueJson &&
                                props1.ValueJson.forEach((opt: ValueJson) => {
                                    dataSource.push({
                                        label: opt.DisplayName,
                                        value: opt.DisplayName,
                                    });
                                });
                            let fields: object = { text: "label", value: "value" };
                            let dropDownObj = new DropDownList({
                                dataSource: dataSource,
                                fields: fields,
                                value: args.values
                                    ? (args.values as string)
                                    : isNotNullAndUndefined(filterVal)
                                        ? filterVal
                                        : null,
                                change: (e: any) => {
                                    if (e.itemData)
                                        this.grid.filterByColumn(
                                            truncateAfter(args.element.id, Constants.idSuffix),
                                            props1.IsMultiSelect ? "contains" : "equal",
                                            e.itemData !== null ? e.itemData.value : null
                                        );
                                    else
                                        this.grid.removeFilteredColsByField(
                                            truncateAfter(args.element.id, Constants.idSuffix),
                                            true
                                        );
                                },
                                placeholder: props1.DisplayName,
                                showClearButton: true,
                            });
                            dropDownObj.appendTo("#" + args.element.id);
                        },
                    };
                }
            }

            if (props1.DataType === RAFDataType.Lookup) {
                let url;
                if (isNotNullAndUndefined(props1.RelatedEntities)) {
                    const firstItem = props1.RelatedEntities;
                    url = isNotNullAndUndefined(firstItem)
                        ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
                        : null;
                }
                retVal = {
                    create: () => {
                        let valueElement = document.createElement("input");
                        valueElement.setAttribute("type", "text");
                        valueElement.id = props1.PropertyName + objSuffix;
                        return valueElement;
                    },
                    write: (args: {
                        element: Element;
                        values: string[] | string;
                        column: Column;
                    }) => {
                        const lookupFields = { text: "Value", value: "Value" };
                        let skip = 0;
                        let take: number = Constants.DropdownFetchCount;
                        let filteredSkip = 0;
                        let filteredTake: number = Constants.DropdownFetchCount;
                        const lookupData: RAFDataManager = new RAFDataManager({
                            adaptor: new RAFUrlAdaptor({
                                Skip: 0,
                                Take: Constants.DropdownFetchCount,
                            }),
                            crossDomain: true,
                            url: Constants.baseAPIUrl + url,
                            requestType: "POST",
                            headers: [],
                        });
                        const setFilteringDebounce = debounce((args1) => {
                            filteredSkip = 0;
                            filteredTake = Constants.DropdownFetchCount;
                            lookupData.adaptor = new RAFUrlAdaptor({
                                Skip: filteredSkip,
                                Take: filteredTake,
                            });
                            let query = new Query();
                            query =
                                args1.text !== ""
                                    ? query.where(
                                        props1.PropertyName,
                                        "contains",
                                        args1.text,
                                        true
                                    )
                                    : query;
                            args1.updateData(lookupData, query);
                        }, 500);
                        const actionBegin = (args1) => {
                            if (args1.query === undefined) {
                                //temp fix to prevent repeated api calls
                                args1.cancel = true;
                            }
                        };
                        const actionComplete = (e) => {
                            e.result = R.uniq(e.result);
                        };
                        const opened = () => {
                            let listElement: HTMLElement = (lookupObj as any).list;
                            listElement.addEventListener("scroll", (args) => {
                                if (
                                    listElement.scrollTop + listElement.offsetHeight + 1 >=
                                    listElement.scrollHeight
                                ) {
                                    setScrollDebounce(args);
                                }
                            });
                        };
                        const onCreated = () => {
                            if (lookupObj) {
                                const tooltip = new Tooltip({
                                    beforeRender: (args1: TooltipEventArgs) => {
                                        if (isNotNullAndUndefined(tooltip)) {
                                            tooltip.content = args1.target.textContent;
                                        }
                                    },
                                    content: "Loading...",
                                    position: "TopCenter",
                                    target:
                                        isNotNullAndUndefined(lookupObj) &&
                                            isNotNullAndUndefined(lookupObj.element)
                                            ? `#${lookupObj.element.id}_popup .e-list-item`
                                            : ".e-list-item",
                                });
                                tooltip.appendTo("body");
                            }
                        };
                        const onClosed = () => {
                            let openTooltips = document.querySelectorAll(
                                "div.e-tooltip-wrap.e-popup-open"
                            );
                            if (isNotNullAndUndefined(openTooltips)) {
                                openTooltips.forEach((x) => {
                                    x.classList.add("hidden");
                                });
                            }
                        };
                        const setScrollDebounce = debounce(() => {
                            let filterQuery = isNotNullAndUndefined(lookupObj.query)
                                ? lookupObj.query.clone()
                                : new Query();
                            const filteredText =
                                lookupObj["searchKeyModule"]["element"]["value"];
                            if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                                filteredSkip += filteredTake;
                                filterQuery = filterQuery.where(
                                    props1.PropertyName,
                                    "contains",
                                    filteredText,
                                    true
                                );
                                lookupData.adaptor = new RAFUrlAdaptor({
                                    Skip: filteredSkip,
                                    Take: filteredTake,
                                });
                            } else {
                                skip += take;
                                lookupData.adaptor = new RAFUrlAdaptor({
                                    Skip: skip,
                                    Take: take,
                                });
                            }
                            lookupData
                                .executeQuery(filterQuery)
                                .then((event) => {
                                    lookupObj.addItem(
                                        (event as any).result as { [key: string]: Object; }[]
                                    );
                                })
                                .catch(() => { });
                        }, 500);
                        const lookupObj: DropDownList = new DropDownList({
                            value: isNotNullAndUndefined(args.values)
                                ? (args.values as string)
                                : isNotNullAndUndefined(filterVal)
                                    ? filterVal
                                    : null,
                            placeholder: props1.DisplayName,
                            showClearButton: true,
                            change: (e: any) => {
                                if (e.itemData)
                                    this.grid &&
                                        this.grid.filterByColumn(
                                            truncateAfter(args.element.id, Constants.idSuffix),
                                            "equal",
                                            e.itemData !== null ? e.itemData["Value"] : null
                                        );
                                else
                                    this.grid.removeFilteredColsByField(
                                        truncateAfter(args.element.id, Constants.idSuffix),
                                        true
                                    );
                            },
                            fields: lookupFields,
                            dataSource: lookupData,
                            allowFiltering: true,
                            filterType: "Contains",

                            filtering: (e: FilteringEventArgs) => {
                                e.preventDefaultAction = true;
                                setFilteringDebounce(e);
                            },
                            actionBegin: actionBegin,
                            actionComplete: actionComplete,
                            created: onCreated,
                            close: onClosed,
                            open: () => {
                                opened();
                            },
                        });
                        const objElement = document.querySelector("#" + args.element.id);
                        if (
                            isNotNullAndUndefined(objElement) &&
                            isNotNullAndUndefined(objElement.classList) &&
                            !objElement.classList.contains("e-dropdownlist")
                        ) {
                            lookupObj.appendTo("#" + args.element.id);
                        }
                    },
                };
            }

            if (props1.DataType === RAFDataType.Date) {
                retVal = {
                    create: () => {
                        let valueElement = document.createElement("input");
                        valueElement.setAttribute("type", "text");
                        valueElement.id = props1.PropertyName + objSuffix;
                        return valueElement;
                    },
                    write: (args: { element: Element; values: Date; column: Column; }) => {
                        const datePickerObj: DateRangePicker = new DateRangePicker({
                            value: args.values
                                ? (args.values as Date)
                                : isNotNullAndUndefined(filterVal)
                                    ? filterVal
                                    : null,
                            presets: presetDateModel,
                            format: RAFDatePickerViewFormat.DATETIME,
                            change: (e: any) => {
                                if (e.value) {
                                    this.grid.filterSettings.columns =
                                        this.grid.filterSettings.columns.filter(
                                            (x) =>
                                                x.field !==
                                                truncateAfter(args.element.id, Constants.idSuffix)
                                        );
                                    this.grid.filterSettings.columns.push({
                                        value: e.value !== null ? e.value[0] : null,
                                        operator: "greaterthanorequal",
                                        field: truncateAfter(args.element.id, Constants.idSuffix),
                                        predicate: "and",
                                    });
                                    this.grid.filterSettings.columns.push({
                                        value: e.value !== null ? e.value[1] : null,
                                        operator: "lessthanorequal",
                                        field: truncateAfter(args.element.id, Constants.idSuffix),
                                        predicate: "and",
                                    });
                                } else {
                                    this.grid.filterSettings.columns =
                                        this.grid.filterSettings.columns.filter(
                                            (x) =>
                                                x.field !==
                                                truncateAfter(args.element.id, Constants.idSuffix)
                                        );
                                    this.grid.removeFilteredColsByField(
                                        truncateAfter(args.element.id, Constants.idSuffix),
                                        true
                                    );
                                }
                            },
                            placeholder: props1.DisplayName,
                            showClearButton: true,
                        });
                        const objElement = document.querySelector("#" + args.element.id);
                        if (
                            isNotNullAndUndefined(objElement) &&
                            isNotNullAndUndefined(objElement.classList) &&
                            !objElement.classList.contains("e-daterangepicker")
                        ) {
                            datePickerObj.appendTo("#" + args.element.id);
                        }
                    },
                };
            }

            if (props1.DataType === RAFDataType.Boolean) {
                retVal = {
                    create: () => {
                        let valueElement = document.createElement("input");
                        valueElement.setAttribute("type", "text");
                        valueElement.id = props1.PropertyName + objSuffix;
                        return valueElement;
                    },
                    write: (args: {
                        element: Element;
                        values: string[] | string;
                        column: Column;
                    }) => {
                        let dataSource: { [key: string]: Object; }[] = [
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                        ];
                        let fields: object = { text: "label", value: "value" };
                        let dropDownObj = new DropDownList({
                            dataSource: dataSource,
                            fields: fields,
                            value: args.values
                                ? (args.values as string)
                                : isNotNullAndUndefined(filterVal)
                                    ? filterVal
                                    : null,
                            change: (e: any) => {
                                if (e.itemData)
                                    this.grid.filterByColumn(
                                        truncateAfter(args.element.id, Constants.idSuffix),
                                        "equal",
                                        e.itemData !== null ? e.itemData.value : null
                                    );
                                else
                                    this.grid.removeFilteredColsByField(
                                        truncateAfter(args.element.id, Constants.idSuffix),
                                        true
                                    );
                            },
                            placeholder: props1.DisplayName,
                            showClearButton: true,
                        });
                        dropDownObj.appendTo("#" + args.element.id);
                    },
                };
            }


            if (isNullOrUndefined(retVal)) {

                retVal = {
                    create: () => {
                        let valueElement = document.createElement("input");
                        valueElement.setAttribute("type", "text");
                        valueElement.id = props1.PropertyName + objSuffix;
                        return valueElement;
                    },
                    write: (args: {
                        element: Element;
                        values: string[] | string;
                        column: Column;
                    }) => {
                        let textBox = new TextBox({
                            type: 'text',
                            value: args.values
                                ? (args.values as string)
                                : isNotNullAndUndefined(filterVal)
                                    ? filterVal
                                    : null,
                            change: (e: any) => {
                                if (e.value) {
                                } else {
                                    this.grid.removeFilteredColsByField(
                                        truncateAfter(args.element.id, Constants.idSuffix),
                                        true
                                    );
                                }
                            },
                            placeholder: props1.DisplayName,
                            showClearButton: true,
                        });
                        textBox.appendTo("#" + args.element.id);
                    },
                };
            }
        }
        return retVal;
    };

    private filterMenuTemplate = (props: QueryAttributeJM): any => {
        let filterVal = null;
        let model: GridModel = null;
        if (isNotNullAndUndefined(this.props.gridId)) {
            model = GetGridModelFromGridState(this.props.gridId);
            if (isNotNullAndUndefined(model)) {
                const objfilterSettings: FilterSettingsModel = model.filterSettings;
                if (isNotNullAndUndefined(objfilterSettings) && isNotNullAndUndefined(objfilterSettings.columns)) {
                    const objFilterCol = objfilterSettings.columns.filter(
                        (x) => x.field === props.PropertyName
                    );
                    if (isNotNullAndUndefined(objFilterCol)) {
                        if (objFilterCol.length > 1) {
                            filterVal = [];
                            objFilterCol.forEach((itemVal) => {
                                filterVal.push(itemVal.value);
                            });
                        } else {
                            filterVal = objFilterCol[0] && objFilterCol[0].value;
                        }
                    }
                }
            }
        }
        let retVal: IFilterMUI;
        if (props && props.DataType === RAFDataType.Text) {
            retVal = {
                create: (args: { target: Element; column: object; }) => {
                    const flValInput: HTMLElement = createElement("input", {
                        id: props.PropertyName,
                        className: "flm-input",
                    });
                    args.target.appendChild(flValInput);
                    this.textboxObj = new TextBox({
                        placeholder: props.DisplayName,
                        type: "text",
                        showClearButton: true,
                    });
                    this.textboxObj.appendTo(flValInput);
                },
                read: (args: {
                    target: Element;
                    column: any;
                    operator: string;
                    fltrObj: Filter;
                }) => {
                    args.fltrObj.filterByColumn(
                        args.column.field,
                        args.operator,
                        this.textboxObj.value
                    );
                },
                write: (args: {
                    column: object;
                    target: Element;
                    parent: any;
                    filteredValue: number | string;
                }) => {
                    this.textboxObj.value = args.filteredValue
                        ? args.filteredValue.toString()
                        : null;
                },
            };
        }
        if (props && props.DataType === RAFDataType.Dropdown) {
            let dataSource: { [key: string]: Object; }[] = [];
            let fields: object = { text: "label", value: "value" };
            props.ValueJson &&
                props.ValueJson.forEach((opt: ValueJson) => {
                    dataSource.push({ label: opt.DisplayName, value: opt.DisplayName });
                });
            retVal = {
                create: (args: { target: Element; column: object; }) => {
                    const flValInput: HTMLElement = createElement("input", {
                        id: "prop_" + props.PropertyName,
                        className: "flm-input",
                    });
                    args.target.appendChild(flValInput);
                    this.dropDownObj = new DropDownList({
                        dataSource: dataSource,
                        fields: fields,
                        placeholder: "Select " + props.DisplayName,
                    });
                    this.dropDownObj.appendTo(flValInput);
                },
                read: (args: {
                    target: Element;
                    column: any;
                    operator: string;
                    fltrObj: Filter;
                }) => {
                    args.fltrObj.filterByColumn(
                        args.column.field,
                        args.operator,
                        this.dropDownObj.value as any
                    );
                },
                write: (args: {
                    column: object;
                    target: Element;
                    parent: any;
                    filteredValue: number | string;
                }) => {
                    this.dropDownObj.value = args.filteredValue;
                },
            };
        }
        if (props && props.DataType === RAFDataType.Boolean) {
            let dataSource: { [key: string]: Object; }[] = [
                { label: "Yes", value: true },
                { label: "No", value: false },
            ];
            let fields: object = { text: "label", value: "value" };
            retVal = {
                create: (args: { target: Element; column: object; }) => {
                    const flValInput: HTMLElement = createElement("input", {
                        id: props.PropertyName,
                        className: "flm-input",
                    });
                    args.target.appendChild(flValInput);
                    this.booleandropDownObj = new DropDownList({
                        dataSource: dataSource,
                        fields: fields,
                        placeholder: "Select " + props.DisplayName,
                    });
                    this.booleandropDownObj.appendTo(flValInput);
                },
                read: (args: {
                    target: Element;
                    column: any;
                    operator: string;
                    fltrObj: Filter;
                }) => {
                    args.fltrObj.filterByColumn(
                        args.column.field,
                        args.operator,
                        this.booleandropDownObj.value as any
                    );
                },
                write: (args: {
                    column: object;
                    target: Element;
                    parent: any;
                    filteredValue: number | string | boolean;
                }) => {
                    this.booleandropDownObj.value = args.filteredValue;
                },
            };
        }

        if (props && props.DataType === RAFDataType.Lookup) {
            let url;
            if (isNotNullAndUndefined(props.RelatedEntities)) {
                const firstItem = props.RelatedEntities;
                url = isNotNullAndUndefined(firstItem)
                    ? firstItem.replace(/_/g, "").replace(/\s/g, "") + "/Lookup"
                    : null;
            }
            retVal = {
                create: (args: { target: Element; column: object; }) => {
                    const flValInput: HTMLElement = createElement("input", {
                        id: props.PropertyName,
                        className: "flm-input",
                    });
                    args.target.appendChild(flValInput);
                    const lookupFields = { text: "Value", value: "Value" };
                    //let isFiltering: boolean = false;
                    let skip = 0;
                    let take: number = Constants.DropdownFetchCount;
                    let filteredSkip = 0;
                    let filteredTake: number = Constants.DropdownFetchCount;
                    const lookupData: RAFDataManager = new RAFDataManager({
                        adaptor: new RAFUrlAdaptor({
                            Skip: 0,
                            Take: Constants.DropdownFetchCount,
                        }),
                        crossDomain: true,
                        url: Constants.baseAPIUrl + url,
                        requestType: "POST",
                        //enableCaching: true,
                        headers: [],
                    });
                    const setFilteringDebounce = debounce((args1) => {
                        //isFiltering = true;
                        filteredSkip = 0;
                        filteredTake = Constants.DropdownFetchCount;
                        lookupData.adaptor = new RAFUrlAdaptor({
                            Skip: filteredSkip,
                            Take: filteredTake,
                        });
                        let query = new Query();
                        query =
                            args1.text !== ""
                                ? query.where(props.PropertyName, "contains", args1.text, true)
                                : query;
                        args1.updateData(lookupData, query);
                    }, 500);
                    const actionBegin = (args1) => {
                        if (args1.query === undefined) {
                            //temp fix to prevent repeated api calls
                            args1.cancel = true;
                        }
                    };
                    const actionComplete = (e) => {
                        e.result = R.uniq(e.result);
                    };
                    const opened = () => {
                        let listElement: HTMLElement = (this.lookupObject as any).list;
                        listElement.addEventListener("scroll", (args) => {
                            if (
                                listElement.scrollTop + listElement.offsetHeight + 1 >=
                                listElement.scrollHeight
                            ) {
                                setScrollDebounce(args);
                            }
                        });
                    };
                    const onCreated = () => {
                        if (this.lookupObject) {
                            const tooltip = new Tooltip({
                                beforeRender: (args1: TooltipEventArgs) => {
                                    if (isNotNullAndUndefined(tooltip)) {
                                        tooltip.content = args1.target.textContent;
                                    }
                                },
                                content: "Loading...",
                                position: "TopCenter",
                                target:
                                    isNotNullAndUndefined(this.lookupObject) &&
                                        isNotNullAndUndefined(this.lookupObject.element)
                                        ? `#${this.lookupObject.element.id}_popup .e-list-item`
                                        : ".e-list-item",
                            });
                            tooltip.appendTo("body");
                        }
                    };
                    const onClosed = () => {
                        let openTooltips = document.querySelectorAll(
                            "div.e-tooltip-wrap.e-popup-open"
                        );
                        if (isNotNullAndUndefined(openTooltips)) {
                            openTooltips.forEach((x) => {
                                x.classList.add("hidden");
                            });
                        }
                    };
                    const setScrollDebounce = debounce(() => {
                        let filterQuery = isNotNullAndUndefined(this.lookupObject.query)
                            ? this.lookupObject.query.clone()
                            : new Query();
                        const filteredText =
                            this.lookupObject["searchKeyModule"]["element"]["value"];
                        if (isNotNullAndUndefined(filteredText) && filteredText !== "") {
                            filteredSkip += filteredTake;
                            filterQuery = filterQuery.where(
                                props.PropertyName,
                                "contains",
                                filteredText,
                                true
                            );
                            lookupData.adaptor = new RAFUrlAdaptor({
                                Skip: filteredSkip,
                                Take: filteredTake,
                            });
                        } else {
                            skip += take;
                            lookupData.adaptor = new RAFUrlAdaptor({
                                Skip: skip,
                                Take: take,
                            });
                        }
                        lookupData
                            //.executeQuery(filterQuery.range(start, end))
                            .executeQuery(filterQuery)
                            .then((event) => {
                                //start = end;
                                //end += 5;
                                this.lookupObject.addItem(
                                    (event as any).result as { [key: string]: Object; }[]
                                );
                            })
                            .catch(() => { });
                    }, 500);
                    this.lookupObject = new DropDownList({
                        dataSource: lookupData,
                        fields: lookupFields,
                        allowFiltering: true,
                        filterType: "Contains",
                        placeholder: "Select " + props.DisplayName,
                        filtering: (e: FilteringEventArgs) => {
                            e.preventDefaultAction = true;
                            setFilteringDebounce(e);
                            //if (isNotNullAndUndefined(url)) {
                            //    let query = new Query();
                            //    // frame the query based on search string with filter type.
                            //    query = (e.text !== "") ? query.where(props.PropertyName, "contains", e.text, true) : query;
                            //    // pass the filter data source, filter query to updateData method.
                            //    //args.updateData(this.searchData, query);
                            //    e.updateData(lookupData, query);
                            //}
                        },
                        actionBegin: actionBegin,
                        actionComplete: actionComplete,
                        created: onCreated,
                        close: onClosed,
                        open: () => {
                            opened();
                        },
                    });
                    this.lookupObject.appendTo(flValInput);
                },
                read: (args: {
                    target: Element;
                    column: any;
                    operator: string;
                    fltrObj: Filter;
                }) => {
                    args.fltrObj.filterByColumn(
                        args.column.field,
                        args.operator,
                        this.lookupObject.value as any
                    );
                },
                write: (args: {
                    column: object;
                    target: Element;
                    parent: any;
                    filteredValue: number | string;
                }) => {
                    this.lookupObject.value = args.filteredValue;
                },
            };
        }
        if (props && props.DataType === RAFDataType.Date) {
            retVal = {
                create: (args: { target: Element; column: object; }) => {
                    let flValInput = createElement("input", {
                        id: props.PropertyName,
                        className: "flm-input",
                    });
                    args.target.appendChild(flValInput);
                    const fdate = [];
                    this.grid.filterSettings.columns.forEach((col) => {
                        if (col.field === props.PropertyName) fdate.push(col.value);
                    });
                    this.daterangepickerObj = new DateRangePicker({
                        presets: presetDateModel,
                        format: RAFDatePickerViewFormat.DATETIME,
                    });
                    if (fdate.length > 0) {
                        this.daterangepickerObj.startDate = fdate[0];
                        this.daterangepickerObj.endDate = fdate[1];
                    }
                    this.daterangepickerObj.appendTo(flValInput);
                },
                read: (args: {
                    target: Element;
                    column: any;
                    operator: string;
                    fltrObj: Filter;
                }) => {
                    args.fltrObj.filterByColumn(
                        args.column.field,
                        args.operator,
                        this.daterangepickerObj.value as Date[]
                    );
                },
                write: () => {
                    //daterangepickerObj.value = args.filteredValue ? args.filteredValue as Date[] : (isNotNullAndUndefined(filterVal) ? filterVal : null);
                },
            };
        }
        return retVal;
    };

    private defaultColumnTemplate = (data) => {
        let item: QueryAttributeJM =
            this.props.fields &&
            this.props.fields.find((x) => x.PropertyName === data.column.field);
        let isFirstColumn = false;
        if (!Browser.isDevice && this.allowSelection) {
            isFirstColumn = data.column.index === 1 ? true : false;
        } else {
            isFirstColumn = data.column.index === 0 ? true : false;
        }
        const fieldValue = data[data.column.field];
        //const gridTemplates: GridColumnTemplates[] = props.gridTemplates;

        if (isNotNullAndUndefined(this.gridTemplates)) {
            let templateName: GridColumnTemplates = this.gridTemplates.find(
                (x) => x.key.toLowerCase() === data.column.field.toLowerCase()
            );
            if (
                isNotNullAndUndefined(templateName) &&
                isNotNullAndUndefined(templateName.value)
            ) {
                if (
                    isNotNullAndUndefined(this.props.templateHelpers[templateName.value])
                ) {
                    //console.log('templateHelpers', templateHelpers);
                    //console.log('templateName.value', templateName.value);
                    return this.props.templateHelpers[templateName.value].call(
                        this,
                        this.props.moduleName,
                        this.allowEditing,
                        isFirstColumn,
                        item,
                        this.isDynamic,
                        data
                    );
                }
            }
        }
        if (isFirstColumn === true && !this.disableFirstColumnTemplate) {
            return this.props.templateHelpers["firstColumnTemplate"].call(
                this,
                this.props.moduleName,
                this.allowEditing,
                isFirstColumn,
                item,
                this.isDynamic,
                data
            );
        }

        if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Date) {
            return this.props.templateHelpers["dateTemplate"].call(
                this,
                this.props.moduleName,
                this.allowEditing,
                isFirstColumn,
                item,
                this.isDynamic,
                data
            );
        }

        if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.DateTime) {
            return this.props.templateHelpers["dateTimeTemplate"].call(
                this,
                this.props.moduleName,
                this.allowEditing,
                isFirstColumn,
                item,
                this.isDynamic,
                data
            );
        }

        if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Dropdown) {
            //return templateHelpers['dropdownTemplate'].call(this, moduleName, allowEditing, isFirstColumn, item, data)
            return this.props.templateHelpers["defaultDropdownTemplate"].call(
                this,
                this.props.moduleName,
                this.allowEditing,
                isFirstColumn,
                item,
                this.isDynamic,
                data
            );
        }

        if (isNotNullAndUndefined(item) && item.DataType === RAFDataType.Boolean) {
            return this.props.templateHelpers["isBooleanTemplate"].call(
                this,
                this.props.moduleName,
                this.allowEditing,
                isFirstColumn,
                item,
                this.isDynamic,
                data
            );
        }

        if (
            isNotNullAndUndefined(item) &&
            item.DataType === RAFDataType.Lookup &&
            item.IsMultiSelect === true
        ) {
            return this.props.templateHelpers["lookupMultiSelectTemplate"].call(
                this,
                this.props.moduleName,
                this.allowEditing,
                isFirstColumn,
                item,
                this.isDynamic,
                data
            );
        }

        return <div>{fieldValue}</div>;
    };

    //private customHeaderTemplate = (item: QueryAttributeJM, data): any => {
    private customHeaderTemplate = (data): any => {
        let item: QueryAttributeJM =
            this.props.fields &&
            this.props.fields.find((x) => x.PropertyName === data.field);
        let headerText = "";
        const fieldValue = data["headerText"];
        const gridColumnHeaders: GridColumnHeader[] = this.props.gridColumnHeader;
        let headerVal: GridColumnHeader;
        if (
            isNotNullAndUndefined(gridColumnHeaders) &&
            isNotNullAndUndefined(item)
        ) {
            headerVal = gridColumnHeaders.find((x) => x.field === item.PropertyName);
            if (
                isNotNullAndUndefined(headerVal) &&
                isNotNullAndUndefined(headerVal.value)
            ) {
                headerText = headerVal.value;
                return <span className="e-headertext">{headerText}</span>;
            }
        }
        return <span className="e-headertext">{fieldValue}</span>;
    };

    private customColumnWidthTemplate = (item: QueryAttributeJM) => {
        let widthVal = "";
        const gridColumnWidths: GridColumnWidth[] = this.props.gridColumnWidth;
        let widthValue: GridColumnWidth;
        if (
            isNotNullAndUndefined(gridColumnWidths) &&
            isNotNullAndUndefined(item)
        ) {
            widthValue = gridColumnWidths.find((x) => x.field === item.PropertyName);
            if (
                isNotNullAndUndefined(widthValue) &&
                isNotNullAndUndefined(widthValue.width)
            ) {
                widthVal = widthValue.width;
            }
        } else {
            widthVal = "150";
        }
        return widthVal;
    };

    private customMaxColumnWidthTemplate = (item: QueryAttributeJM) => {
        let widthVal = "";
        const gridColumnWidths: GridColumnWidth[] = this.props.gridColumnWidth;
        let widthValue: GridColumnWidth;
        if (
            isNotNullAndUndefined(gridColumnWidths) &&
            isNotNullAndUndefined(item)
        ) {
            widthValue = gridColumnWidths.find((x) => x.field === item.PropertyName);
            if (
                isNotNullAndUndefined(widthValue) &&
                isNotNullAndUndefined(widthValue.width)
            ) {
                widthVal = widthValue.width;
            }
        } else {
            widthVal = "150";
        }
        return widthVal;
    };

    componentDidUpdate(): //prevProps: Readonly<IProps & RAFDataListAdditionalProps>,
        // prevState: Readonly<IState>,
        //snapshot?: any
        void {
        let resetState = false;
        const stateData = { ...this.state.data };
        const currData = this.props.data && { ...this.props.data.data };
        if (isNotNullAndUndefined(stateData) && isNullOrUndefined(currData)) {
            resetState = true;
        } else if (
            isNullOrUndefined(stateData) &&
            isNotNullAndUndefined(currData)
        ) {
            resetState = true;
        } else if (
            isNotNullAndUndefined(stateData) &&
            isNotNullAndUndefined(currData)
        ) {
            //if (stateData.count !== currData.count) {
            if (!deepEqual(stateData.result, currData.result)) {
                resetState = true;
            }
        }
        if (resetState === true) {
            this.setState({ data: { ...currData } }, () => { });
        }
    }

    render() {
        let loading = isNotNullAndUndefined(this.state.loading)
            ? this.state.loading
            : false;

        return (
            <div className="h-100">
                <div className="h-100" id="divGridParent">
                    {/*{showToolbar && <ToolbarComponent id='toolbar-actions' cssClass="toolbarhidden" clicked={toolbarActionClickHandler.bind(this)}>
                </ToolbarComponent>
                }*/}
                    <div id="customSpinner"></div>
                    {/* <TooltipComponent
                        ref={(t) => (this.tooltip = t)}
                        target=".e-rowcell"
                        beforeRender={this.beforeRender.bind(this)}
                        className="h-100"
                    >
                    </TooltipComponent> */}
                    {loading === true ? (
                        <div></div>
                    ) : (
                        this.renderGrid(this.props.fields)
                    )}
                </div>
            </div>
        );
    }
}

export default TaskCardView2;
