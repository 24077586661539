
export class LogInAsRequestRow {
    // Employee?: string;
    // Tenant?: string;
    // Portal?: string;
    EmployeeUID?: string;
    TenantUID?: string;
    PortalUID?: string;
}

export class UserDefinition {
    Id?: string;
    DisplayName?: string;
    Email?: string;
    IsActive?: boolean;
    UserUID?: string;
    Username?: string;
    UserGroupUID?: string;
    BusinessUnitUID?: string;
    BusinessUnitID?: string;
    BusinessUnit?: string;
    TenantUID?: string;
    TenantID?: string;
    Impersonated?: string;
}