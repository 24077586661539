import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Component, Fragment, PropsWithChildren } from "react";
import { Field } from "react-final-form";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import {
  ConditionNotNullOrWhiteSpace,
  ConditionNullOrWhiteSpace,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFGroupDropdown from "../../../../RAFComponents/Inputs/RAFGroupDropdown";
import RAFLookupCC from "../../../../RAFComponents/Inputs/RAFLookupCC";
import RAFTextBox from "../../../../RAFComponents/Inputs/RAFTextBox";
import {
  RAFFormContext,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { getFields } from "../../../../RAFComponents/helpers/AppHelper";
import {
  getDisplayNameByModuleName,
} from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import RAFBPDetailInlineEdit1 from "../../../../RAFMaster/RMModules/BusinessProcess/helpers/RAFBPDetailInlineEdit1";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { getModuleAvatarClass, getModuleAvatarIcon } from "../../../../helpers/Common/AvatarHelper";
import { getURLPrefixByModuleName } from "../../../../helpers/Common/URLPrefixByModuleName";

//const RAFTextBox = ({ field, label, required }) => {

interface IProps {
  field?: string;
  relatedField?: string;
  moduleName?: string;
  enableFloatingPopup?: boolean;
}

class RelatedToModuleList {
  label?: string;
  value?: string;
  category?: string;
}

interface IState {
  isActive: boolean;
  dropdownKey?: number;
  lookupUrl?: string;
  lookupModule?: string;
  relatedModuleList?: RelatedToModuleList[];
}

class RAFRelatedLookupSelector extends Component<
  PropsWithChildren<IProps>,
  IState
> {
  _isMounted = false;
  context: any;

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      lookupUrl: null,
      lookupModule: null,
      relatedModuleList: [],
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    setTimeout(() => {
      let relatedModuleList = [];
      getFields(this.props.moduleName).then((fieldList) => {
        //getAllForms().then(formList => {
        let relatedField =
          isNotEmptyArray(fieldList) &&
            isNotNullAndUndefined(this.props.field)
            ? fieldList.find((x) => x.PropertyName === this.props.field)
            : null;
        let relatedFieldValue = isNotNullAndUndefined(relatedField)
          ? relatedField.RelatedEntities
          : null;
        let relatedEntities =
          relatedFieldValue && relatedFieldValue.split(",");
        relatedEntities &&
          relatedEntities.forEach((item) => {
            if (item !== RAFEntityName.Task)
              relatedModuleList.push({
                label: getDisplayNameByModuleName(item),
                value: item,
                category: "System Form",
              });
          });
        // formList && formList.forEach(item => {
        //     relatedModuleList.push({ label: ConvertToPascal(item.Entity), value: item.Entity, category: 'User Form' });
        // });
        if (
          isNotNullAndUndefined(this.context) &&
          isNotNullAndUndefined(this.context.values)
        ) {
          let relatedToType = this.context.values[this.props.relatedField];
          let selectedItem =
            relatedModuleList &&
            relatedModuleList.find((x) => x.value === relatedToType);
          if (selectedItem && selectedItem.category === "System Form") {
            if (this._isMounted) {
              this.setState({
                isActive: true,
                relatedModuleList,
                lookupModule: relatedToType,
                lookupUrl: relatedToType === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : getURLPrefixByModuleName(relatedToType) + "/Lookup",
              });
            }
          } else {
            if (this._isMounted) {
              this.setState({
                isActive: true,
                relatedModuleList,
                lookupModule: relatedToType,
                lookupUrl: relatedToType === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : "DataList/Lookup",
              });
            }
          }
        } else {
          if (this._isMounted) {
            this.setState({ isActive: true, relatedModuleList });
          }
        }
        //});
      });

    }, 500);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onDropdownChanged = (label, value) => {
    let { relatedModuleList } = this.state;
    let selectedItem =
      relatedModuleList && relatedModuleList.find((x) => x.value === value);
    setFormValue(this.context, "RelatedTo", null);
    setFormValue(this.context, "RelatedToUID", null);

    if (selectedItem && selectedItem.category === "System Form") {
      if (this._isMounted) {
        this.setState({
          lookupModule: value,
          lookupUrl: value === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : getURLPrefixByModuleName(value) + "/Lookup",
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({
          lookupModule: value, lookupUrl: value === CareEsioEntity.care_recipient.EntityName ? "CareRecipient/LookUp" : "DataList/Lookup",
        });
      }
    }

  };
  relatedToCustomContent = () => {
    const { field, relatedField } = this.props;
    const { lookupModule, lookupUrl, relatedModuleList } = this.state;
    return (
      <div className="p-3">
        <div className="row gy-3 gx-0">
          <div className="col-12">
            <RAFGroupDropdown
              field={relatedField}
              onChanged={(label, value) => {
                this.onDropdownChanged(label, value);
              }}
              showLabel={false}
              showClearButton
              allowFiltering
            >
              {relatedModuleList &&
                relatedModuleList.map((item) => {
                  return (
                    <RAFChoiceOption
                      key={item.value}
                      label={item.label}
                      value={item.value}
                      category={item.category}
                    />
                  );
                })}
            </RAFGroupDropdown>
          </div>
          <div className="col-12">
            <ConditionNotNullOrWhiteSpace when={relatedField}>
              <div key={lookupModule}>
                <RAFAttributeRelatedListProvider moduleName={lookupModule}>
                  <RAFAttributesContext.Consumer>
                    {({ queryAttributes }) => {
                      let defaultField =
                        queryAttributes &&
                        queryAttributes.find((x) => x.IsDefault === true);
                      let defaultSelectedField =
                        defaultField && defaultField.PropertyName;
                      return (
                        <>
                          <RAFLookupCC
                            field={field.toString()}
                            showLabel={false}
                            url={lookupUrl}
                            placeholder={
                              "Select " +
                              getDisplayNameByModuleName(lookupModule)
                            }
                            moduleName={lookupModule}
                            selectedField={defaultSelectedField}
                            SearchCreateOptionMode={"Footer"}
                            required
                          ></RAFLookupCC>
                        </>
                      );
                    }}
                  </RAFAttributesContext.Consumer>
                </RAFAttributeRelatedListProvider>
              </div>
            </ConditionNotNullOrWhiteSpace>
            <ConditionNullOrWhiteSpace when={relatedField}>
              <div>
                <RAFTextBox
                  field={field.toString()}
                  showLabel={false}
                  placeholder={"Select"}
                  disabled
                />
              </div>
            </ConditionNullOrWhiteSpace>
          </div>
        </div>
      </div>
    );
  };
  RelatedToIcon(moduleName) {
    let moduleIconClass = getModuleAvatarIcon(moduleName);
    let moduleAvatarClass = getModuleAvatarClass(moduleName);
    return (
      <span
        className={moduleAvatarClass + " avatar-text-square"}
        style={{
          height: "28px",
          width: "28px",
          minHeight: "28px",
          minWidth: "28px",
          fontSize: "0.875rem",
          marginRight: "0.3125rem",
        }}
      >
        <i className={"fa fa-" + moduleIconClass}></i>
      </span>
    );
  }
  customValue = () => {
    return (
      <div className="w-100 rafrelatedTo overflow-hidden">
        <div className="row g-1 flex-nowrap">
          <div className="col-auto">
            <Field
              name="RelatedToType"
              allowNull
              parse={(value) => (value === "" ? null : value)}
            >
              {({ input }) => {
                let inputValue =
                  isNotNullAndUndefined(input) &&
                    !IsNullOrWhiteSpace(input.value)
                    ? input.value
                    : "Not set";
                return this.RelatedToIcon(inputValue);
              }}
            </Field>
          </div>
          <div className="col overflow-auto">
            <div className="row g-0">
              <div className="col-12">
                <div className="rafrelatedTo__Content d-flex">
                  <div className="rafrelatedTo__Content__secondary text-uppercase">
                    <small>
                      <Field
                        name="RelatedToType"
                        allowNull
                        parse={(value) => (value === "" ? null : value)}
                      >
                        {({ input }) => {
                          let inputValue =
                            isNotNullAndUndefined(input) &&
                              !IsNullOrWhiteSpace(input.value)
                              ? getDisplayNameByModuleName(input.value)
                              : "Not set";
                          return inputValue;
                        }}
                      </Field>
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="swapTextwithHyperlink rafrelatedTo__Content d-flex">
                  <span
                    className={
                      "rafrelatedTo__Content__primary ecllipseFirstLine"
                    }
                  >
                    <Field
                      name="RelatedTo"
                      allowNull
                      parse={(value) => (value === "" ? null : value)}
                    >
                      {({ input }) => {
                        let inputValue =
                          isNotNullAndUndefined(input) &&
                            !IsNullOrWhiteSpace(input.value)
                            ? input.value
                            : "Not set";
                        return inputValue;
                      }}
                    </Field>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  closeFloatingPopupContent = (field) => {
    let floatingPopupContent = document.getElementById(
      `floatingCustomPopup${field}`
    );
    let customInputDiv = document.getElementById(
      `customInputDiv_${field.toString()}`
    );
    if (isNotNullAndUndefined(floatingPopupContent)) {
      floatingPopupContent.classList.remove("active");
    }
    if (isNotNullAndUndefined(customInputDiv)) {
      customInputDiv.classList.remove("remove");
    }
  };
  customButton = () => {
    const { field, relatedField } = this.props;
    const { lookupModule, lookupUrl } = this.state;
    return (
      <>
        <ConditionNotNullOrWhiteSpace when={relatedField}>
          <ConditionNotNullOrWhiteSpace when={field}>
            <div key={lookupModule}>
              <ButtonComponent
                type="button"
                id={`btn_close_floatingCustomPopup_${RAFButtonConstant.Ok.Id}_${field}`}
                isPrimary
                content={RAFButtonConstant.Ok.DisplayName}
                iconCss={RAFButtonConstant.Ok.IconCss}
                onClick={() => this.closeFloatingPopupContent(field)}
              ></ButtonComponent>
            </div>
          </ConditionNotNullOrWhiteSpace>
          <ConditionNullOrWhiteSpace when={field}>
            <ButtonComponent
              type="button"
              id={`btn_close_floatingCustomPopup_${RAFButtonConstant.Ok.Id}_${field}`}
              isPrimary
              disabled
              content={RAFButtonConstant.Ok.DisplayName}
              iconCss={RAFButtonConstant.Ok.IconCss}
              onClick={() => this.closeFloatingPopupContent(field)}
            ></ButtonComponent>
          </ConditionNullOrWhiteSpace>
        </ConditionNotNullOrWhiteSpace>
        <ConditionNullOrWhiteSpace when={relatedField}>
          <div>
            <ButtonComponent
              type="button"
              id={`btn_close_floatingCustomPopup_${RAFButtonConstant.Ok.Id}_${field}`}
              isPrimary
              content={RAFButtonConstant.Ok.DisplayName}
              iconCss={RAFButtonConstant.Ok.IconCss}
              onClick={() => this.closeFloatingPopupContent(field)}
            ></ButtonComponent>
          </div>
        </ConditionNullOrWhiteSpace>
      </>
    );
  };

  render() {
    const { field, relatedField } = this.props;
    const { lookupModule, lookupUrl, relatedModuleList, isActive } = this.state;
    return (
      <Fragment>

        {isActive === true && (
          this.props.enableFloatingPopup === false ?
            <div className="row g-0 gy-2 align-items-center">
              {relatedModuleList && relatedModuleList.length > 0 && (
                <div className="col-md-12">
                  <RAFGroupDropdown field={relatedField}
                    label="Select Type"
                    formGroupClassName="m-md-0"
                    enableGroupByFormat
                    onChanged={(label, value) => { this.onDropdownChanged(label, value); }}
                    showLabel={true} showClearButton allowFiltering>
                    {relatedModuleList && relatedModuleList.map(item => {
                      return <RAFChoiceOption key={item.value} label={item.label} value={item.value} category={item.category} />;
                    })}
                  </RAFGroupDropdown>
                </div>
              )}
              <div className="col-12">
                <ConditionNotNullOrWhiteSpace when={relatedField}>
                  <div key={lookupModule}>
                    <RAFAttributeRelatedListProvider moduleName={lookupModule}>
                      <RAFAttributesContext.Consumer>
                        {({ queryAttributes }) => {
                          let defaultField = queryAttributes && queryAttributes.find(x => x.IsDefault === true);
                          let defaultSelectedField = defaultField && defaultField.PropertyName;
                          return <>
                            <RAFLookupCC field={field.toString()} showLabel={true} label={"Select " + getDisplayNameByModuleName(lookupModule)} url={lookupUrl} placeholder={"Select " + getDisplayNameByModuleName(lookupModule)} moduleName={lookupModule}
                              selectedField={defaultSelectedField} SearchCreateOptionMode={"Footer"} required
                            ></RAFLookupCC>
                          </>;
                        }}
                      </RAFAttributesContext.Consumer>
                    </RAFAttributeRelatedListProvider>
                  </div>
                </ConditionNotNullOrWhiteSpace>
                <ConditionNullOrWhiteSpace when={relatedField}>
                  <div>
                    <RAFTextBox field={field.toString()} showLabel={true} label="Select Record" placeholder={"Select Record"} disabled />
                  </div>
                </ConditionNullOrWhiteSpace>
              </div>
            </div>
            :
            <RAFBPDetailInlineEdit1
              label="Related to"
              fieldName={field}
              tooltipContent={this.relatedToCustomContent()}
              labelClassName="col-3"
              inputClassName="col-9"
              UiMode="floatingCustomDiv"
              customValue={this.customValue()}
              customButton={this.customButton()}
            />
        )}
        {/* {isActive === true && (
                    <div className="row g-0 g-md-3 align-items-center d-none">
                        <div className="col-md-4 d-flex">
                            <label className="form-label" >Link this task to</label>
                        </div>
                        <div className="col-md-8">
                            <RAFGroupDropdown field={relatedField}
                                formGroupClassName="m-md-0"
                                onChanged={(label, value) => { this.onDropdownChanged(label, value); }}
                                showLabel={false} showClearButton allowFiltering>
                                {relatedModuleList && relatedModuleList.map(item => {
                                    return <RAFChoiceOption key={item.value} label={item.label} value={item.value} category={item.category} />;
                                })}
                            </RAFGroupDropdown>
                        </div>
                        <div className="col-12">
                            <ConditionNotNullOrWhiteSpace when={relatedField}>
                                <div key={lookupModule}>
                                    <RAFAttributeRelatedListProvider moduleName={lookupModule}>
                                        <RAFAttributesContext.Consumer>
                                            {({ queryAttributes }) => {
                                                let defaultField = queryAttributes && queryAttributes.find(x => x.IsDefault === true);
                                                let defaultSelectedField = defaultField && defaultField.PropertyName;
                                                return <>
                                                    <RAFLookupCC field={field.toString()} showLabel={false} url={lookupUrl} placeholder={"Select " + getDisplayNameByModuleName(lookupModule)} moduleName={lookupModule}
                                                        selectedField={defaultSelectedField} SearchCreateOptionMode={"Footer"} required
                                                    ></RAFLookupCC>
                                                </>;
                                            }}
                                        </RAFAttributesContext.Consumer>
                                    </RAFAttributeRelatedListProvider>
                                </div>
                            </ConditionNotNullOrWhiteSpace>
                            <ConditionNullOrWhiteSpace when={relatedField}>
                                <div>
                                    <RAFTextBox field={field.toString()} showLabel={false} placeholder={"Select"} disabled />
                                </div>
                            </ConditionNullOrWhiteSpace>
                        </div>
                    </div>
                )} */}
      </Fragment>
    );
  }
}

RAFRelatedLookupSelector.contextType = RAFFormContext;
export default RAFRelatedLookupSelector;
