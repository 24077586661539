import React, { PropsWithChildren, Reducer, useCallback, useReducer } from 'react';
import RAFProfilePhoto from '../../../RAFComponents/Inputs/RAFProfilePhoto';

interface IProps {
    uid: string,
    relatedToType: string,
    profileImgCss?: 'profileImg-container-xxsm' | 'profileImg-container-xsm' | 'profileImg-container-xmd' | 'profileImg-container-sm' | 'profileImg-container-normal-sm' | 'profileImg-container-normal' | 'profileImg-container-md' | 'profileImg-container-xxl' | 'profileImg-container-xxxl';
    canEdit: boolean;
    isAnonymous?: boolean;
    onChange?: () => void;
    title?: string;
    progressDivID?: string;
    //    width?: string;
    //    minWidth?: string;
    //    height?: string;
    //    minHeight?: string;
    emptyAvatarType?: 'Initial' | 'Icon';
    iconSize?: string;
    fontSize?: string;
    emptyAvatar?: React.ReactNode;
    backgroundColor?: string;
    color?: string;
    border?: string;
    emptyIconCss?: string;
}

interface IState {
    userProfilePhotoKey: number;
}

function UserProfilePhoto({ ...props }: PropsWithChildren<IProps>) {

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            userProfilePhotoKey: Math.random()
        }
    );

    const onChanageProfilePhoto = useCallback(() => {
        if (props.onChange) {
            props.onChange();
        } else {
            setState({ userProfilePhotoKey: Math.random() });
        }
    }, []);

    return (
        <RAFProfilePhoto
            key={state.userProfilePhotoKey}
            uid={props.uid}
            relatedToType={props.relatedToType}
            getURL={'ContentLibrary/DownloadProfilePicture/'}
            //uploadURL={'User/SaveProfilePicture/'}
            uploadURL={'ContentLibrary/UploadProfilePicture'}
            //removeURL={'User/RemoveProfilePicture'}
            removeURL={'ContentLibrary/RemoveProfilePicture'}
            additionalParamsGet={[
                { key: 'RelatedUID', value: props.uid },
                { key: 'Entity', value: props.relatedToType },
            ]}
            additionalParamsSave={[
                { key: 'RelatedToUID', value: props.uid },
                { key: 'RelatedToType', value: props.relatedToType },
            ]}
            canEdit={props.canEdit} isAnonymous={props.isAnonymous}
            onChange={onChanageProfilePhoto}
            profileImgCss={props.profileImgCss}
            title={props.title}
            progressDivID={props.progressDivID}
            emptyAvatar={props.emptyAvatar}
            emptyAvatarType={props.emptyAvatarType}
            iconSize={props.iconSize}
            fontSize={props.fontSize}
            color={props.color}
            backgroundColor={props.backgroundColor}
            border={props.border}
            emptyIconCss={props.emptyIconCss}
        // width={props.width}
        // minWidth={props.minWidth}
        // height={props.height}
        // minHeight={props.minHeight}
        />
    );
}
export default React.memo(UserProfilePhoto);