import { DialogUtility } from "@syncfusion/ej2-popups";
import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";

import { msalInstance } from "../../..";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFEmailTextBox from "../../../RAFComponents/Inputs/RAFEmailTextBox";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { DeleteRecord, RetrieveRecord, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { IDialogProps, IsNullOrWhiteSpace, isNotNullAndUndefined, isNullOrUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACRadioButtonList from "../../../components/shared/ACFormFields/ACRadioButtonList";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { RAFButtonConstant, RAFLayout } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { saveEmployeeAPI } from "../../CareESIO/Employee/EmployeeHelper/EmployeeHelper";
import { EmployeeRow } from "../../CareESIO/Employee/EmployeeRow";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { ManageUserDlgMode, ResendUserInvite, checkLoginExistsByRelatedToUID, getPermissionGroupLookUpCustomFilter, getUserByUsername, saveAPIAddLoginEmployeeOrContact, saveAPICreateUserLinkEmployeeOrContact, saveUserAPI } from "./UserHelper";
import { UserRow } from "./UserRow";

interface IProps {
    initialValues?: UserRow;
    onDelete?: () => void;
    objectUID?: string;
    userDlgMode?: ManageUserDlgMode;
}

interface IState {
    isLoading: boolean;
    noContent: boolean;
    userRow: UserRow;
    userDlgMode: ManageUserDlgMode;
    selectedPageIndex: 'createOption' | 'formContent';
}


function ManageUser({ ...props }: PropsWithChildren<IProps & IDialogProps>) {

    let rafForm: FormRenderProps | null;

    const moduleName = RAFEntityName.User;
    const isUserActions = (IsNullOrWhiteSpace(props.userDlgMode) || props.userDlgMode === ManageUserDlgMode.Create || props.userDlgMode === ManageUserDlgMode.Update) ? true : false;

    const outerDivId = `manageupdate_employee_dialog`;
    let deleteDialog: any;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            noContent: true,
            userRow: null,
            userDlgMode: null,
            selectedPageIndex: null
        }
    );

    useEffect(() => {
        refresh();
    }, []);


    const refresh = async () => {
        if (props.isActive) {
            setState({ isLoading: true });
            const [userRow] = await Promise.all([
                getIntitailUserRow(),
            ]);

            if (isNotNullAndUndefined(userRow)) {
                setState({ isLoading: false, noContent: false, userRow, userDlgMode: userRow['userDlgMode'] });
            } else {
                setState({ isLoading: false, noContent: true });
            }
        }
    };

    const getIntitailUserRow = () => {
        return new Promise<UserRow>(async (resolve) => {
            if (isNotNullAndUndefined(props.objectUID)) {
                const userRow = await RetrieveRecord(props.objectUID, moduleName);
                userRow['userDlgMode'] = props.userDlgMode ?? 'update';
                if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(userRow.UID)) {
                    resolve(userRow);
                } else {
                    resolve(null);
                }
            } else {
                let initialObject = isNotNullAndUndefined(props.initialValues) ? props.initialValues : new UserRow();
                initialObject['userDlgMode'] = props.userDlgMode ?? 'create';

                resolve(initialObject);
            }
        });
    };

    const onSubmitUser = async (value: UserRow) => {
        let progressDiv = showProgress(`#${outerDivId}`);
        const userRow = { ...value } as UserRow;
        const userDlgMode: ManageUserDlgMode = userRow['userDlgMode'];
        if (isNotNullAndUndefined(userRow) && isNotNullAndUndefined(userRow['userDlgMode'])) {
            delete userRow['userDlgMode'];
        }
        if (userDlgMode === ManageUserDlgMode.CreateUserLinkEmployee ||
            userDlgMode === ManageUserDlgMode.CreateUserLinkContact
        ) {
            const existingUser = await checkLoginExistsByRelatedToUID(userRow.RelatedToUID, userRow.RelatedToType);
            if (isNotNullAndUndefined(existingUser)) {
                showWarningToast(`Sorry, the user you've selected already has a login. Please choose another one.`);
                hideProgress(progressDiv);
                return;
            }
        }

        const existUserRow = await getUserByUsername(value.UserName);
        if (isNotNullAndUndefined(existUserRow) &&
            isNotNullAndUndefined(existUserRow.UID) &&
            isNotNullAndUndefined(existUserRow.UserName) &&
            existUserRow.UID !== userRow.UID
        ) {
            if ((
                userDlgMode === ManageUserDlgMode.CreateUserLinkEmployee ||
                userDlgMode === ManageUserDlgMode.CreateUserLinkContact
            ) && isNullOrUndefined(existUserRow.RelatedToUID)
            ) {
                const userFormRow = { ...existUserRow } as UserRow;
                userFormRow.RelatedTo = userRow.RelatedTo;
                userFormRow.RelatedToUID = userRow.RelatedToUID;
                userFormRow.RelatedToType = userRow.RelatedToType;
                userFormRow.PermissionGroup = userRow.PermissionGroup;
                userFormRow.PermissionGroupUID = userRow.PermissionGroupUID;
                const response = await saveAPIAddLoginEmployeeOrContact(userFormRow,
                    userFormRow.RelatedToType === RAFEntityName.Employee ? ManageUserDlgMode.CreateUserLinkEmployee : ManageUserDlgMode.CreateUserLinkContact);
                hideProgress(progressDiv);
                if (isNotNullAndUndefined(response)) {
                    if (isNotNullAndUndefined(props.onSave)) {
                        props.onSave(response.entityId);
                    }
                }
                //update user with related to
            } else {
                showWarningToast("Sorry, the username you've entered is already in use. Please choose another one.");
                hideProgress(progressDiv);
            }
        } else {
            if (
                userDlgMode === ManageUserDlgMode.CreateUserEmployee ||
                userDlgMode === ManageUserDlgMode.CreateUserContact
            ) {
                const employeeFirstName = userRow.FirstName;
                const employeeLastName = userRow.LastName;
                const employee = new EmployeeRow;
                employee.FirstName = employeeFirstName;
                employee.LastName = employeeLastName;
                const createEmployeeResponse = await saveEmployeeAPI(employee);
                if (isNotNullAndUndefined(createEmployeeResponse) && isNotNullAndUndefined(createEmployeeResponse.entityId)) {
                    userRow.RelatedTo = createEmployeeResponse.objectName;
                    userRow.RelatedToUID = createEmployeeResponse.entityId;
                    const response = await saveUserAPI(userRow);
                    hideProgress(progressDiv);
                    if (isNotNullAndUndefined(response)) {
                        if (isNotNullAndUndefined(props.onSave)) {
                            props.onSave(response.entityId);
                        }
                    }
                } else {
                    hideProgress(progressDiv);
                    showWarningToast("Sorry something went wrong !");
                    return;
                }
            } else if (
                userDlgMode === ManageUserDlgMode.CreateUserLinkEmployee ||
                userDlgMode === ManageUserDlgMode.CreateUserLinkContact
            ) {
                const response = await saveAPICreateUserLinkEmployeeOrContact(userRow, userDlgMode);
                hideProgress(progressDiv);
                if (isNotNullAndUndefined(response)) {
                    if (isNotNullAndUndefined(props.onSave)) {
                        props.onSave(response.entityId);
                    }
                }
            } else {
                const response = await saveUserAPI(userRow);
                hideProgress(progressDiv);
                if (isNotNullAndUndefined(response)) {
                    if (isNotNullAndUndefined(props.onSave)) {
                        props.onSave(response.entityId);
                    }
                }
            }
        }
    };

    //delete item start
    const onClickDelete = () => {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: deleteRecord.bind(this) },
            title: `Delete User`,
            position: { X: "center", Y: "center" },
            cssClass: `alert-dialog deleteDialog`,
        });
    };

    async function deleteRecord() {
        let progressDiv = showProgress(".deleteDialog.e-dialog");

        let isDeleted = await DeleteRecord(
            props.objectUID,
            moduleName
        );

        if (isDeleted) {
            hideProgress(progressDiv);
            deleteDialog.hide();
            if (isNotNullAndUndefined(props.onDelete)) {
                props.onDelete();
            } else if (isNotNullAndUndefined(props.onSave)) {
                props.onSave();
            }
        } else {
            hideProgress(progressDiv);
            deleteDialog.hide();
            showWarningToast("Sorry something went wrong !");
        }
    }
    //delete item end


    const getCreateDropdownOptions = () => {
        const createDropdownOption: {
            label: string;
            value: string;
            subTitle: string;
            description: string;
        }[] = [];

        //if (IsSuperAdminQueryString()) {
        createDropdownOption.push(
            {
                label: 'Add User Only',
                value: ManageUserDlgMode.Create,
                subTitle: 'Add User Only.',
                description: 'Create a new user without linking to an existing employee profile.'
            },
        );
        // }

        createDropdownOption.push(
            {
                label: 'Add User & Create Employee Profile',
                value: ManageUserDlgMode.CreateUserEmployee,
                subTitle: 'Add User & Create Employee Profile.',
                description: 'Create a new user and simultaneously generate a new employee profile.',
            },
            {
                label: 'Link to Existing Employee Profile',
                value: ManageUserDlgMode.CreateUserLinkEmployee,
                subTitle: 'Link to Existing Employee Profile.',
                description: 'Associate the new user with an existing employee profile in the system.'
            }
        );

        return createDropdownOption;
    };

    const onChangeUserDlgMode = (value: string) => {
        if (state.userDlgMode !== value as any) {
            setFormValue(rafForm, propertyOf<UserRow>('RelatedTo'), null);
            setFormValue(rafForm, propertyOf<UserRow>('RelatedToUID'), null);
            setFormValue(rafForm, propertyOf<UserRow>('RelatedToType'), null);
            if (value === ManageUserDlgMode.CreateUserEmployee || value === ManageUserDlgMode.CreateUserLinkEmployee) {
                setFormValue(rafForm, propertyOf<UserRow>('RelatedToType'), RAFEntityName.Employee);
            }

            setState({ userDlgMode: value as any, selectedPageIndex: 'formContent' });
        } else {
            setState({ selectedPageIndex: 'formContent' });
        }
    };

    const getUserDlgContentMode = () => {
        const createDropdownOption = getCreateDropdownOptions();

        return (
            <div className="p-2">
                <ACRadioButtonList
                    field="userDlgMode"
                    label="Create Option"
                    placeholder="Select"
                    showClearButton={false}
                    onChanged={onChangeUserDlgMode}
                    uitype={'customButton'}
                    showLabel={false}
                    required
                    radioBtnClassName="custom-radio-btn-template"
                    labelClassName="align-items-start"
                //enableFullwidth
                >
                    {createDropdownOption.map((item) => {
                        return (
                            <RAFChoiceOption label={item.label} value={item.value} key={item.value}
                                labelTemplate={<div className="row gy-2 gx-0">
                                    <div className="col-12"><span className="header-text remove-color mb-1">{item.label}</span></div>
                                    <div className="col-12">
                                        <span className="header-text-sm-light remove-color text-wrap">
                                            {item.description}
                                        </span>
                                    </div>
                                </div>}
                            />
                        );
                    })}
                </ACRadioButtonList>
            </div>
        );
    };

    const employeeLookUpDiv = () => {
        return (
            <ACLookup
                field={propertyOf<UserRow>('RelatedTo')}
                label="Employee"
                placeholder="Select Employee"
                showFullList={false}
                url={'Employee/Lookup'}
                moduleName={RAFEntityName.Employee}
                showClearButton={false}
                required
            />
        );
    };


    const inviteUser = async (userRowUID: string) => {
        const resendUserInvite = await ResendUserInvite(userRowUID);
    };

    const getFooterContent = () => {
        if (props.userDlgMode === ManageUserDlgMode.Details) {
            const { userRow } = state;
            const isLoggedInUser = msalInstance.currentUserId === userRow.UID ? true : false;
            return (
                <div className="w-100">
                    <div className="row gx-2">
                        <div className="col-auto ms-auto">
                            <RAFPermissionRender permissionName={PermissionConstants.UserDelete}>
                                <RAFButtonComponent
                                    type="button"
                                    onClick={() => onClickDelete()}
                                    btnContent="Remove Login"
                                    idString="RemoveLogin"
                                    disabled={isLoggedInUser}
                                    className={isLoggedInUser ? '' : 'btn_brand_primary outline'}
                                />
                            </RAFPermissionRender>
                        </div>
                        <div className="col-auto">
                            <RAFButtonComponent
                                type="button"
                                className={isLoggedInUser ? '' : 'btn_brand_primary semi_dark'}
                                onClick={() => inviteUser(userRow.UID)}
                                btnContent='Invite User'
                                disabled={isLoggedInUser}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="w-100">
                    <div className="row gx-2">
                        {isNotNullAndUndefined(props.objectUID) && (
                            <div className="col-auto">
                                <RAFPermissionRender permissionName={PermissionConstants.UserDelete}>
                                    <RAFButtonComponent
                                        action={RAFButtonConstant.Delete}
                                        onClick={() => onClickDelete()}
                                        idString="DeleteContent"
                                        className="e-danger e-outline"
                                    />
                                </RAFPermissionRender>
                            </div>
                        )}
                        <div className="col-auto ms-auto">
                            <RAFPermissionRender permissionName={isNotNullAndUndefined(props.objectUID) ? PermissionConstants.UserUpdate : PermissionConstants.UserCreate}>
                                <RAFButtonComponent
                                    type="button"
                                    isPrimary
                                    action={RAFButtonConstant.Save}
                                    onClick={() => rafForm && rafForm.form.submit()}
                                    idString="CreateContent"
                                    disabled={rafForm && rafForm.submitting}
                                />
                            </RAFPermissionRender>
                        </div>
                        <div className="col-auto">
                            <RAFButtonComponent
                                type="button"
                                action={RAFButtonConstant.Cancel}
                                onClick={props.onClose}
                                idString="CreateContent"
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };

    const getInputFormContent = () => {
        if (props.userDlgMode === ManageUserDlgMode.Details) {
            const { userRow } = state;
            return (
                <div className="e-dlg-body-content">
                    <div className="row gx-2 gy-4">
                        <RAFDetailFieldCustom
                            value={userRow.FirstName}
                            title={"Firstname"}
                            colClassName="col-md-6"
                            rowClassName="gy-0"
                        />
                        <RAFDetailFieldCustom
                            value={userRow.LastName}
                            title={"LastName"}
                            colClassName="col-md-6"
                            rowClassName="gy-0"
                        />
                        <RAFDetailFieldCustom
                            value={userRow.UserName}
                            title={"UserName"}
                            colClassName="col-md-6"
                            rowClassName="gy-0"
                        />
                        <RAFDetailFieldCustom
                            value={userRow.PermissionGroup}
                            title={isUserActions ? 'Role Name' : "Permission Group"}
                            colClassName="col-md-6"
                            rowClassName="gy-0"
                        />
                    </div>
                </div>
            );
        } else {
            const { userDlgMode } = state;
            return (
                <div className="e-dlg-body-content">
                    <div className="row gx-2 gy-4">
                        {/* {(userDlgMode === ManageUserDlgMode.Create ||
                    userDlgMode === ManageUserDlgMode.CreateUserEmployee ||
                    userDlgMode === ManageUserDlgMode.CreateUserLinkEmployee) && (
                        getUserDlgContentMode()
                    )} */}
                        {/* {userDlgMode === ManageUserDlgMode.CreateUserLinkEmployee && (
                    employeeLookUpDiv()
                )} */}
                        {
                            (
                                userDlgMode !== ManageUserDlgMode.CreateUserLinkEmployee &&
                                userDlgMode !== ManageUserDlgMode.CreateUserLinkContact
                            ) && (
                                <>
                                    <ACTextBox<UserRow> field="FirstName" label="First Name" required placeholder="First Name" />
                                    <ACTextBox<UserRow> field="LastName" label="Last Name" required placeholder="Last Name" />
                                </>
                            )}
                        <RAFEmailTextBox<UserRow> field="UserName" label="User Name" required placeholder="User Name" />
                        <ACLookup<UserRow> field="PermissionGroup"
                            label={isUserActions ? 'Role Name' : "Permission Group"}
                            placeholder={isUserActions ? 'Select Role Name' : "Select Permission Group"}
                            //type={RoleRow}
                            showFullList={false}
                            url={'PermissionGroup/Lookup'} moduleName={RAFEntityName.Role}
                            showClearButton={false}
                            customFilter={getPermissionGroupLookUpCustomFilter()}
                            required
                        />
                    </div>
                </div>
            );
        }
    };

    if (props.isActive) {
        if (state.isLoading === false) {
            if (state.noContent === false) {
                return (
                    <RAFEntityProvider moduleName={moduleName}>
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFForm
                                type={UserRow}
                                initialValues={state.userRow}
                                formRef={(g) => (rafForm = g)}
                                layout={RAFLayout.TwoColumnLayout}
                                onSubmit={onSubmitUser}
                                className="h-100"
                            >
                                <div className="e-dlg-content-outer" id={outerDivId}>
                                    {getInputFormContent()}
                                    <div className="e-dlg-footerContent ">
                                        {getFooterContent()}
                                    </div>
                                </div>
                            </RAFForm>
                        </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                );
            } else {
                return (
                    <div className="container-fluid px-0">
                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                    </div>
                );
            }
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Loading..." />
                </div>
            );
        }
    } else {
        return (
            <div></div>
        );
    }
};


export default React.memo(ManageUser);
