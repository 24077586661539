import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Component } from "react";
import { FormRenderProps } from "react-final-form";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { RAFLayout } from "../../../constants/Common/Constants";
import { IDialogProps, isNotNullAndUndefined, RAFFormComponentProps } from "../../../RAFComponents/helpers/utils";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { NavigateParamsProps } from "../../../router";
import { CountryRow } from "../Country/CountryRow";
import CreateCountry from "../Country/CreateCountry";
import { StateRow } from './StateRow';

interface IState {
    isActive?: boolean,
}
class CreateState extends Component<IDialogProps & RAFFormComponentProps & NavigateParamsProps, IState> {

    public rafForm: FormRenderProps | null;
    public submitButton: ButtonComponent;

    constructor(props) {
        super(props);
        this.state = {
            isActive: isNotNullAndUndefined(props.isActive) ? props.isActive : false,
        }
    }

    onSaveState = (entityId, objectName) => {
        if (this.props.onSave)
            this.props.onSave(entityId, objectName);
    }

    enableSubmitButton = (g: FormRenderProps | null) => {
        if (g) {
            if (isNotNullAndUndefined(this.submitButton)) {
                this.submitButton.disabled = g.submitting;
            }
        }
    }

    enableCreate = (isActive) => {
        this.setState({ isActive: isActive });
    }

    render() {
        let stateRow: StateRow = new StateRow() as StateRow;
        if (isNotNullAndUndefined(this.props.initialValues)) {
            stateRow = this.props.initialValues as StateRow;
        }

        let isActive = this.state.isActive;

        return (
            isActive &&
            <div>
                <RAFForm type={StateRow} initialValues={stateRow} formRef={g => { this.enableSubmitButton(g); return this.rafForm = g }} layout={RAFLayout.TwoColumnLayout} onSave={this.onSaveState}>
                    <div className="e-dlg-body-content">
                        <ACTextBox<StateRow> field="StateName" label="State Name" required placeholder="State Name" />
                        <ACLookup <StateRow> field="Country" label="Country" placeholder="Select Country" required type={CountryRow}
                            createform={CreateCountry} url='Country/Lookup'
                            createformdefaultfield={"CountryName"} showFullList={false}
                            SearchCreateOptionMode={"Footer"}
                        />
                    </div>
                    <div className="e-dlg-footerContent ">
                        <ButtonComponent id="btnCreateStateSave" type="button" className="me-2" cssClass='e-flat e-info' ref={g => this.submitButton = g} disabled={(this.rafForm && (this.rafForm.submitting))} onClick={() => { this.rafForm && this.rafForm.form.submit() }}>Save</ButtonComponent>
                        <ButtonComponent id="btnCreateStateCancel" type="button" cssClass='e-flat' onClick={this.props.onClose}>Cancel</ButtonComponent>
                    </div>
                </RAFForm>
            </div>
        );
    }
}

export default CreateState;
