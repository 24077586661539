import { ChangeEventArgs, DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFFieldLabel from "../../../RAFComponents/Inputs/RAFFieldLabel";
import { RAFFieldError, composeValidators } from "../../../RAFComponents/Inputs/RAFForm";
import { RAFDefaultFieldProps, RAFFieldProps, RAFFormContext, getFormValue, isRequired, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { LookUpGroupRow } from "../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import { RAFUserTeam } from "../../../constants/Common/Constants";

interface IProps {
    showAdd?: boolean;
    showRemove?: boolean;
    attributesRow?: QueryAttributeJM[];
    usersAndTeams?: LookUpGroupRow[];
}

function RAFUserTeamDropdown<T>(
    { field, onChanged, children,

        showAdd = false,
        showRemove = false,
        required = RAFDefaultFieldProps.required,
        showLabel = RAFDefaultFieldProps.showLabel,
        disabled = RAFDefaultFieldProps.disabled,
        showClearButton = RAFDefaultFieldProps.showClearButton,
        validate = RAFDefaultFieldProps.validate,

        ...props
    }: PropsWithChildren<RAFFieldProps<T> & IProps>,
) {
    const assigneeField = 'Assignee';
    const assigneeFieldUID = assigneeField + 'UID';
    const assignTeamField = 'AssignTeam';
    const assignTeamFieldUID = assignTeamField + 'UID';

    let fields = { groupBy: 'Catagory', text: 'Value', value: 'Value' };
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
    let dropDownListComponent: DropDownListComponent | null;

    //const assigneeUID = getFormValue(rafFormContextValue, assigneeFieldUID);

    const assignTeamUID = getFormValue(rafFormContextValue, assignTeamFieldUID);

    const fieldName = isNotNullAndUndefined(assignTeamUID) ? assignTeamFieldUID : assigneeFieldUID;


    const getAssigneeData = () => {
        let retVal = null;
        const assigneeUIDValue = getFormValue(rafFormContextValue, assigneeFieldUID);
        const assigneeValue = getFormValue(rafFormContextValue, assigneeField);
        const assignTeamUIDValue = getFormValue(rafFormContextValue, assignTeamFieldUID);
        const assignTeamValue = getFormValue(rafFormContextValue, assignTeamField);
        if (isNotNullAndUndefined(assigneeUIDValue)) {
            retVal = assigneeValue;
        }
        else if (isNotNullAndUndefined(assignTeamUIDValue)) {
            retVal = assignTeamValue;
        }
        return retVal;
    };

    const onAssigneeDataChanged = (e: ChangeEventArgs) => {
        if (isNotNullAndUndefined(e.itemData)) {
            const objCategory = e.itemData['Catagory'];
            if (objCategory === RAFUserTeam.Team) {
                setFormValue(rafFormContextValue, assignTeamFieldUID, e.itemData['UID']);
                setFormValue(rafFormContextValue, assignTeamField, e.itemData['Value']);
                setFormValue(rafFormContextValue, assigneeFieldUID, null);
                setFormValue(rafFormContextValue, assigneeField, null);
            }
            else {
                setFormValue(rafFormContextValue, assigneeFieldUID, e.itemData['UID']);
                setFormValue(rafFormContextValue, assigneeField, e.itemData['Value']);
                setFormValue(rafFormContextValue, assignTeamFieldUID, null);
                setFormValue(rafFormContextValue, assignTeamField, null);
            }
            if (isNotNullAndUndefined(onChanged)) {
                onChanged(e.itemData['Value'], e.itemData['UID']);
            }
        }
        else {
            setFormValue(rafFormContextValue, assigneeFieldUID, null);
            setFormValue(rafFormContextValue, assigneeField, null);
            setFormValue(rafFormContextValue, assignTeamFieldUID, null);
            setFormValue(rafFormContextValue, assignTeamField, null);

            if (isNotNullAndUndefined(onChanged)) {
                onChanged(null, null);
            }
        }
    };

    return (
        <div className={
            isNotNullAndUndefined(props.formGroupClassName)
                ? props.formGroupClassName +
                " form-group"
                : "form-group"
        }>
            <div className={isNotNullAndUndefined(props.rowClassName)
                ? `${props.rowClassName} row`
                : "row g-0 gy-1"} id={"rafdiv" + fieldName.toString()}
            >
                {showLabel && showLabel === true &&
                    <RAFFieldLabel
                        field={field}
                        label={props.label}
                        required={required}
                        labelClassName={props.labelClassName}
                    ></RAFFieldLabel>
                }
                <div className={props.inputFieldClassName ? props.inputFieldClassName + " " : "col-12"}>

                    <Field name={fieldName.toString()}
                        {...props.initialValue ? { initialValue: props.initialValue } : {}}
                        //validate={validate === true ? (required && isRequired) : null}
                        {...props.validators ?
                            { validate: validate === true ? composeValidators(required === true ? isRequired : null, ...props.validators) : null }
                            :
                            { validate: validate === true ? composeValidators(required === true ? isRequired : null) : null }
                        }
                    >
                        {({ input, meta }) => {
                            return (
                                <div className="">
                                    <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                        <DropDownListComponent name={input.name} value={getAssigneeData()}
                                            ref={g => dropDownListComponent = g}
                                            change={(e) => {
                                                if (e.isInteracted) {
                                                    onAssigneeDataChanged(e);
                                                }
                                            }}
                                            dataSource={props.usersAndTeams as any} fields={fields}
                                            //delayUpdate
                                            filterType="Contains"
                                            allowFiltering
                                            width="100%"
                                            cssClass={`${meta.error && meta.touched ? "inputFieldError " : ''}unsetHeight`}
                                            showClearButton={showClearButton}
                                        />

                                        {props.hideRequiredMessage !== true ?
                                            <RAFFieldError name={fieldName} /> :
                                            ""}
                                    </div>
                                </div>
                            );
                        }}
                    </Field>
                </div>
            </div>
        </div>
    );
}

export default RAFUserTeamDropdown;

