import { atomFamily } from "recoil";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { RAFSort } from "../../../RAFComponents/helpers/types";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../constants/Common/Constants";
import { TagRow } from "./TagRow";


export const atomSelectedTagName = atomFamily<any, any>({
    key: "selectedTagNameState",
    default: null
});

export function getAllTagLookUpList(entityName: string, headerFilterText?: string) {
    return new Promise<LookUpRow[]>((resolve) => {
        let listServiceRequest = new ListServiceRequest();
        listServiceRequest.Skip = 0;
        listServiceRequest.Take = 0;

        if (IsNotNullOrWhiteSpace(headerFilterText)) {
            listServiceRequest.ContainsText = headerFilterText;
        }
        let sort: RAFSort = { field: propertyOf<TagRow>("TagName"), order: 'ascending' };
        let sortQuery: string[] = [];
        if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
            sortQuery.push(sort.order === 'descending' ? `${sort.field} desc` : sort.field);
        }

        listServiceRequest.Sort = sortQuery;

        listServiceRequest.EntityName = entityName;

        return repositoryActions
            .postDataAndGetResponse(
                "Tag/LookUpDropDown",
                listServiceRequest,
                null,
                ContentType.applicationJson,
                false
            )
            .then((response) => {
                if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data)
                ) {
                    resolve(response.data.Entities);
                } else {
                    resolve(null);
                }
            })
            .catch((error) => error);
    });
}