import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { ILookupRow } from '../../../RAFComponents/models/CompositeTypes/ILookupRow';
import { RAFEntityBase } from './../../../RAFComponents/models/Common/RAFEntityBase';

//export class TicketRow extends RAFEntityBase {
export class ITicketRow {
    //Id?: number;
    UID?: string;
    TicketId?: string;
    Subject?: string;
    AccountUID?: string;
    Account?: string;
    ContactUID?: string;
    Contact?: string;
    Description?: string;
    TicketTypeUID?: string;
    TicketType?: string;
    RequestOrigin?: string;
    Priority?: string;
    StatusUID?: string;
    Status?: string;
    Substatus?: string;
    LoggedDate?: Date;
    Assignee?: string;
    AssigneeUID?: string;
    InternalContact?: string;
    InternalContactUID?: string;
    AdditionalInfo?: string;
    ExtraInfoJson?: any;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    ProcessStatus?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    //AclId?: number;
    Portal?:string;
    PortalUID?:string;
    BpStep?:string;
    BpStepUID?:string;
}
export class TicketRow extends ITicketRow implements RAFEntityBase, ILookupRow {
    getClassName?() {//use entityname
        return 'ticket';
    }
    getIdField?(): string {
        return propertyOf<TicketRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "Ticket/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "Ticket/Save";
    }
    getGroupByUrl?(): string {
        return "Account/GroupBy";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "Ticket/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "Ticket/RelatedList";
    }
}
