import { Browser } from "@syncfusion/ej2-base";
import React, { PropsWithChildren } from "react";
import { useRecoilValue } from "recoil";
import RAFPermissionRender from "../../../../RAFComponents/helpers/PermissionHelper";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFTreeViewMenu } from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import { atomSelectedViewState } from "../../../Common/List/IndexHelper";
import { TaskDropdownItems } from "../TaskRow";
import "../TaskStyle.scss";
import RAFTaskIndexContent from "./RAFTaskIndexContent";

interface IProps {
  mode?: string;
  listPageGridID?: string;
  //isBusineesProcess?: boolean;
  showCreateSection?: boolean;
  showRelatedToField?: boolean;
  isRelatedSection?: boolean;
  hideTabHeader?: boolean;
  secondaryRelatedToUID?: string;
}

function RAFTaskIndex({
  mode,
  showCreateSection,
  listPageGridID = `grid3_${RAFEntityName.Task}_mainList`,
  ...props
}: PropsWithChildren<IProps>) {
  let calWidth;

  const currentView: RAFTreeViewMenu = useRecoilValue(
    atomSelectedViewState(RAFEntityName.Task)
  );

  //const selectedTaskDropdownValue = useRecoilValue(selectedTaskView);

  const getLeftViewFilterText = (currentView: RAFTreeViewMenu) => {
    let filterText = `${TaskDropdownItems.MyTask}`;

    if (
      props.isRelatedSection !== true &&
      isNotNullAndUndefined(currentView) &&
      isNotNullAndUndefined(currentView.DisplayName)
    ) {
      filterText = currentView.Name;
    }

    return filterText;
  };

  const selectedTaskLeftView = getLeftViewFilterText(currentView);


  if (!Browser.isDevice === true) {
    calWidth = "calc(100% - 450px)";
  } else {
    calWidth = "100%";
  }

  let dateColumnWidth = "";
  if (window.innerWidth < 768) {
    dateColumnWidth = "130px";
  } else {
    dateColumnWidth = "95px";
  }
  return (
    <div
      className={
        mode === "dialog" ? "w-100 h-100 custom-bg-grey" : "w-100 h-100"
      }
      style={{ maxWidth: "100%" }}
    >
      <div className="row h-100 g-0 flex-nowrap">
        <div
          style={{ width: calWidth }}
          className={
            !Browser.isDevice === true
              ? "col h-100 d-flex align-items-center justify-content-center"
              : "col-md-12 h-100 d-flex align-items-center justify-content-center"
          }
        >
          <div
            id="task_widget"
            className="task-grid taskTab h-100 w-100"
          //style={{ width: "calc(100% - 1.5rem)" }}
          >
            <RAFPermissionRender
              permissionName={PermissionConstants.TaskRead}
            >
              {/* {isNotNullAndUndefined(showCreateSection) &&
                        showCreateSection === true && <RAFTaskIndexCreate />} */}
              {/* <div className="bg-white">
                        <div className="p-2 w-100">
                          <div className="row g-3 align-items-center">
                            <div className="col">
                              <RAFTaskViewDropdown />
                            </div>
                          </div>
                        </div>
                      </div> */}
              {(isNotNullAndUndefined(currentView) ||
                props.isRelatedSection === true) && (
                  <div
                    id="taskTabContentOutter"
                    className={
                      mode === "dialog"
                        ? "task-tab-content-outter"
                        : "task-tab-content-outter"
                    }
                  >
                    <RAFTaskIndexContent
                      mode={mode}
                      listPageGridID={listPageGridID}
                      //isBusineesProcess={props.isBusineesProcess}
                      showRelatedToField={props.showRelatedToField}
                      isRelatedSection={props.isRelatedSection}
                      selectedTaskDropdownValue={selectedTaskLeftView}
                      hideTabHeader={props.hideTabHeader}
                      secondaryRelatedToUID={props.secondaryRelatedToUID}
                    />
                  </div>
                )}
            </RAFPermissionRender>
          </div>
        </div>
      </div>
    </div>
  );

}

export default React.memo(RAFTaskIndex);
