import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  Suspense,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFEmptyState, {
  RAFEmptyStateProps,
} from "../../../RAFComponents/Navigation/RAFEmptyState";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  triggerRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import { getFocusListByRelatedUID } from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { BusinessProcessRow } from "../../../RAFMaster/RMModules/BusinessProcess/Process/BusinessProcessRow";
import { BusinessProcessStepRow } from "../../../RAFMaster/RMModules/BusinessProcess/Step/BusinessProcessStepRow";
import RAFBusinessProcessStepDetails from "../../../RAFMaster/RMModules/BusinessProcess/Step/RAFBusinessProcessStepDetails";
import { BusinessProcessStepTemplateRow } from "../../../RAFMaster/RMModules/BusinessProcess/StepTemplate/BusinessProcessStepTemplateRow";
import {
  CompleteTaskAndStep,
  GetBPStepsAndTemplateStepsById,
  RetrieveBusinessProcessStepById,
} from "../../../RAFMaster/RMModules/BusinessProcess/helpers/ProcessHelper";
import { RAFActionStatus } from "../../../constants/Common/Constants";
import { RAFBPStepStatus } from "../../../constants/Common/RMConstants";

import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import dashboard_upcoming_es from "../../../assets/ES/dashboard_upcoming_es.svg";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { NavigateParamsProps } from "../../../router";
import DashboardWidgetComponent from "../Dashboards/DashboardWidgetComponent";
import ManageTask from "./ManageTask";
import RAFRelatedTaskTab from "./RelatedTask/RAFRelatedTaskTab";
import {
  completeTaskAndRemoveFromFocusList,
  getAllRelatedTasksCount,
  getRelatedNextActionTask,
  getRelatedTaskCount,
  getTaskDisplayDateAndBadge,
} from "./TaskHelper";
import RAFTaskIndex from "./TaskIndex/RAFTaskIndex";
import { ConvertTaskRowToTaskDto } from "./TaskMappingRegister";
import { MyTaskCount, TaskRow } from "./TaskRow";

interface IProps {
  relatedToUID?: string;
  relatedTo?: string;
  relatedToType?: string;
  objectData?: any;
  refreshOnCompleteTask?: () => void;
  refreshTaskAndComment?: () => void; // not used
  refreshOnCommentsUpdated?: () => void; // not used
  relatedCommentsSectionUpdate?: () => void; // not used
  showAddBtn?: boolean;
  addBtnContent?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  addBtnDisplayStyle?: "SectionFooter" | "Primary";
  addBtnClassname?: string;
  hideSectionHeader?: boolean;
  showTasksWidgetContent?: boolean;
  hideTabMenu?: boolean;
  headerTemplate?: React.ReactNode;
  headerString?: { primaryHeader: string; secondaryHeader: string; };

  shiftStatus?: string;
  showAllTasks?: boolean;
}

interface IState {
  isFocused: boolean;
  upcomingTaskRow: TaskRow;
  taskSummaryData: MyTaskCount;
  bpRequest: {
    currentBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
    businessProcessRow: BusinessProcessRow;
    bpTemplateSteps: BusinessProcessStepTemplateRow[];
  };

  //dialog
  isCreateTask: boolean;
  showManageTaskContent: boolean;
  showTasksWidgetContent: boolean;
  showBPStepContent: boolean;
}

function MyTaskWidget({
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isFocused: false,
      upcomingTaskRow: null,
      taskSummaryData: null,
      bpRequest: null,

      isCreateTask: false,
      showManageTaskContent: false,
      showTasksWidgetContent: isNotNullAndUndefined(
        props.showTasksWidgetContent
      )
        ? props.showTasksWidgetContent
        : false,
      showBPStepContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.relatedToUID, props.relatedTo]);

  const hideTabMenu = isNotNullAndUndefined(props.hideTabMenu)
    ? props.hideTabMenu
    : false;

  const showAllTasks = isNotNullAndUndefined(props.showAllTasks)
    ? props.showAllTasks
    : false;

  const itemCount =
    isNotNullAndUndefined(state.taskSummaryData) &&
      isNotNullAndUndefined(state.taskSummaryData.All)
      ? state.taskSummaryData.All
      : 0;

  const headerTitle = isNotNullAndUndefined(props.headerTemplate) ? (
    props.headerTemplate
  ) : IsNotNullOrWhiteSpace(props.headerString) &&
    IsNotNullOrWhiteSpace(props.headerString.primaryHeader) &&
    IsNotNullOrWhiteSpace(props.headerString.secondaryHeader) ? (
    <div>
      <div className="d-flex align-items-center">
        <div className="header-text">{props.headerString.primaryHeader}</div>
        {hideTabMenu === true && itemCount > 0 && (
          <span className="e-badge e-badge-circle e-badge-border ms-1">
            {itemCount}
          </span>
        )}
      </div>
      <div className="secondary-header-text-dark">
        {props.headerString.secondaryHeader}
      </div>
    </div>
  ) : (
    <span className="e-header-title header-text-sm" style={{ fontWeight: 500 }}>
      {"Tasks"}
    </span>
  );

  const refresh = async (refreshOnCompleteTask?: boolean) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    if (isNotNullAndUndefined(props.relatedToUID)) {
      let relatedToFilter: RAFCustomFilter = {};
      let relatedToFilterVal: string[] = [];
      relatedToFilterVal.push(props.relatedToUID);
      relatedToFilter.Operator = RAFCustomOperator.Equal;
      relatedToFilter.Value = relatedToFilterVal;
      relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
      customFilter.Rules.push(relatedToFilter);
    }

    if (isNotNullAndUndefined(props.secondaryRelatedToUID)) {
      let secondaryRelatedToFilter: RAFCustomFilter = {};
      let secondaryRelatedToFilterVal: string[] = [];
      secondaryRelatedToFilterVal.push(props.relatedToUID);
      secondaryRelatedToFilter.Operator = RAFCustomOperator.Equal;
      secondaryRelatedToFilter.Value = secondaryRelatedToFilterVal;
      secondaryRelatedToFilter.Field = propertyOf<TaskRow>(
        "SecondaryRelatedToUID"
      );
      customFilter.Rules.push(secondaryRelatedToFilter);
    }

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = customFilter;

    if (
      !IsNullOrWhiteSpace(props.relatedToType) &&
      !IsNullOrWhiteSpace(props.relatedToUID)
    ) {
      let progressDiv = showProgress("#myTaskWidgetBody");
      let taskSummaryData: MyTaskCount = {
        All: 0,
        ASAP: 0,
        Today: 0,
        Delegated: 0,
        Upcoming: 0,
        Overdue: 0,
        Available: 0,
      };

      if (hideTabMenu === true) {
        let relatedTasksCount = await getAllRelatedTasksCount(
          props.relatedToUID,
          props.secondaryRelatedToUID
        );

        if (isNotNullAndUndefined(relatedTasksCount) && relatedTasksCount > 0) {
          taskSummaryData.All = relatedTasksCount;
        }
      } else {
        taskSummaryData = await getRelatedTaskCount(
          props.relatedToUID,
          props.secondaryRelatedToUID
        );
      }

      //let upcomingResponse: TaskRow[] = upComingCount > 0 ? await getRelatedUpcomingTask(props.relatedToUID) : null;
      let upcomingTaskRow = await getRelatedNextActionTask(
        props.relatedToUID,
        taskSummaryData
      );
      let _isFocused =
        isNotNullAndUndefined(upcomingTaskRow) &&
          isNotNullAndUndefined(upcomingTaskRow.UID)
          ? await getFocusListByRelatedUID(upcomingTaskRow.UID)
          : false;

      hideProgress(progressDiv);
      setState({
        isFocused: _isFocused,
        taskSummaryData,
        upcomingTaskRow,
      });
      if (refreshOnCompleteTask) {
        refreshRelatedObject();
      }
    }
  };

  const refreshRelatedObject = () => {
    // if (props.refreshOnCompleteTask)
    //   props.refreshOnCompleteTask();
    console.log("refreshRelatedObject");
  };

  const refreshRelatedComments = () => {
    triggerRAFEvent(
      `${RAFEventName.ReloadCommentsContent}_${props.relatedToUID}`,
      {
        moduleName: `${props.relatedToType}`,
      }
    );
  };

  //taskWidget start
  const taskWidgetDialogOpen = () => {
    setState({ showTasksWidgetContent: true });
  };

  const taskWidgetGridContent = () => {
    const listPageGridID = `grid3_${RAFEntityName.Task}_${props.relatedToUID}_relatedList`;
    return (
      <DashboardWidgetComponent
        //key={state.taskWidgetKey}
        contentTemplate={
          <RAFTaskIndex
            mode="dialog"
            showRelatedToField={false}
            isRelatedSection={true}
            listPageGridID={listPageGridID}
            hideTabHeader={hideTabMenu}
            secondaryRelatedToUID={props.secondaryRelatedToUID}
            {...props}
          />
        }
      />
    );
  };
  const taskWidgetContent = () => {
    if (props.showTasksWidgetContent) {
      return taskWidgetGridContent();
    } else if (state.showTasksWidgetContent === true) {
      return (
        <div>
          <DialogComponent
            header={"Tasks"}
            showCloseIcon
            visible={state.showTasksWidgetContent}
            cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
            id="task_details_dialog"
            content={taskWidgetGridContent}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeTaskWidgetContent.bind(this)}
            zIndex={1200}
          />
          {/* 
        <div className={"custom-dialog right-dialog right-dialog-lg show"}>
          <div className="custom-dialog-content">
            <div className="custom-dialog-header">
              <span className="header-text">Task</span>
              <ButtonComponent
                cssClass="primary-custom-button"
                iconCss="fas fa-xmark"
                onClick={() => closeTaskWidgetContent()}
              ></ButtonComponent>
            </div>
            <div className="custom-dialog-body-content p-0 overflow-hidden">
              {taskWidgetGridContent()}
            </div>
          </div> 
          </div> 
          */}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const closeTaskWidgetContent = () => {
    setState({ showTasksWidgetContent: false });
    refresh();
    refreshRelatedComments();
  };
  //taskWidget end

  //manage_task start

  const onClickManageTask = (isCreateTask, taskRow?: TaskRow) => {
    if (isNotNullAndUndefined(props.shiftStatus)) {
      if (
        props.shiftStatus === RAFActionStatus.Unassigned ||
        props.shiftStatus === RAFActionStatus.Planned
      ) {
        showWarningToast(
          "The task cannot be completed until the shift has started."
        );
      } else {
        setState({
          showManageTaskContent: true,
          isCreateTask,
          upcomingTaskRow: taskRow,
        });
      }
    } else {
      setState({
        showManageTaskContent: true,
        isCreateTask,
        upcomingTaskRow: taskRow,
      });
    }
  };

  const manageTaskContent = () => {
    if (state.showManageTaskContent === true) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ManageTask
            onSave={refreshOnTaskUpdate.bind(this)}
            onDelete={refreshOnTaskUpdate.bind(this)}
            onClose={onCloseManageTaskContent.bind(this)}
            isDashboard
            complete={(taskRow, taskStatus) =>
              completeTaskClicked(taskRow, taskStatus)
            }
            contextModuleName={props.relatedToType}
            //taskUID={state.taskUID}
            isActive
            relatedCommentsUpdated={(value) => {
              refreshRelatedComments();
            }}
            isCreateTask={state.isCreateTask}
            taskUID={
              state.isCreateTask === true ? null : state.upcomingTaskRow.UID
            }
            {...props}
          />
        </Suspense>
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnTaskUpdate = () => {
    onCloseManageTaskContent();
    refresh();
  };

  const onCloseManageTaskContent = () => {
    setState({ showManageTaskContent: false, isCreateTask: false });
  };
  //manage_task start

  //completeTask start

  const completeTaskClicked = (
    selectedTaskRow: TaskRow,
    taskStatus: string
  ) => {
    onCloseManageTaskContent();
    let taskRow = ConvertTaskRowToTaskDto(selectedTaskRow);
    if (isNotNullAndUndefined(taskRow.BPStepUID)) {
      let progressDiv = showProgress("body", true);
      RetrieveBusinessProcessStepById(taskRow.BPStepUID).then((bpStepRow) => {
        //get bpstep details and related data
        GetBPStepsAndTemplateStepsById(taskRow.BPStepUID).then((bpResponse) => {
          if (
            isNotNullAndUndefined(bpStepRow.FormUID) ||
            bpStepRow.IsApproval === true
          ) {
            hideProgress(progressDiv);
            //show bpstep dialog for complete bpstep
            businessProcessSteDialogOpen(bpResponse);
            onCloseManageTaskContent();
          } else {
            //complete bpstep and task
            let currentBPStepRow: BusinessProcessStepRow =
              bpResponse.currentBPStepRow;
            currentBPStepRow.StepStatus = RAFBPStepStatus.Completed;
            currentBPStepRow.CompletedDate = new Date();
            CompleteTaskAndStep(
              currentBPStepRow,
              bpResponse.allbpStepRows,
              bpResponse.businessProcessRow,
              bpResponse.bpTemplateSteps,
              props.objectData,
              taskStatus
            ).then(() => {
              hideProgress(progressDiv);
              refresh(true);
              refreshRelatedComments();
              onCloseManageTaskContent();
            });
          }
        });
      });
    } else {
      completeTask(taskRow, taskStatus);
    }
  };

  const completeTask = (taskRow: TaskRow, taskStatus: string) => {
    let progressDiv = showProgress("body", true);
    taskRow.TaskStatus = taskStatus;

    completeTaskAndRemoveFromFocusList(taskRow)
      .then((response) => {
        hideProgress(progressDiv);
        //if (isNotNullAndUndefined(response)) {
        refresh();
        refreshRelatedComments();
        //}
      })
      .catch((error) => error);
  };

  //completeTask end

  //bp_Step start

  const businessProcessSteDialogOpen = (bpResponse) => {
    document.body.classList.add("overflow-hidden");
    setState({
      showBPStepContent: true,
      bpRequest: {
        currentBPStepRow: bpResponse.currentBPStepRow,
        allbpStepRows: bpResponse.allbpStepRows,
        businessProcessRow: bpResponse.businessProcessRow,
        bpTemplateSteps: bpResponse.bpTemplateSteps,
      },
    });
  };

  function businessProcessStepContent() {
    if (state.showBPStepContent === true) {
      let { bpRequest } = state;
      let businessProcessStepRow: BusinessProcessStepRow =
        new BusinessProcessStepRow();
      if (isNotNullAndUndefined(bpRequest)) {
        businessProcessStepRow = bpRequest.currentBPStepRow;
      }

      return (
        <div>
          <RAFBusinessProcessStepDetails
            businessProcessStepRow={bpRequest.currentBPStepRow}
            businessProcessRow={bpRequest.businessProcessRow}
            businessProcessStepRows={bpRequest.allbpStepRows}
            bpTemplateStepsRow={bpRequest.bpTemplateSteps}
            onBPStepSave={() => onBPStepSaved()}
            onCloseBusinessProcessDialog={() => {
              businessProcessStepDialogClose();
            }}
            mode="Dialog"
            hideRelatedCommentSections={
              businessProcessStepRow &&
                businessProcessStepRow.StepStatus ===
                RAFBPStepStatus.AwaitingforApproval
                ? false
                : true
            }
            hideRelateTaskSections
            hideRelatedDoumentSections
          //hideRelatedSections
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  const onBPStepSaved = () => {
    document.body.classList.remove("overflow-hidden");
    setState({ showBPStepContent: false });
    refresh(true);
  };

  function businessProcessStepDialogClose(): void {
    document.body.classList.remove("overflow-hidden");
    setState({
      showBPStepContent: false,
      bpRequest: {
        currentBPStepRow: null,
        allbpStepRows: null,
        businessProcessRow: null,
        bpTemplateSteps: null,
      },
    });
  }
  //bp_Step end

  //UpcomingTaskDiv end
  const getUpcomingTaskDiv = (upcomingTaskRow: TaskRow) => {
    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
      upcomingTaskRow.TaskDate,
      upcomingTaskRow.TaskType
    );
    let taskDate = taskDisplayDateAndBadge.taskDate;
    let taskDueDate = taskDisplayDateAndBadge.taskDueDate;
    let dateBadge = taskDisplayDateAndBadge.dateBadge;

    let titleTooltipContent;
    if (upcomingTaskRow.TaskDate !== null) {
      let titleTooltipDescription = isNotNullAndUndefined(
        upcomingTaskRow.Description
      )
        ? upcomingTaskRow.Description
        : "";
      titleTooltipContent =
        "<h6>" +
        upcomingTaskRow.Title +
        "</h6>" +
        "<p>" +
        titleTooltipDescription +
        "</p>";
    }

    return (
      <RolePermissionsContext.Consumer>
        {({ permissionValue }) => {
          // let hasPermissionToEdit = false;
          if (isNotNullAndUndefined(permissionValue))
            //hasPermissionToEdit = hasPermission(permissionValue, ACPermissionConstants.TaskUpdate);
            return (
              <div
                className="activityItems border-0 flex-row px-2"
                onClick={() => {
                  onClickManageTask(false);
                }}
              >
                <div className="w-100 d-flex align-items-center justify-space-around">
                  <div className="text-nowrap px-2">
                    <TooltipComponent
                      content={taskDueDate}
                      position="BottomCenter"
                    >
                      <span
                        className={
                          upcomingTaskRow.TaskStatus !== RAFActionStatus.Planned
                            ? "activityDateSpanSection ms-2 badge grey lighten-1 badge-grey"
                            : "activityDateSpanSection badge " + dateBadge
                        }
                      >
                        {taskDate}
                      </span>
                    </TooltipComponent>
                  </div>
                  <TooltipComponent
                    content={titleTooltipContent}
                    position="BottomCenter"
                  >
                    <span className="itemHeader ecllipseFirstLine text-decoration-none px-1">
                      {upcomingTaskRow.Title}
                    </span>
                  </TooltipComponent>
                </div>
              </div>
            );
        }}
      </RolePermissionsContext.Consumer>
    );
  };
  //UpcomingTaskDiv end

  const upComingCount = isNotNullAndUndefined(state.taskSummaryData)
    ? state.taskSummaryData.Upcoming
    : 0;
  const todayTaskCount =
    isNotNullAndUndefined(state.taskSummaryData) &&
      isNotNullAndUndefined(state.taskSummaryData.Today)
      ? state.taskSummaryData.Today
      : 0;

  const nextActionTaskCount = todayTaskCount + upComingCount;
  // let delegatedCount = isNotNullAndUndefined(state.taskSummaryData)
  //   ? state.taskSummaryData.Delegated
  //   : 0;

  let emptyRecord;
  if (isNullOrUndefined(state.upcomingTaskRow) && nextActionTaskCount < 1) {
    let emptyStateValue: RAFEmptyStateProps = {
      title: "Next action not planned.",
      image: dashboard_upcoming_es,
    }; //, body: "Please enjoy your day!"
    const emptyStateProps = emptyStateValue;
    //let stateClassName = isNotNullAndUndefined(emptyStateProps.image) ? 'empty-state-image' : 'empty-state-icon'
    emptyRecord = (
      <RAFEmptyState
        title={emptyStateProps.title}
        body={emptyStateProps.body}
        displayStyle="TextOnly"
      ></RAFEmptyState>
    );
  }

  let showAddBtn = props.showAddBtn;

  let addTaskBtn = (
    <RAFPermissionRender permissionName={PermissionConstants.TaskCreate}>
      <ButtonComponent
        //cssClass="primary-custom-button custom-button-md d-none d-sm-block"
        cssClass={`${props.addBtnDisplayStyle === "SectionFooter"
            ? "e-outline w-100 btn-dashed btn-roundedCorner e-primary"
            : isNotNullAndUndefined(props.addBtnClassname)
              ? props.addBtnClassname
              : "e-small d-none primary-custom-button d-sm-inline-block"
          }`}
        iconCss="fas fa-plus"
        onClick={() => onClickManageTask(true)}
        type="button"
        content={
          isNotNullAndUndefined(props.addBtnContent)
            ? props.addBtnContent
            : "Add"
        }
      ></ButtonComponent>
      {props.addBtnDisplayStyle === "SectionFooter" ? (
        ""
      ) : (
        <ButtonComponent
          //cssClass="primary-custom-button custom-button-md d-sm-none"
          cssClass={`${props.addBtnDisplayStyle === "Primary"
              ? "e-primary e-outline "
              : "primary-custom-button "
            }e-small d-sm-none`}
          iconCss="fas fa-plus"
          type="button"
          onClick={() => onClickManageTask(true)}
        ></ButtonComponent>
      )}
    </RAFPermissionRender>
  );

  // const itemCount =
  //   isNotNullAndUndefined(state.taskSummaryData) &&
  //     isNotNullAndUndefined(state.taskSummaryData.All)
  //     ? state.taskSummaryData.All
  //     : 0;

  return (
    <RAFPermissionRender permissionName={PermissionConstants.TaskRead}>
      {isNotNullAndUndefined(showAddBtn) && showAddBtn === true ? (
        addTaskBtn
      ) : (
        <div
          className="d-flex flex-column h-100 border-0"
          id="myTaskWidgetBody"
        >
          {props.hideSectionHeader !== true ? (
            <div
              className="e-card-header py-2 px-3 justify-content-between align-items-center d-flex"
              style={{ borderBottom: "1px solid #e3e3e7" }}
            >
              <div className="d-flex align-items-center">
                {hideTabMenu === false && (
                  <span className="fa fa-tasks me-2"></span>
                )}

                {headerTitle}
                {hideTabMenu === true &&
                  itemCount > 0 &&
                  !IsNotNullOrWhiteSpace(props.headerString) &&
                  !IsNotNullOrWhiteSpace(props.headerString.primaryHeader) &&
                  !IsNotNullOrWhiteSpace(
                    props.headerString.secondaryHeader
                  ) && (
                    <span className="e-badge e-badge-circle e-badge-border ms-1">
                      {itemCount}
                    </span>
                  )}
                {/* {nextActionTaskCount > 0 && (
                  <TooltipComponent
                    content={'Today and Upcoming'}
                    position="TopCenter"
                  >
                    <span className="ms-1 mt-1 e-badge e-badge-primary e-badge-circle">
                      {nextActionTaskCount}
                    </span>
                  </TooltipComponent>
                )} */}
              </div>
              <div className="row g-2">
                {props.addBtnDisplayStyle !== "SectionFooter" &&
                  hideTabMenu === false && (
                    <div className="col">
                      <RAFPermissionRender
                        permissionName={PermissionConstants.TaskCreate}
                      >
                        <ButtonComponent
                          cssClass={`${props.addBtnDisplayStyle === "Primary"
                              ? "e-primary e-outline "
                              : "primary-custom-button "
                            }custom-button-md d-none d-sm-block`}
                          iconCss="fas fa-plus"
                          onClick={() => onClickManageTask(true)}
                          type="button"
                        >
                          Add
                        </ButtonComponent>
                        <ButtonComponent
                          cssClass={`${props.addBtnDisplayStyle === "Primary"
                              ? "e-primary e-outline "
                              : "primary-custom-button "
                            }custom-button-md d-sm-none`}
                          iconCss="fas fa-plus"
                          type="button"
                          onClick={() => onClickManageTask(true)}
                        ></ButtonComponent>
                      </RAFPermissionRender>
                    </div>
                  )}
                {/* <div className="col-auto">
                  <RAFButtonComponent action={RAFButtonConstant.SectionExpandBtn} iconBtn className="primary-custom-button" onClick={taskWidgetDialogOpen}></RAFButtonComponent>
                </div> */}
              </div>
            </div>
          ) : (
            ""
          )}
          {props.showTasksWidgetContent === true ? (
            <div>{taskWidgetContent()}</div>
          ) : (
            <div className="overflow-auto customScrollBar task-card-content">
              <RAFRelatedTaskTab
                taskSummaryData={state.taskSummaryData}
                taskDialogOpen={() => taskWidgetDialogOpen()}
                relatedToUID={props.relatedToUID}
                secondaryRelatedToUID={props.secondaryRelatedToUID}
                onClickManageTask={(taskRow) =>
                  onClickManageTask(false, taskRow)
                }
                hideTabMenu={hideTabMenu}
                showAllTasks={showAllTasks}
              />
              {/* {isNotNullAndUndefined(state.upcomingTaskRow) && isNotNullAndUndefined(state.upcomingTaskRow.UID) ? (
                getUpcomingTaskDiv(state.upcomingTaskRow)
              ) : (
                <div className="d-flex additionalBtn align-items-center">
                  {emptyRecord}
                </div>
              )} */}
              <div>{taskWidgetContent()}</div>
            </div>
          )}
          {props.addBtnDisplayStyle === "SectionFooter" && (
            <div
              className={
                props.showTasksWidgetContent === true ? "pt-2" : "px-3 pt-2"
              }
            >
              {props.addBtnDisplayStyle === "SectionFooter" ? addTaskBtn : ""}
            </div>
          )}
        </div>
      )}
      <Fragment>
        {state.showManageTaskContent == true && (
          <DialogComponent
            visible={state.showManageTaskContent}
            cssClass="rightDialog createEditForm full-height"
            id="TaskDetailsSection"
            content={manageTaskContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={onCloseManageTaskContent.bind(this)}
            zIndex={1300}
          ></DialogComponent>
        )}

        {state.showBPStepContent && (
          <DialogComponent
            visible={state.showBPStepContent}
            cssClass="rightDialog createEditForm full-height"
            id="bpStepUpdateDialog"
            content={businessProcessStepContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={businessProcessStepDialogClose.bind(this)}
          ></DialogComponent>
        )}
      </Fragment>
    </RAFPermissionRender>
  );
}

export default MyTaskWidget;
