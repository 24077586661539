import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import createDecorator from "final-form-calculate";
import {
  PropsWithChildren,
  Reducer,
  Suspense,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { useRecoilValue } from "recoil";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import { isCurrentTenantCareESIOPartner } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import nodata_es from "../../../assets/ES/nodata_es.svg";
import ACDatePicker from "../../../components/shared/ACFormFields/ACDatePicker";
import ACDropdown from "../../../components/shared/ACFormFields/ACDropdown";
import ACLookup from "../../../components/shared/ACFormFields/ACLookup";
import ACTextArea from "../../../components/shared/ACFormFields/ACTextArea";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { TicketConstants } from "../../../constants/ActiveContact/ACPermissionConstants";
import { Constants, RAFLayout } from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFPageType } from "../../../constants/Common/RMConstants";
import { atomEditingRecordId } from "../../Common/List/IndexHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import CreateAccount from "../Account/CreateAccount";
import ACContactLookup from "../Contact/ACContactLookup";
import CreateContact from "../Contact/CreateContact";
import { UserRow } from "../User/UserRow";
import { TicketRow } from "./TicketRow";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";

interface IState {
  isActive?: boolean;
  noContent?: boolean;
  //ticketRow?: TicketRow;
  relatedFilter?: RAFCustomFilter;
}

interface IProps {
  objectUID?: string;
  ticket?: TicketRow;
}

function UpdateTicket({
  //ticket,
  isActive,
  objectUID,
  ...props
}: PropsWithChildren<IDialogProps & RAFFormComponentProps & IProps>) {
  const entityRow = useContext(RAFEntityContext);

  const moduleName: string = createInstance(TicketRow).getClassName();
  let rafForm: FormRenderProps | null;

  let ticketUID = useRecoilValue(atomEditingRecordId(moduleName));
  objectUID = isNotNullAndUndefined(ticketUID)
    ? ticketUID
    : isNotNullAndUndefined(objectUID)
    ? objectUID
    : isNotNullAndUndefined(props.ticket) &&
      isNotNullAndUndefined(props.ticket.UID)
    ? props.ticket.UID
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      noContent: false,
      //ticketRow: null,
    }
  );

  useEffect(() => {
    //console.log('UpdateAccount', objectUID);
    refresh(isActive, objectUID, props.ticket);
  }, [isActive, objectUID, props.ticket]);

  function refresh(isActive: boolean, id?: string, ticket?: TicketRow) {
    if (isNotNullAndUndefined(ticket) && isActive) {
      setState({
        isActive: true,
        noContent: false,
      });
    } else if (isNotNullAndUndefined(id) && isActive) {
      setState({
        isActive: true,
        noContent: false,
      });
      //getTicket(id);
    } else {
      setState({
        isActive: false,
      });
    }
  }

  // function getTicket(id) {
  //   const entityUID = isNotNullAndUndefined(entityRow) && isNotNullAndUndefined(entityRow.entity) ? entityRow.entity.UID : null;
  //   RetrieveRecord(id, moduleName, entityUID).then((response) => {
  //     if (isNotNullAndUndefined(response)) {
  //       setState({
  //         noContent: false,
  //         ticketRow: response
  //       });
  //     } else {
  //       setState({
  //         noContent: true,
  //         ticketRow: null
  //       });
  //     }
  //   })
  //     .catch((error) => error);
  // }

  if (state.isActive) {
    const onSaveTicket = (entityId, objectName) => {
      setState({ isActive: false });
      if (props.onSave) props.onSave(entityId, objectName);
    };

    const onAccountLookupChanged = (id) => {
      setFormValue(rafForm, "AccountUID", id);
      setFormValue(rafForm, "ContactUID", null);
      setFormValue(rafForm, "Contact", null);
    };

    let ticketDecorators = createDecorator({
      field: "Priority",
      updates: {
        Priority: (minimumValue, allValues: TicketRow) =>
          allValues.Priority === "None" ? null : allValues.Priority,
      },
    });

    const onSubmitObject = (
      values,
      saveObject?: (
        objectData?: any
      ) => Promise<{ objectUID?: string; objectName?: string }>
    ) => {
      // let progressDiv = showProgress("#update-page-outerDiv");
      saveObject(values)
        .then((response) => {
          // hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.objectUID)
          ) {
            showSuccessToast("Saved successfully");
            if (props.onSave)
              props.onSave(response.objectUID, response.objectName);
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    };

    if (
      !IsNullOrWhiteSpace(objectUID) ||
      (isNotNullAndUndefined(props.ticket) &&
        isNotNullAndUndefined(props.ticket.UID))
    ) {
      return (
        <div
          className="update-page h-100"
          id={`raf_dlg_Outer_Div_${moduleName}`}
        >
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFPageLayoutProvider rafPageType={RAFPageType.Edit}>
                <RAFObjectContextProvider
                  moduleName={moduleName}
                  objectId={objectUID}
                  progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                >
                  <RAFObjectContext.Consumer>
                    {({ /*objectId, */ rafObject, error, saveObject }) => {
                      if (
                        isNotNullAndUndefined(rafObject) &&
                        !IsNullOrWhiteSpace(rafObject.UID)
                      ) {
                        return (
                          <RAFForm
                            type={TicketRow}
                            initialValues={rafObject}
                            formRef={(g) => (rafForm = g)}
                            layout={RAFLayout.TwoColumnLayout}
                            onSubmit={(value) =>
                              onSubmitObject(value, saveObject)
                            }
                            //onSave={onSaveTicket}
                            primaryKey={rafObject.UID}
                            decorators={[ticketDecorators]}
                            className="h-100"
                          >
                            <div className="e-dlg-content-outer">
                              <div className="e-dlg-body-content">
                                <RAFPageLayout
                                  defaultComponent={
                                    <div>
                                      <ACTextBox<TicketRow>
                                        field="Subject"
                                        label="Subject"
                                        required
                                        placeholder="Subject"
                                      />
                                      <RAFLookUpMUI<TicketRow>
                                        field="Account"
                                        label="Company Name"
                                        placeholder="Select Company Name"
                                        required={
                                          !isCurrentTenantCareESIOPartner()
                                        }
                                        //type={AccountRow}
                                        showCreateButton
                                        url={"Account/Lookup"}
                                        moduleName={RAFEntityName.Account}
                                        createform={CreateAccount}
                                        createformdefaultfield={"AccountName"}
                                        onChanged={(value, uid) =>
                                          onAccountLookupChanged(uid)
                                        }
                                        selectedField={"AccountName"}
                                        SearchCreateOptionMode={"Footer"}
                                      />
                                      <ACContactLookup<TicketRow>
                                        field="Contact"
                                        label="Contact Name"
                                        placeholder="Select Contact Name"
                                        required
                                        //type={ContactRow}
                                        showCreateButton
                                        url={"Contact/Lookup"}
                                        moduleName={RAFEntityName.Contact}
                                        //account={ticketRow.Account} accountUID={ticketRow.AccountUID}
                                        createform={CreateContact}
                                        createformdefaultfield={"FirstName"}
                                        //createformaccountfield={"Account"}
                                        //createformaccountUIDfield={"AccountUID"}
                                        fieldName="Account"
                                        selectedField={"ContactName"}
                                        //createFormInitialValues={[{ key: "Account", value: ticketRow.Account }, { key: "AccountUID", value: ticketRow.AccountUID }]}
                                        SearchCreateOptionMode={"Footer"}
                                      />
                                      <ACDropdown<TicketRow>
                                        field="TicketType"
                                        label="Type"
                                        placeholder="Select Type"
                                        isColorOption
                                        mode="discView"
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={moduleName}
                                        required
                                      />
                                      <ACDropdown<TicketRow>
                                        field="Status"
                                        label="Status"
                                        placeholder="Select Status"
                                        isColorOption
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={moduleName}
                                        required
                                      />
                                      <ACDropdown<TicketRow>
                                        field="Substatus"
                                        label="Sub-status"
                                        placeholder="Select Sub-status"
                                        isColorOption
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={moduleName}
                                        required
                                      />
                                      <ACDatePicker<TicketRow>
                                        field="LoggedDate"
                                        label="Logged Date"
                                        required
                                        placeholder="Logged Date"
                                      />
                                      <ACDropdown<TicketRow>
                                        field="RequestOrigin"
                                        label="Request Origin"
                                        placeholder="Select Request Origin"
                                        required
                                        isColorOption
                                        mode="discView"
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={moduleName}
                                      />
                                      <ACDropdown<TicketRow>
                                        field="Priority"
                                        label="Priority"
                                        placeholder="Select Priority"
                                        isColorOption
                                        mode="discView"
                                        uitype="colorpicker"
                                        createOptionMode={"Footer"}
                                        moduleName={moduleName}
                                      />
                                      {/*<ACDropdown<TicketRow> field="RequestOrigin" label="Request Origin" placeholder="Select Request Origin" required >
                              <RAFChoiceOption label="Phone" value="Phone" />
                              <RAFChoiceOption label="Email" value="Email" />
                              <RAFChoiceOption label="Web" value="Web" />
                              <RAFChoiceOption label="Internal Request" value="Internal Request" />
                          </ACDropdown>
                          <ACDropdown<TicketRow> field="Priority" label="Priority" placeholder="Select Priority" >
                              <RAFChoiceOption label="High" value="High" />
                              <RAFChoiceOption label="Medium" value="Medium" />
                              <RAFChoiceOption label="Low" value="Low" />
                              <RAFChoiceOption label="Urgent" value="Urgent" />
                              <RAFChoiceOption label="Immediate" value="Immediate" />
                          </ACDropdown>*/}
                                      <ACTextArea<TicketRow>
                                        field="Description"
                                        label="Description of Problem"
                                      />
                                      {/*<ACTextBox<TicketRow> field="TicketId" label="Ticket No" placeholder="Ticket No" />*/}
                                      <ACLookup<TicketRow>
                                        field="InternalContact"
                                        label="Internal Contact"
                                        placeholder="Select Internal Contact"
                                        type={UserRow}
                                        url={"User/Lookup"}
                                        moduleName={RAFEntityName.User}
                                        showFullList={false}
                                      />
                                      <ACLookup<TicketRow>
                                        field="Assignee"
                                        label="Assign to"
                                        placeholder="Select Assign to"
                                        type={UserRow}
                                        url={"User/Lookup"}
                                        moduleName={RAFEntityName.User}
                                        showFullList={false}
                                      />
                                      <ACTextArea<TicketRow>
                                        field="AdditionalInfo"
                                        label="Additional Info"
                                      />
                                      {/*<RAFExtraInfo<TicketRow> field="ExtraInfoJson" label="Extra Info" />*/}
                                    </div>
                                  }
                                />
                              </div>
                              <div className="e-dlg-footerContent">
                                <RAFPermissionRender
                                  permissionName={TicketConstants.TicketUpdate}
                                >
                                  <ButtonComponent
                                    className="me-2"
                                    cssClass="e-flat e-info"
                                  >
                                    Save
                                  </ButtonComponent>
                                </RAFPermissionRender>
                                <ButtonComponent
                                  type="button"
                                  cssClass="e-flat"
                                  onClick={props.onClose}
                                >
                                  Cancel
                                </ButtonComponent>
                              </div>
                            </div>
                          </RAFForm>
                        );
                      } else {
                        if (error === Constants.RAFObject_NoContent) {
                          return (
                            <div className="container-fluid px-0">
                              <RAFDeletedRecordState
                                image={nodata_es}
                                title="This item is either deleted or You do not have sufficient privileges to view this item."
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="container-fluid px-0"
                              style={{ background: "transparent" }}
                            >
                              <ACLoadingPanel loadingText="Loading..." />
                            </div>
                          );
                        }
                      }
                    }}
                  </RAFObjectContext.Consumer>
                </RAFObjectContextProvider>
              </RAFPageLayoutProvider>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        </div>
      );
    } else if (state.noContent === true) {
      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          <div className="container-fluid px-0">
            <RAFDeletedRecordState
              image={nodata_es}
              title="This item is either deleted or You do not have sufficient privileges to view this item."
            />
          </div>
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<ACLoadingPanel loadingText="Loading..." />}>
          <div className="container-fluid px-0">
            <ACLoadingPanel loadingText="Loading..." />
          </div>
        </Suspense>
      );
    }
  } else {
    return <div></div>;
  }
}

export default UpdateTicket;
