import { MenuEventArgs } from "@syncfusion/ej2-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { msalInstance } from "../../..";
import { GridColumnTemplates } from "../../../RAFComponents/Grid/RAFSFGridUtils";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFRelatedToWithIcon from "../../../RAFComponents/Navigation/RAFRelatedToWithIcon";
import { getDisplayNameByModuleName, onLookupLinkClicked } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { IsNullOrWhiteSpace, isNotNullAndUndefined, isNullOrUndefined } from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { BrowserIsDevice, RAFActionStatus, RAFTaskType } from "../../../constants/Common/Constants";
import { getModuleAvatarClass, getModuleAvatarIcon } from "../../../helpers/Common/AvatarHelper";
import { getTaskDisplayDateAndBadge } from "./TaskHelper";
import { TaskRow } from "./TaskRow";

export const TaskGridColumnTemplates: GridColumnTemplates[] = [
    { key: "TaskStatus", value: "taskStatusTemplate" },
    { key: "TaskDate", value: "taskDateColumnTemplate" },
    { key: "CompletedDate", value: "taskDateColumnTemplate" },
    { key: "Title", value: "taskTitleColumnTemplate" },
    //{ key: "Title", value: "taskTitleRowTemplate" },
    { key: "RelatedTo", value: "relatedTaskToLookupTemplate" },
    { key: "ModifiedDate", value: "updatedDateTemplate" },
];


export const TaskGridNoCompletedDateColumnTemplates: GridColumnTemplates[] = [
    { key: "TaskStatus", value: "taskStatusTemplate" },
    { key: "TaskDate", value: "taskDateColumnTemplate" },
    { key: "Title", value: "taskTitleColumnTemplate" },
    //{ key: "Title", value: "taskTitleRowTemplate" },
    { key: "RelatedTo", value: "relatedTaskToLookupTemplate" },
    { key: "ModifiedDate", value: "updatedDateTemplate" },
];

export const MyTeamTaskGridColumnTemplates: GridColumnTemplates[] = [
    { key: "TaskStatus", value: "taskStatusTemplate" },
    { key: "TaskDate", value: "taskDateColumnTemplate" },
    { key: "CompletedDate", value: "taskDateColumnTemplate" },
    { key: "Title", value: "taskTitleWithReassigneeColumnTemplate" },
    //{ key: "Title", value: "taskTitleWithReassigneeRowTemplate" },
    { key: "RelatedTo", value: "relatedTaskToLookupTemplate" },
    { key: "ModifiedDate", value: "updatedDateTemplate" },
];


export const taskStatusTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    if (isNotNullAndUndefined(fieldValue)) {
        return (
            <div className="gridDefault d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center noRowclick w-100">
                    <div className="gridTempleteTextOuter">
                        <span className="ms-2" style={{ cursor: "pointer" }}>
                            {fieldValue}
                        </span>
                    </div>
                </div>
                {showEdit === true && (
                    <div className="gridEditBtn noRowclick">
                        <ButtonComponent
                            type="button"
                            className="e-flat btndefault-icon e-editbutton"
                            iconCss="fal fa-pencil"
                        />
                    </div>
                )}
            </div>
        );
    }
    return;
};


export const taskDateColumnTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    // const fieldValue = (
    //   isNotNullAndUndefined(data[data.column.field]) && data[data.column.field]
    // ).toString();
    let taskRow: TaskRow = data as TaskRow;
    let priorityColorCode;
    if (taskRow.Priority === "High") {
        priorityColorCode = "#e03e2d";
    } else if (taskRow.Priority === "Medium") {
        priorityColorCode = "#e67e23";
    } else if (taskRow.Priority === "Low") {
        priorityColorCode = "#3598db";
    }
    const loggedinUserId = msalInstance.currentUserId;
    //let taskDueDate = isNotNullAndUndefined(taskRow.TaskDate) ? moment(new Date(taskRow.TaskDate )).format("DD/MM/YYYY").toString() : 'No Date';

    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
        isNotNullAndUndefined(taskRow) ? taskRow.TaskDate : null,
        isNotNullAndUndefined(taskRow) ? taskRow.TaskType : null
    );
    let taskDate = taskDisplayDateAndBadge.taskDate;
    let dateBadge = taskDisplayDateAndBadge.dateBadge;

    // if (taskRow.TaskDate !== null) {
    //   const objTaskDate: Date = convertUTCDateToLocalTimezone(taskRow.TaskDate);
    //   if (Constants.MaxDate.valueOf() === objTaskDate.valueOf()) {
    //     dateBadge = "grey lighten-1 badge-grey";
    //     taskDate = "Unplanned";
    //   } else {
    //     taskDate = getDate(objTaskDate, "MMM DD");

    //     /*if (moment(objTaskDate).isSame(moment(Constants.MaxDate), "day")) {
    //                                         dateBadge = 'danger badge-danger';
    //                                         taskDate = 'ASAP';
    //                                     }*/
    //     if (moment(objTaskDate).isAfter(new Date(), "day")) {
    //       dateBadge = "badge-info lighten-1";
    //     } else if (moment(objTaskDate).isBefore(new Date(), "day")) {
    //       dateBadge = "danger badge-danger";
    //     } else if (moment(objTaskDate).isSame(new Date(), "day")) {
    //       dateBadge = "orange lighten-1 badge-orange";
    //     }
    //   }
    // } else {
    //   dateBadge = "grey lighten-1 badge-grey";
    //   //taskDate = 'Queued';
    //   taskDate = "Unplanned";
    // }

    return (
        <div
            className={
                !BrowserIsDevice
                    ? "d-flex justify-content-between mx-2"
                    : "d-flex justify-content-between"
            }
        >
            {taskRow.TaskStatus === RAFActionStatus.Planned &&
                taskRow.AssigneeUID === loggedinUserId ? (
                <div className="md-checkbox ms-2 e-info d-flex align-items-center mt-0 completeTask">
                    <input
                        type="checkbox"
                        className=""
                        id={"Task_" + taskRow.UID}
                        name={"Task_" + taskRow.UID}
                        style={{ cursor: "pointer" }}
                    />
                    <label
                        className=""
                        htmlFor={"Task_" + taskRow.UID}
                        style={{ cursor: "pointer" }}
                    ></label>
                </div>
            ) : null}
            <div className="text-nowrap row g-2 align-items-center flex-nowrap w-100">
                {/* <span className={taskRow.TaskStatus !== RAFActionStatus.Planned ? "hide-td-child-div activityDateSpanSection badge grey lighten-1 badge-grey" : "hide-td-child-div activityDateSpanSection badge " + dateBadge} >
                                {taskDate}</span>
                            <span className={taskRow.TaskStatus !== RAFActionStatus.Planned ? "activityDateSpanSection badge badge-grey hover-td-child-div e-editbutton" : "e-editbutton hover-td-child-div activityDateSpanSection badge " + dateBadge}>
                                <span className="e-icons e-Edit text-white pe-1" style={{ fontSize: "12px" }}></span>
                                Edit</span>
                                {taskRow.TaskStatus === RAFActionStatus.Planned && taskRow.TaskType === RAFTaskType.ToDo && taskRow.AssigneeUID === loggedinUserId &&
                                <div className="e-badge e-badge-grey hover-td-child-div px-2 e-completebutton" style={{paddingTop: "7px", paddingBottom: "6px" }}>
                                <div className='row g-1'>
                                    <div className='col-auto'>
                                <span className="e-icons e-check" style={{ fontSize: "12px" }}></span>
    
                                    </div>
                                    <div className='col-auto'>
                                        <span>Complete</span>
                                    </div>
                                </div>
                                </div>
            } */}
                {/* <div className='col-auto'>
                                <span
                                    className={taskRow.TaskStatus === RAFActionStatus.Completed ?
                                        "activityDateSpanSection badge badge-success"
                                        :
                                        taskRow.TaskStatus === RAFActionStatus.Planned && taskRow.TaskType === RAFTaskType.ToDo && taskRow.AssigneeUID === loggedinUserId ?
                                            "hide-td-child-div activityDateSpanSection badge " + dateBadge
                                            :
                                            "activityDateSpanSection badge " + dateBadge} >
                                    {taskDate}</span>
                                {taskRow.TaskStatus === RAFActionStatus.Planned && taskRow.TaskType === RAFTaskType.ToDo && taskRow.AssigneeUID === loggedinUserId &&
                                    < span className="e-completebutton align-items-center hover-td-child-div-flex activityDateSpanSection remove-clip-path badge badge-success">
                                        Complete
                                    </span>
                                }
                            </div> */}
                <div className="col-auto d-flex">
                    {taskRow.TaskStatus === RAFActionStatus.Completed ? (
                        <span className="activityDateSpanSection badge badge-completed">
                            Completed
                        </span>
                    ) : (
                        <span className={"activityDateSpanSection badge " + dateBadge}>
                            {taskDate}
                        </span>
                    )}
                </div>
                {/* {isNotNullAndUndefined(taskRow.TaskType) ? (
                <div
                  className={
                    BrowserIsDevice ? "col-auto ms-auto d-flex" : "col-auto d-flex"
                  }
                >
                  <span className={"ms-1 e-badge e-badge-lg e-badge-primary"}>
                    {taskRow.TaskType}
                  </span>
                </div>
              ) : (
                ""
              )} */}
                <div className="col-auto">
                    <div className="w-100">
                        <div className="row g-2">
                            {/* {taskRow.Priority !== null && (
                      <div className="col-auto">
                        <div
                          className="d-flex align-items-center"
                          title={taskRow.Priority}
                        >
                          <i
                            className="fas fa-flag"
                            style={{
                              color: priorityColorCode,
                              lineHeight: "normal",
                              fontSize: "10px",
                            }}
                          ></i>
                        </div>
                      </div>
                    )} */}
                            {taskRow.HasReminders === true && (
                                <div className="col-auto">
                                    <div className="d-flex align-items-center" title="Reminder">
                                        <i
                                            className="far fa-bell"
                                            style={{
                                                lineHeight: "normal",
                                                fontSize: "10px",
                                                color: "#0078D7",
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            )}
                            {!IsNullOrWhiteSpace(taskRow.CronExpression) &&
                                !IsNullOrWhiteSpace(taskRow.RRuleExpression) && (
                                    <div className="col-auto">
                                        <div className="d-flex align-items-center" title="Repeat">
                                            <i
                                                className="fas fa-repeat"
                                                style={{
                                                    lineHeight: "normal",
                                                    fontSize: "10px",
                                                    color: "#0078D7",
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {BrowserIsDevice && (
                    <div className="ms-auto col-auto">
                        <div className={"custom_key_value_outter"}>
                            <div
                                style={{
                                    background: "rgb(45, 194, 107)",
                                    color: "white",
                                }}
                                className="custom_key_value e-badge"
                            >
                                <span> {taskRow.TaskType} </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export const taskTitleColumnTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = !IsNullOrWhiteSpace(data[data.column.field])
        ? data[data.column.field].toString()
        : "NA";
    let taskRow: TaskRow = data as TaskRow;
    //let titleTooltipDescription = isNotNullAndUndefined(taskRow.Description) ? taskRow.Description : ""
    //let titleTooltipContent = '<h6>' + taskRow.Title + '</h6>' + '<p>' + titleTooltipDescription + '</p>';
    //let priorityChoiceList = item && item.ValueJson && item.ValueJson.find(x => x.DisplayName === fieldValue);
    //let priorityColorCode = priorityChoiceList && priorityChoiceList.ColorCode;
    return (
        <div className="w-100">
            <div className="row g-1 align-items-center flex-nowrap">
                {/* {isNotNullAndUndefined(taskRow.Priority) &&
                            <div className='col-auto'>
                            <div className="d-flex align-items-center" title={taskRow.Priority}>
                                {taskRow.Priority !== null && <i className="fa fa-flag" style={{ color: priorityColorCode, lineHeight: "normal", fontSize: '8px' }}></i>}
                            </div>
                            </div>
                        } */}
                <div className="col">
                    <span className="itemHeader text-truncate d-block">{fieldValue}</span>
                </div>
                <div className="col-auto d-none">
                    <span className={"ms-1 e-badge e-badge-primary"}>
                        {taskRow.TaskType}
                    </span>
                </div>
                {isNullOrUndefined(taskRow.AssigneeUID) &&
                    taskRow.TaskStatus !== RAFActionStatus.Completed && (
                        <div className="col-auto hover-td-child-div-flex">
                            <ButtonComponent
                                className="link-button unset-custom-button-md"
                                content="Assign"
                            //onClick={(e) => e.stopPropagation()}
                            ></ButtonComponent>
                        </div>
                    )}
                {isNotNullAndUndefined(taskRow.TaskType) &&
                    taskRow.TaskType === RAFTaskType.Approval && (
                        <div className="col-auto hover-td-child-div-flex">
                            <div
                                className="d-flex align-items-center"
                                title={taskRow.TaskType}
                            >
                                <i
                                    className="fas fa-user-check ms-1 me-2"
                                    style={{ lineHeight: "normal", fontSize: "12px" }}
                                ></i>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

export const taskTitleRowTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = !IsNullOrWhiteSpace(data[data.column.field])
        ? data[data.column.field].toString()
        : "NA";
    let taskRow: TaskRow = data as TaskRow;
    //let titleTooltipDescription = isNotNullAndUndefined(taskRow.Description) ? taskRow.Description : ""
    //let titleTooltipContent = '<h6>' + taskRow.Title + '</h6>' + '<p>' + titleTooltipDescription + '</p>';
    let priorityChoiceList =
        item &&
        item.ValueJson &&
        item.ValueJson.find((x) => x.DisplayName === fieldValue);
    let priorityColorCode = priorityChoiceList && priorityChoiceList.ColorCode;

    const loggedinUserId = msalInstance.currentUserId;
    let eFilterbar = document.querySelector(".e-filterbar");
    let eGridpager = document.querySelector(".e-gridpager");
    let taskTabContentOutter = document.querySelector("#taskTabContentOutter");
    let eGridcontent = document.querySelector(
        ".e-gridcontent"
    ) as HTMLElement | null;

    if (
        isNotNullAndUndefined(eFilterbar) &&
        isNotNullAndUndefined(taskTabContentOutter)
    ) {
        eFilterbar.classList.add("hidden");
        taskTabContentOutter.classList.add("filterSectionRemoved");
    }
    if (
        isNotNullAndUndefined(eGridpager) &&
        isNotNullAndUndefined(taskTabContentOutter) &&
        isNotNullAndUndefined(eGridcontent) &&
        isNotNullAndUndefined(eFilterbar)
    ) {
        let eGridpagerWidth = eGridpager.clientHeight;
        let eFilterbarWidth = eFilterbar.clientHeight;
        let contentHeight = eGridpagerWidth + eFilterbarWidth;
        //console.log("eGridpagerWidth", eGridpagerWidth);
        //console.log("eFilterbarWidth", eFilterbarWidth);
        eGridcontent.style.height = "calc(100% - " + contentHeight + "px)";
    }

    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
        isNotNullAndUndefined(taskRow) ? taskRow.TaskDate : null,
        isNotNullAndUndefined(taskRow) ? taskRow.TaskType : null
    );
    let taskDate = taskDisplayDateAndBadge.taskDate;
    let dateBadge = taskDisplayDateAndBadge.dateBadge;

    // if (taskRow.TaskDate !== null) {
    //   taskDate =
    //     isNotNullAndUndefined(taskRow) &&
    //       isNotNullAndUndefined(taskRow.CompletedDate)
    //       ? taskRow.CompletedDate
    //       : taskRow.TaskDate;
    //   const objTaskDate: Date = convertUTCDateToLocalTimezone(taskDate);
    //   if (Constants.MaxDate.valueOf() === objTaskDate.valueOf()) {
    //     dateBadge = "grey lighten-1 badge-grey";
    //     taskDate = "Unplanned";
    //   } else {
    //     taskDate = getDate(objTaskDate, "MMM DD");
    //     /*if (moment(objTaskDate).isSame(moment(Constants.MaxDate), "day")) {
    //                                         dateBadge = 'danger badge-danger';
    //                                         taskDate = 'ASAP';
    //                                     }*/
    //     if (moment(objTaskDate).isAfter(new Date(), "day")) {
    //       dateBadge = "badge-info lighten-1";
    //     } else if (moment(objTaskDate).isBefore(new Date(), "day")) {
    //       dateBadge = "danger badge-danger";
    //     } else if (moment(objTaskDate).isSame(new Date(), "day")) {
    //       dateBadge = "orange lighten-1 badge-orange";
    //     }
    //   }
    // } else {
    //   dateBadge = "grey lighten-1 badge-grey";
    //   //taskDate = 'Queued';
    //   taskDate = "Unplanned";
    // }

    let iconName = "";
    let text = "";
    let avatarClassName = "";

    switch (taskRow.RelatedToType) {
        case "contact":
            iconName = "address-card";
            text = "Contact";
            avatarClassName = "contact-avatar";
            break;
        case "account":
            iconName = "building";
            text = "Company";
            avatarClassName = "company-avatar";
            break;
        case "deal":
            iconName = "circle-dollar-to-slot";
            text = "Deal";
            avatarClassName = "deal-avatar";
            break;
        case "ticket":
            iconName = "ticket-alt";
            text = "Ticket";
            avatarClassName = "ticket-avatar";
            break;
        case "business_process":
            iconName = "server";
            text = "Process";
            avatarClassName = "bp-avatar avatar-text";
            break;
        case "complaint":
            iconName = "exclamation-triangle";
            text = "Complaint";
            avatarClassName = "company-avatar";
            break;
        default:
            iconName = "";
            text = "Not set";
            avatarClassName = "";
            break;
    }

    let relatedTo = (
        <div>
            <div
                className="d-flex align-items-center"
                style={{ marginBottom: "2px" }}
            >
                <TooltipComponent content={text} position="BottomCenter">
                    {iconName !== "" ? (
                        <span
                            className={
                                "avatar-text-circle transparent-avatar " + avatarClassName
                            }
                            style={{
                                width: "auto",
                                height: "auto",
                                minWidth: "auto",
                                maxWidth: "auto",
                                fontSize: "14px",
                                lineHeight: "1rem",
                            }}
                        >
                            <i className={"fa fa-" + iconName + " "}></i>
                        </span>
                    ) : (
                        <span
                            className={"avatar-text-circle default-avatar transparent-avatar"}
                            style={{
                                background: "#bdbdbd",
                                width: "auto",
                                height: "auto",
                                minWidth: "auto",
                                maxWidth: "auto",
                                fontSize: "14px",
                                lineHeight: "1rem",
                            }}
                        >
                            <span>N</span>
                        </span>
                    )}
                </TooltipComponent>
            </div>
        </div>
    );

    const createdBy = data["CreatedBy"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];

    return (
        <div className="row g-2 align-items-center">
            <div className="col-auto">
                <div className="d-flex justify-content-between">
                    {taskRow.TaskStatus === RAFActionStatus.Planned &&
                        taskRow.AssigneeUID === loggedinUserId ? (
                        <div className="md-checkbox ms-2 e-info d-flex align-items-center mt-0 completeTask">
                            <input
                                type="checkbox"
                                className=""
                                id={"Task_" + taskRow.UID}
                                name={"Task_" + taskRow.UID}
                                style={{ cursor: "pointer" }}
                            />
                            <label
                                className=""
                                htmlFor={"Task_" + taskRow.UID}
                                style={{ cursor: "pointer" }}
                            ></label>
                        </div>
                    ) : null}
                    <div className="text-nowrap d-flex align-items-center">
                        {/* <span className={taskRow.TaskStatus !== RAFActionStatus.Planned && taskRow.TaskStatus !== RAFActionStatus.Completed ? "hide-td-child-div activityDateSpanSection badge grey lighten-1 badge-grey" : taskRow.TaskStatus === RAFActionStatus.Completed ? "activityDateSpanSection badge badge-success" :  "hide-td-child-div activityDateSpanSection badge " + dateBadge} >
                                {taskDate}</span> */}
                        {/* <span className={taskRow.TaskStatus === RAFActionStatus.Completed ? "hidden" : taskRow.TaskStatus !== RAFActionStatus.Planned ? "activityDateSpanSection badge badge-grey hover-td-child-div e-editbutton" : "e-editbutton hover-td-child-div activityDateSpanSection badge " + dateBadge}>
                                <span className="e-icons e-Edit text-white pe-1" style={{ fontSize: "12px" }}></span>
                                Edit</span> */}
                        <span
                            className={
                                taskRow.TaskStatus === RAFActionStatus.Completed
                                    ? "activityDateSpanSection badge badge-success"
                                    : taskRow.TaskStatus === RAFActionStatus.Planned &&
                                        taskRow.TaskType === RAFTaskType.ToDo &&
                                        taskRow.AssigneeUID === loggedinUserId
                                        ? "hide-td-child-div activityDateSpanSection badge " +
                                        dateBadge
                                        : "activityDateSpanSection badge " + dateBadge
                            }
                        >
                            {taskDate}
                        </span>
                        {taskRow.TaskStatus === RAFActionStatus.Planned &&
                            taskRow.TaskType === RAFTaskType.ToDo && (
                                <span className="e-completebutton hover-td-child-div activityDateSpanSection badge badge-success">
                                    Complete
                                </span>
                            )}
                        {/* {taskRow.TaskStatus === RAFActionStatus.Planned && taskRow.TaskType === RAFTaskType.ToDo && taskRow.AssigneeUID === loggedinUserId &&
                                <div className="e-badge e-badge-grey hover-td-child-div px-2 e-completebutton" style={{paddingTop: "7px", paddingBottom: "6px" }}>
                                <div className='row g-1'>
                                    <div className='col-auto'>
                                <span className="e-icons e-check" style={{ fontSize: "12px" }}></span>
    
                                    </div>
                                    <div className='col-auto'>
                                        <span>Complete</span>
                                    </div>
                                </div>
                                </div>
            } */}
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="row g-2">
                    <div className="col-12">
                        <div className="row g-1 align-items-center">
                            {isNotNullAndUndefined(taskRow.Priority) && (
                                <div className="col-auto">
                                    <div title={taskRow.Priority}>
                                        {taskRow.Priority !== null && (
                                            <i
                                                className="fa fa-flag"
                                                style={{
                                                    color: priorityColorCode,
                                                    lineHeight: "normal",
                                                    fontSize: "8px",
                                                }}
                                            ></i>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="col">
                                <span className="itemHeader ecllipseFirstLine">
                                    {fieldValue}
                                </span>
                            </div>
                        </div>
                    </div>
                    {isNotNullAndUndefined(taskRow.RelatedTo) && (
                        <div className="col-auto">
                            <div className="raf-relatedTo">
                                <div className="d-flex align-items-center">
                                    <div className="row g-1">
                                        <div className="col-auto">{relatedTo}</div>
                                        <div className="col-auto">
                                            {isNotNullAndUndefined(taskRow.RelatedTo) ? (
                                                <div>
                                                    <span className="swapTextwithHyperlink pe-2 me-2 ecllipseFirstLine">
                                                        {taskRow.RelatedTo}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className="swapTextwithHyperlink pe-2 me-2 ecllipseFirstLine">
                                                        Not set
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {taskRow &&
                        taskRow.Assignee &&
                        taskRow.Assignee !== msalInstance.currentUserName && (
                            <div className="col-auto">
                                <div className="row g-2">
                                    <div className="col-auto">
                                        <div
                                            className={
                                                "avatar-text-circle default-avatar transparent-avatar"
                                            }
                                            style={{
                                                background: "#bdbdbd",
                                                width: "auto",
                                                height: "auto",
                                                minWidth: "auto",
                                                maxWidth: "auto",
                                                fontSize: "14px",
                                                lineHeight: "1rem",
                                            }}
                                        >
                                            <span className="fas fa-user"></span>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <span className="swapTextwithHyperlink">
                                            {taskRow.Assignee}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    {taskRow && taskRow.AssignTeam && (
                        <div className="col-auto">
                            <div className="row g-2">
                                <div className="col-auto">
                                    <div
                                        className={
                                            "avatar-text-circle default-avatar transparent-avatar"
                                        }
                                        style={{
                                            background: "#bdbdbd",
                                            width: "auto",
                                            height: "auto",
                                            minWidth: "auto",
                                            maxWidth: "auto",
                                            fontSize: "14px",
                                            lineHeight: "1rem",
                                        }}
                                    >
                                        <span className="fas fa-users"></span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <span className="swapTextwithHyperlink">
                                        {taskRow.AssignTeam}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-auto">
                <div className="row g-2 align-items-center">
                    {isNotNullAndUndefined(taskRow.TaskType) &&
                        taskRow.TaskType === RAFTaskType.Approval && (
                            <div className="col-auto">
                                <div
                                    className="d-flex align-items-center"
                                    title={taskRow.TaskType}
                                >
                                    <i
                                        className="fas fa-user-check ms-1 me-2"
                                        style={{ lineHeight: "normal", fontSize: "12px" }}
                                    ></i>
                                </div>
                            </div>
                        )}
                    <div className="col-auto">
                        <div
                            className="gridDefault d-flex align-items-center justify-content-between"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="recordInfomsg">
                                <RAFRecordInfo
                                    createdBy={createdBy}
                                    createdDate={createdDate}
                                    modifiedDate={modifiedDate}
                                    modifiedBy={modifiedBy}
                                    lastActivityDate={lastActivityDate}
                                    content=""
                                ></RAFRecordInfo>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const taskTitleWithReassigneeColumnTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = !IsNullOrWhiteSpace(data[data.column.field])
        ? data[data.column.field].toString()
        : "NA";
    let taskRow: TaskRow = data as TaskRow;
    //let titleTooltipDescription = isNotNullAndUndefined(taskRow.Description)? taskRow.Description: "";
    //let titleTooltipContent = '<h6>' + taskRow.Title + '</h6>' + '<p>' + titleTooltipDescription + '</p>';

    //let priorityChoiceList = item && item.ValueJson && item.ValueJson.find(x => x.DisplayName === fieldValue);
    //let priorityColorCode = priorityChoiceList && priorityChoiceList.ColorCode;

    return (
        <div className="d-flex justify-content-between">
            <div className="row g-1 align-items-center">
                {/* <div className='col-auto'>
                        {isNotNullAndUndefined(taskRow.Priority) &&
                            <div className="d-flex align-items-center" title={taskRow.Priority}>
                                {taskRow.Priority !== null && <i className="fa fa-flag" style={{ color: priorityColorCode, lineHeight: "normal", fontSize: '8px' }}></i>}
                            </div>
                        }
                        </div> */}
                <div className="col">
                    <span className="itemHeader ecllipseFirstLine">{fieldValue}</span>
                </div>
            </div>
            <div className="hover-td-child-div raf-reassignee">
                <div className="d-flex" id={"additional_btn_" + taskRow.UID}>
                    <TooltipComponent content="Assign To me" position="BottomCenter">
                        <ButtonComponent
                            type="button"
                            cssClass="ms-2 border-0 task-icon-btn"
                            iconCss="fas fa-user"
                        ></ButtonComponent>
                    </TooltipComponent>
                </div>
            </div>
            {isNotNullAndUndefined(taskRow.TaskType) &&
                taskRow.TaskType === RAFTaskType.Approval && (
                    <div className="d-flex align-items-center" title={taskRow.TaskType}>
                        <i
                            className="fas fa-user-check ms-1 me-2"
                            style={{ lineHeight: "normal", fontSize: "12px" }}
                        ></i>
                    </div>
                )}
        </div>
    );
};

export const taskTitleWithReassigneeRowTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldValue = !IsNullOrWhiteSpace(data[data.column.field])
        ? data[data.column.field].toString()
        : "NA";
    let taskRow: TaskRow = data as TaskRow;
    //let titleTooltipDescription = isNotNullAndUndefined(taskRow.Description) ? taskRow.Description : ""
    //let titleTooltipContent = '<h6>' + taskRow.Title + '</h6>' + '<p>' + titleTooltipDescription + '</p>';

    let priorityChoiceList =
        item &&
        item.ValueJson &&
        item.ValueJson.find((x) => x.DisplayName === fieldValue);
    let priorityColorCode = priorityChoiceList && priorityChoiceList.ColorCode;

    const loggedinUserId = msalInstance.currentUserId;
    let eFilterbar = document.querySelector(".e-filterbar");
    eFilterbar.classList.add("hidden");

    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
        isNotNullAndUndefined(taskRow) ? taskRow.TaskDate : null,
        isNotNullAndUndefined(taskRow) ? taskRow.TaskType : null
    );
    let taskDate = taskDisplayDateAndBadge.taskDate;
    let dateBadge = taskDisplayDateAndBadge.dateBadge;

    // if (taskRow.TaskDate !== null) {
    //   taskDate =
    //     isNotNullAndUndefined(taskRow) &&
    //       isNotNullAndUndefined(taskRow.CompletedDate)
    //       ? taskRow.CompletedDate
    //       : taskRow.TaskDate;
    //   const objTaskDate: Date = convertUTCDateToLocalTimezone(taskDate);
    //   if (Constants.MaxDate.valueOf() === objTaskDate.valueOf()) {
    //     dateBadge = "grey lighten-1 badge-grey";
    //     taskDate = "Unplanned";
    //   } else {
    //     taskDate = getDate(objTaskDate, "MMM DD");
    //     /*if (moment(objTaskDate).isSame(moment(Constants.MaxDate), "day")) {
    //                                         dateBadge = 'danger badge-danger';
    //                                         taskDate = 'ASAP';
    //                                     }*/
    //     if (moment(objTaskDate).isAfter(new Date(), "day")) {
    //       dateBadge = "badge-info lighten-1";
    //     } else if (moment(objTaskDate).isBefore(new Date(), "day")) {
    //       dateBadge = "danger badge-danger";
    //     } else if (moment(objTaskDate).isSame(new Date(), "day")) {
    //       dateBadge = "orange lighten-1 badge-orange";
    //     }
    //   }
    // } else {
    //   dateBadge = "grey lighten-1 badge-grey";
    //   //taskDate = 'Queued';
    //   taskDate = "Unplanned";
    // }

    let iconName = "";
    let text = "";
    let avatarClassName = "";

    switch (taskRow.RelatedToType) {
        case "contact":
            iconName = "address-card";
            text = "Contact";
            avatarClassName = "contact-avatar";
            break;
        case "account":
            iconName = "building";
            text = "Company";
            avatarClassName = "company-avatar";
            break;
        case "deal":
            iconName = "circle-dollar-to-slot";
            text = "Deal";
            avatarClassName = "deal-avatar";
            break;
        case "ticket":
            iconName = "ticket-alt";
            text = "Ticket";
            avatarClassName = "ticket-avatar";
            break;
        case "business_process":
            iconName = "server";
            text = "Process";
            avatarClassName = "bp-avatar avatar-text";
            break;
        case "complaint":
            iconName = "exclamation-triangle";
            text = "Complaint";
            avatarClassName = "company-avatar";
            break;
        default:
            iconName = "";
            text = "Not set";
            avatarClassName = "";
            break;
    }

    let relatedTo = (
        <div>
            <div
                className="d-flex align-items-center"
                style={{ marginBottom: "2px" }}
            >
                <TooltipComponent content={text} position="BottomCenter">
                    {iconName !== "" ? (
                        <span
                            className={
                                "avatar-text-circle transparent-avatar " + avatarClassName
                            }
                            style={{
                                width: "auto",
                                height: "auto",
                                minWidth: "auto",
                                maxWidth: "auto",
                                fontSize: "14px",
                                lineHeight: "1rem",
                            }}
                        >
                            <i className={"fa fa-" + iconName + " "}></i>
                        </span>
                    ) : (
                        <span
                            className={"avatar-text-circle default-avatar transparent-avatar"}
                            style={{
                                background: "#bdbdbd",
                                width: "auto",
                                height: "auto",
                                minWidth: "auto",
                                maxWidth: "auto",
                                fontSize: "14px",
                                lineHeight: "1rem",
                            }}
                        >
                            <span>N</span>
                        </span>
                    )}
                </TooltipComponent>
            </div>
        </div>
    );

    const createdBy = data["CreatedBy"];
    const createdDate = data["CreatedDate"];
    const modifiedDate = data["ModifiedDate"];
    const modifiedBy = data["ModifiedBy"];
    const lastActivityDate = data["LastActivityDate"];

    return (
        <div className="row g-2 align-items-center">
            <div className="col-auto">
                <div className="d-flex justify-content-between">
                    {taskRow.TaskStatus === RAFActionStatus.Planned &&
                        taskRow.AssigneeUID === loggedinUserId ? (
                        <div className="md-checkbox ms-2 e-info d-flex align-items-center mt-0 completeTask">
                            <input
                                type="checkbox"
                                className=""
                                id={"Task_" + taskRow.UID}
                                name={"Task_" + taskRow.UID}
                                style={{ cursor: "pointer" }}
                            />
                            <label
                                className=""
                                htmlFor={"Task_" + taskRow.UID}
                                style={{ cursor: "pointer" }}
                            ></label>
                        </div>
                    ) : null}
                    <div className="text-nowrap d-flex align-items-center">
                        <span
                            className={
                                taskRow.TaskStatus !== RAFActionStatus.Planned &&
                                    taskRow.TaskStatus !== RAFActionStatus.Completed
                                    ? "hide-td-child-div activityDateSpanSection badge grey lighten-1 badge-grey"
                                    : taskRow.TaskStatus === RAFActionStatus.Completed
                                        ? "activityDateSpanSection badge badge-success"
                                        : "hide-td-child-div activityDateSpanSection badge " +
                                        dateBadge
                            }
                        >
                            {taskDate}
                        </span>
                        <span
                            className={
                                taskRow.TaskStatus === RAFActionStatus.Completed
                                    ? "hidden"
                                    : taskRow.TaskStatus !== RAFActionStatus.Planned
                                        ? "activityDateSpanSection badge badge-grey hover-td-child-div e-editbutton"
                                        : "e-editbutton hover-td-child-div activityDateSpanSection badge " +
                                        dateBadge
                            }
                        >
                            <span
                                className="fal fa-pencil text-white pe-1"
                                style={{ fontSize: "12px" }}
                            ></span>
                            Edit
                        </span>
                        {/* {taskRow.TaskStatus === RAFActionStatus.Planned && taskRow.TaskType === RAFTaskType.ToDo && taskRow.AssigneeUID === loggedinUserId &&
                                <div className="e-badge e-badge-grey hover-td-child-div px-2 e-completebutton" style={{paddingTop: "7px", paddingBottom: "6px" }}>
                                <div className='row g-1'>
                                    <div className='col-auto'>
                                <span className="e-icons e-check" style={{ fontSize: "12px" }}></span>
    
                                    </div>
                                    <div className='col-auto'>
                                        <span>Complete</span>
                                    </div>
                                </div>
                                </div>
            } */}
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="row g-2">
                    <div className="col-12">
                        <span className="itemHeader ecllipseFirstLine">{fieldValue}</span>
                    </div>
                    {isNotNullAndUndefined(taskRow.RelatedTo) && (
                        <div className="col-auto">
                            <div className="raf-relatedTo">
                                <div className="d-flex align-items-center">
                                    <div className="row g-1">
                                        <div className="col-auto">{relatedTo}</div>
                                        <div className="col-auto">
                                            {isNotNullAndUndefined(taskRow.RelatedTo) ? (
                                                <div>
                                                    <span className="swapTextwithHyperlink pe-2 me-2 ecllipseFirstLine">
                                                        {taskRow.RelatedTo}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className="swapTextwithHyperlink pe-2 me-2 ecllipseFirstLine">
                                                        Not set
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-auto">
                        <div className="hover-td-child-div raf-reassignee">
                            <div className="d-flex" id={"additional_btn_" + taskRow.UID}>
                                <TooltipComponent
                                    content="Assign To me"
                                    position="BottomCenter"
                                >
                                    <ButtonComponent
                                        type="button"
                                        cssClass="ms-2 border-0 task-icon-btn"
                                        iconCss="fas fa-user"
                                    ></ButtonComponent>
                                </TooltipComponent>
                            </div>
                        </div>
                    </div>
                    {taskRow && taskRow.AssignTeam && (
                        <div className="col-auto">
                            <div className="row g-2">
                                <div className="col-auto">
                                    <div
                                        className={
                                            "avatar-text-circle default-avatar transparent-avatar"
                                        }
                                        style={{
                                            background: "#bdbdbd",
                                            width: "auto",
                                            height: "auto",
                                            minWidth: "auto",
                                            maxWidth: "auto",
                                            fontSize: "14px",
                                            lineHeight: "1rem",
                                        }}
                                    >
                                        <span className="fas fa-users"></span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <span className="swapTextwithHyperlink">
                                        {taskRow.AssignTeam}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="col-auto">
                <div className="row g-2 align-items-center">
                    {isNotNullAndUndefined(taskRow.Priority) && (
                        <div className="col-auto">
                            <div
                                className="d-flex align-items-center"
                                title={taskRow.Priority}
                            >
                                {taskRow.Priority !== null && (
                                    <i
                                        className="fa fa-flag ms-1 me-2"
                                        style={{
                                            color: priorityColorCode,
                                            lineHeight: "normal",
                                            fontSize: "12px",
                                        }}
                                    ></i>
                                )}
                            </div>
                        </div>
                    )}
                    {isNotNullAndUndefined(taskRow.TaskType) &&
                        taskRow.TaskType === RAFTaskType.Approval && (
                            <div className="col-auto">
                                <div
                                    className="d-flex align-items-center"
                                    title={taskRow.TaskType}
                                >
                                    <i
                                        className="fas fa-user-check ms-1 me-2"
                                        style={{ lineHeight: "normal", fontSize: "12px" }}
                                    ></i>
                                </div>
                            </div>
                        )}
                    <div className="col-auto">
                        <div
                            className="gridDefault d-flex align-items-center justify-content-between"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="recordInfomsg">
                                <RAFRecordInfo
                                    createdBy={createdBy}
                                    createdDate={createdDate}
                                    modifiedDate={modifiedDate}
                                    modifiedBy={modifiedBy}
                                    lastActivityDate={lastActivityDate}
                                    content=""
                                ></RAFRecordInfo>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const relatedTaskToLookupTemplate = (
    moduleName: string,
    allowEditing: boolean,
    showEdit: boolean,
    item: QueryAttributeJM,
    isDynamic: boolean,
    data,
    navigate: any,
    onActionMenuClicked: (data: any, selectedMenu: MenuEventArgs) => void
) => {
    const fieldName = data.column.field;
    const fieldValue = data[fieldName];
    let taskRow: TaskRow = data as TaskRow;
    let iconName = getModuleAvatarIcon(taskRow.RelatedToType);
    let text = getDisplayNameByModuleName(taskRow.RelatedToType);
    //let className = '';
    //let url = '';
    let avatarClassName = getModuleAvatarClass(taskRow.RelatedToType);

    if (
        IsNullOrWhiteSpace(iconName) ||
        IsNullOrWhiteSpace(text) ||
        IsNullOrWhiteSpace(avatarClassName)
    ) {
        switch (taskRow.RelatedToType) {
            case "contact":
                iconName = "address-card";
                text = "Contact";
                //className = 'contact-badge';
                avatarClassName = "contact-avatar";
                //url = '/Contact/View/';
                break;
            case "account":
                iconName = "building";
                text = "Company";
                //className = 'company-badge';
                avatarClassName = "company-avatar";
                //url = '/Company/View/';
                break;
            case "deal":
                iconName = "circle-dollar-to-slot";
                text = "Deal";
                //className = 'deal-badge';
                avatarClassName = "deal-avatar";
                //url = '/Deal/View/';
                break;
            case "ticket":
                iconName = "ticket-alt";
                text = "Ticket";
                //className = 'ticket-badge';
                avatarClassName = "ticket-avatar";
                //url = '/Ticket/View/';
                break;
            case "business_process":
                iconName = "server";
                text = "Process";
                //className = 'ticket-badge';
                avatarClassName = "bp-avatar avatar-text";
                //url = '/Process/Manage/';
                break;
            case "complaint":
                iconName = "exclamation-triangle";
                text = "Complaint";
                //className = 'grey lighten-1 badge-grey';
                avatarClassName = "company-avatar";
                //url = '/Complaint/View/';
                break;
            default:
                iconName = "";
                text = "Not set";
                //className = 'grey lighten-1 badge-grey';
                avatarClassName = "";
                //url = '';
                break;
        }
    }

    let relatedTo = (
        <div>
            <div
                className="d-flex align-items-center"
                style={{ marginBottom: "2px" }}
            >
                <TooltipComponent content={text} position="BottomCenter">
                    {iconName !== "" ? (
                        <div className="d-flex align-items-center">
                            <span
                                className={
                                    "avatar-text-circle transparent-avatar " + avatarClassName
                                }
                                style={{
                                    width: "auto",
                                    height: "auto",
                                    minWidth: "auto",
                                    maxWidth: "auto",
                                    fontSize: "10px",
                                }}
                            >
                                <i className={"fa fa-" + iconName + " "}></i>
                            </span>
                            <div className="secondary-text ps-1 text-uppercase">
                                <small>{text}</small>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center">
                            <span
                                className={
                                    "avatar-text-circle default-avatar transparent-avatar"
                                }
                                style={{
                                    background: "#bdbdbd",
                                    width: "10px",
                                    height: "10px",
                                    minWidth: "10px",
                                    maxWidth: "10px",
                                    fontSize: "9px",
                                }}
                            >
                                <span>N</span>
                            </span>
                            <label className="form-label">{"Notset"}</label>
                        </div>
                    )}
                </TooltipComponent>
            </div>
        </div>
    );
    let valueUID = taskRow.RelatedToUID;

    if (isNotNullAndUndefined(valueUID)) {
        return (
            <div
                className={
                    isNotNullAndUndefined(valueUID)
                        ? "gridDefault swapTextwithHyperlink raf-relatedTo noRowclick"
                        : "gridDefault swapTextwithHyperlink raf-relatedTo"
                }
                onClick={() =>
                    onLookupLinkClicked(
                        taskRow.RelatedToType,
                        valueUID,
                        isDynamic,
                        navigate
                    )
                }
            >
                <div className="w-100">
                    <div className="row g-0">
                        <div className="col overflow-auto">
                            <RAFRelatedToWithIcon
                                item={taskRow}
                                showTextAsLink
                            ></RAFRelatedToWithIcon>
                        </div>
                        <div className="col-auto">
                            <div
                                className="gridEditBtn noRowclick"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {!BrowserIsDevice && (
                                    <ButtonComponent
                                        type="button"
                                        className="grid-square-btn"
                                        iconCss="far fa-arrow-up-right-from-square"
                                        onClick={() =>
                                            onLookupLinkClicked(
                                                taskRow.RelatedToType,
                                                valueUID,
                                                isDynamic
                                            )
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row g-1 flex-nowrap">
                            <div className="col-auto">
                                <RAFIconImage
                                    iconCssClass={"fa fa-" + iconName}
                                    moduleavatar={avatarClassName + " avatar-text-square"}
                                    iconSize="28"
                                    fontSize="14"
                                ></RAFIconImage>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="activitiesContentDiv d-flex">
                                            <div className="secondary-text text-uppercase">
                                                <small>{text}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="swapTextwithHyperlink activitiesContentDiv d-flex">
                                            {isNotNullAndUndefined(taskRow.RelatedTo) ? (
                                                <span className="ecllipseFirstLine blue-text word-break-all text-wrap">
                                                    {taskRow.RelatedTo}
                                                </span>
                                            ) : (
                                                <div>
                                                    <span className="ecllipseFirstLine">Not set</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            </div>
        );
    }
    return;
};