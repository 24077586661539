import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  DialogUtility,
} from "@syncfusion/ej2-react-popups";
import {
  DropDownButtonComponent,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import * as R from "ramda";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { RRule } from "rrule";
import { msalInstance } from "../../..";
import ACLookupAutoComplete from "../../../components/shared/ACFormFields/ACLookupAutoComplete";
import ACTextBox from "../../../components/shared/ACFormFields/ACTextBox";
import { ACStorageKey } from "../../../constants/Common/ACConstants";
//import ACLoadingPanel from '../../../components/shared/ACLoadingPanel';
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFAutoCompleteMUITagInput from "../../../RAFComponents/Inputs/RAFAutoCompleteMUITagInput";
import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDatePickerCustom from "../../../RAFComponents/Inputs/RAFDatePickerCustom";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFFieldLabel from "../../../RAFComponents/Inputs/RAFFieldLabel";
import RAFForm, {
  Condition,
  ConditionIncludes,
  ConditionNotIncludes,
  WhenFieldChanges,
} from "../../../RAFComponents/Inputs/RAFForm";
import RAFNumber from "../../../RAFComponents/Inputs/RAFNumber";
import RAFRadioButtonList from "../../../RAFComponents/Inputs/RAFRadioButtonList";
import RAFStartDateEndDatePicker from "../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFTaskTagInput from "../../../RAFComponents/Inputs/RAFTaskTagInput";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import {
  getFormValue,
  setFormValue,
} from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailInlineEdit1 from "../../../RAFComponents/Navigation/RAFDetailInlineEdit1";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFRelatedToWithIcon from "../../../RAFComponents/Navigation/RAFRelatedToWithIcon";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { createInstance } from "../../../RAFComponents/Utility/FormUtility";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecordRemoveFocus,
  RetrieveRecord,
  getSessionStorage,
  hideProgress,
  isRAFMaxDate,
  isRAFMinDate,
  showProgress
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, {
  hasPermission,
} from "../../../RAFComponents/helpers/PermissionHelper";
import {
  getCollectionNameByModuleName,
  getURLNameByModuleName,
} from "../../../RAFComponents/helpers/RAFMenuHelper";
import {
  CalculateDate,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  convertUTCDateToLocalTimezone,
  getSaveRequest,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { LookUpGroupRow } from "../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { getEntityByName } from "../../../RAFMaster/helpers/RMutils";
import ACAutoCompleteMUI from "../../../components/shared/ACFormFields/ACAutoCompleteMUI";
import {
  BrowserIsDevice,
  Constants,
  ContentType,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
  RAFStatusNameWithColor,
  RAFTaskType,
} from "../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../constants/Common/PermissionConstants";
import { RAFEntityType } from "../../../constants/Common/RMConstants";
import { initUserAndTeam } from "../../../helpers/ACutils";
import { NavigateParamsProps, withRouter } from "../../../router";
import RAFCreate from "../../Common/Create";
import { MyTeamsContext } from "../../Common/Providers/MyTeamsContextProvider";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { RAFActivityAction } from "../Activity/ActivityHelper";
import { ActivityRow, RAFActivityCommentType } from "../Activity/ActivityRow";
import CreateActivitySummary from "../Activity/Controller/CreateActivitySummary";
import DocumentSummary from "../Document/DocumentSummary";
import RAFReminderDropdownWithAddBtn from "../Reminder/RAFReminderDropdownWithAddBtn";
import { ServiceTransactionRow } from "../ServiceTransaction/ServiceTransactionRow";
import UserProfilePhoto from "../User/UserProfilePhoto";
import { UserGroupRow } from "../UserGroup/UserGroupRow";
import RAFRepeatProcesssDropdown, {
  RepeatProcesssItems,
} from "./RAFRepeatProcesssDropdown";
import RAFUserTeamDropdown from "./RAFUserTeamDropdown";
import {
  getTaskDisplayDateAndBadge,
  getTaskDueDate,
  retrieveTaskWithReminders,
  saveTaskWithReminders,
} from "./TaskHelper";
import RAFRelatedLookupSelector from "./TaskInput/RAFRelatedLookupSelector";
import { TaskRow } from "./TaskRow";
import { TaskTitleRow } from "./TaskTitle/TaskTitleRow";

interface IProps {
  complete?: (taskRow: TaskRow, taskStatus: string) => void;
  completeNextTask?: (taskRow: TaskRow) => void;
  onSave?: (taskRow?: TaskRow) => void;
  onDelete?: (taskRow?: TaskRow) => void;
  isDashboard?: boolean;
  isEditTask?: boolean;
  isCreateTask?: boolean;
  taskUID?: string;
  contextModuleName?: string;
  onFocusClicked?: (relatedTo: string, relatedUID: string) => void;
  relatedCommentsUpdated?: (isRelatedCommentsUpdated?: boolean) => void;
  isDetailsMode?: boolean;
  secondaryRelatedToUID?: string;
  secondaryRelatedTo?: string;
  secondaryRelatedToType?: string;
  relatedToUID?: string;
  relatedTo?: string;
  relatedToType?: string;
  hideHeader?: boolean;
  onClose?: () => void;
  isActive?: boolean;
}

interface IState {
  isActive: boolean;
  taskRow: TaskRow;
  usersAndTeams: LookUpGroupRow[];
  taskUID: string;
  isEditTask?: boolean;
  updatedTaskRow?: TaskRow;
  documentSummaryKey?: number;
  activitySummaryKey?: number;
  taskDetailsSectionKey?: number;
  selectedManageTaskTabIndex: any;
  isLoading: boolean;
  showCompleteTaskAlertDialogContent: boolean;
  showRelatedToTaskDialogContent: boolean;
  relatedBPWidgetKey?: number;
  showAddToFocusListDialog?: boolean;
  selectedTabIndex?: number;
  relatedToContentKey?: number;
}

function ManageTask({
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & NavigateParamsProps>) {
  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const objGuid = isNotNullAndUndefined(props.taskUID)
    ? props.taskUID
    : props.isCreateTask
      ? "create"
      : "na";

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      isLoading: false,
      isEditTask: false,
      updatedTaskRow: null,
      taskRow: null,
      usersAndTeams: [],
      taskUID: isNotNullAndUndefined(props.taskUID) ? props.taskUID : null,
      documentSummaryKey: Math.random(),
      activitySummaryKey: Math.random(),
      taskDetailsSectionKey: Math.random(),
      selectedManageTaskTabIndex: {
        id: 0,
        text: "Details",
      },
      showAddToFocusListDialog: false,
      showCompleteTaskAlertDialogContent: false,
      showRelatedToTaskDialogContent: false,
      relatedBPWidgetKey: Math.random(),
      relatedToContentKey: Math.random(),
    }
  );

  let rafForm: FormRenderProps | null;
  let rafLookupSelectorForm: FormRenderProps | null;
  let submitButton: ButtonComponent;
  let animationSettings: AnimationSettingsModel;
  let deleteDialog: any;

  let updateTaskStatusAlertDialog: any;
  let myTeams: UserGroupRow[] = [];
  let contextModuleName: string = isNotNullAndUndefined(props.contextModuleName)
    ? props.contextModuleName
    : createInstance(TaskRow).getClassName();
  let moduleName: string = createInstance(TaskRow).getClassName();
  const currentTenantName = msalInstance.currentTenantName;

  async function checkScrollable() {
    const div = await document.querySelector("#raf_tab_header_manage_task");
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_manage_task_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_manage_task_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  useEffect(() => {
    if (isNotNullAndUndefined(props.taskUID)) {
      refresh(props.taskUID, props.isActive);
    } else {
      refresh(null, props.isActive ? props.isCreateTask : false);
    }
  }, [props.taskUID]);
  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (isNotNullAndUndefined(submitButton)) {
        submitButton.disabled = !g.valid;
      }
    }
  };

  const onSubmitTask = (value) => {
    let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
    saveTaskWithReminders(value).then((entityId) => {
      if (isNotNullAndUndefined(entityId)) {
        showSuccessToast("Saved successfully");
        hideProgress(progressDiv);

        if (props.onSave) {
          props.onSave(value);
        }
        onCloseDialogClick();
      } else {
        showWarningToast("Sorry unable to update the changes.");
        hideProgress(progressDiv);
      }
    });
  };

  const onSubmitRelatedToTask = (value: TaskRow) => {
    if (props.isCreateTask) {
      setFormValue(
        rafForm,
        propertyOf<TaskRow>("RelatedToUID"),
        value.RelatedToUID
      );
      setFormValue(rafForm, propertyOf<TaskRow>("RelatedTo"), value.RelatedTo);
      setFormValue(
        rafForm,
        propertyOf<TaskRow>("RelatedToType"),
        value.RelatedToType
      );
      closeRelatedToTaskDialog();
    } else {
      let progressDiv = showProgress("#RelatedToDialogContent");
      saveTaskWithReminders(value).then((entityId) => {
        if (isNotNullAndUndefined(entityId)) {
          showSuccessToast("Saved successfully");
          hideProgress(progressDiv);

          if (props.onSave) {
            props.onSave(value);
          }
          setState({ showRelatedToTaskDialogContent: false, isActive: true });
          if (isNotNullAndUndefined(props.taskUID)) {
            refresh(props.taskUID, props.isActive);
          }
          // closeRelatedToTaskDialog();
        } else {
          showWarningToast("Sorry unable to update the changes.");
          hideProgress(progressDiv);
        }
      });
    }
  };

  const refresh = async (uid, isActive) => {
    if (isActive) {
      let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
      const [usersAndTeams, taskRow] = await Promise.all([
        initUserAndTeam(),
        retrieveTaskWithReminders(uid),
      ]);
      if (
        isNotNullAndUndefined(usersAndTeams) &&
        isNotNullAndUndefined(taskRow) &&
        isNotNullAndUndefined(taskRow.UID)
      ) {
        hideProgress(progressDiv);
        setState({
          usersAndTeams,
          taskRow,
          isActive,
          isEditTask:
            (isNotNullAndUndefined(taskRow.TaskStatus) &&
              taskRow.TaskStatus === "Completed") ||
              props.isEditTask === false
              ? false
              : true,
          documentSummaryKey: Math.random(),
          activitySummaryKey: Math.random(),
          relatedBPWidgetKey: Math.random(),
          taskDetailsSectionKey: Math.random(),
          selectedManageTaskTabIndex: {
            id: 0,
            text: "Details",
          },
        });
      } else if (
        isNotNullAndUndefined(usersAndTeams) &&
        props.isCreateTask === true
      ) {
        hideProgress(progressDiv);
        let taskRow: TaskRow = new TaskRow();
        taskRow.TaskStatus = RAFActionStatus.Planned;
        taskRow.Priority = "Low";
        /*if (isNotNullAndUndefined(props.account)) {
                                                                                    taskRow.RelatedToType = "account";
                                                                                } else if (isNotNullAndUndefined(props.contact)){
                                                                                    taskRow.RelatedToType = "contact";
                                                                                }*/

        // let moduleName = isNotNullAndUndefined(props.moduleName)
        //   ? props.moduleName
        //   : null;

        taskRow.RelatedToType = isNotNullAndUndefined(props.relatedToType)
          ? props.relatedToType
          : null;

        taskRow.RelatedToUID = isNotNullAndUndefined(props.relatedToUID)
          ? props.relatedToUID
          : null;
        taskRow.RelatedTo = isNotNullAndUndefined(props.relatedTo)
          ? props.relatedTo
          : null;

        taskRow.TaskType = taskRow.RelatedToType === "incident" ? RAFTaskType.Action : RAFTaskType.ToDo;

        taskRow.SecondaryRelatedTo = isNotNullAndUndefined(
          props.secondaryRelatedTo
        )
          ? props.secondaryRelatedTo
          : null;
        taskRow.SecondaryRelatedToUID = isNotNullAndUndefined(
          props.secondaryRelatedToUID
        )
          ? props.secondaryRelatedToUID
          : null;
        taskRow.SecondaryRelatedToType = isNotNullAndUndefined(
          props.secondaryRelatedToType
        )
          ? props.secondaryRelatedToType
          : null;
        taskRow.Assignee = msalInstance.currentUserName;
        taskRow.AssigneeUID = msalInstance.currentUserId;
        taskRow.RepeatProcesss = RepeatProcesssItems.Never;
        //taskRow.Reminders = getTaskRemindersRow(null);

        let taskDueDate = JSON.parse(
          getSessionStorage(ACStorageKey.task_DueDate, true)
        );
        if (isNotNullAndUndefined(taskDueDate)) {
          taskRow.TaskDate = CalculateDate(taskDueDate["id"]);
        } else {
          if (
            taskRow.TaskType === RAFTaskType.Event ||
            taskRow.TaskType === RAFTaskType.Appointment
          ) {
            taskRow.TaskDate = new Date();
          } else {
            taskRow.DueDate = "NoDate";
            taskRow.TaskDate = Constants.MaxDate;
          }
          //taskRow.TaskDate = moment().add(1, 'days').toDate();
        }

        setState({
          usersAndTeams: usersAndTeams,
          taskRow,
          isActive,
        });
      } else {
        hideProgress(progressDiv);
        setState({ taskRow: null, isActive: false });
      }
    } else {
      setState({ taskRow: null, isActive: false });
    }
  };

  const onModifyQuickDateSelected = (days, date) => {
    rafForm.form.mutators.setValue("TaskDate", date);
  };

  const DeleteTaskClicked = (taskRow: TaskRow) => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteTask.bind(this, taskRow) },
      showCloseIcon: false,
      title: " Delete Task",
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
      // cssClass: "alert-dialog",
    });
  };

  const DeleteTask = (taskRow: TaskRow) => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    // let progressDiv = showProgress(".alert-dialog");
    DeleteRecordRemoveFocus(taskRow.UID, moduleName).then((response) => {
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response)) {
        deleteDialog.hide();
        setState({ isActive: false });
        if (props.onDelete) {
          props.onDelete();
        } else if (props.onSave) {
          props.onSave();
        }
      }
    });
  };

  const onChangeTaskStatus = async (actionStatus: string) => {
    if (actionStatus === RAFActionStatus.Planned) {
      showReopenTaskAlert(actionStatus);
    } else if (actionStatus === RAFActionStatus.Completed) {
      const { taskRow } = state;
      let hasPermissionToComplete = true;
      if (taskRow.SecondaryRelatedToType === RAFEntityName.ServiceTransaction) {
        let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
        const serviceTransactionItem: ServiceTransactionRow =
          await RetrieveRecord(
            taskRow.SecondaryRelatedToUID,
            RAFEntityName.ServiceTransaction
          );
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(serviceTransactionItem)) {
          if (
            serviceTransactionItem.Status === RAFActionStatus.Unassigned ||
            serviceTransactionItem.Status === RAFActionStatus.Planned ||
            serviceTransactionItem.Status === RAFActionStatus.Scheduled
          ) {
            showWarningToast(
              "The task cannot be completed until the shift has started."
            );
            hasPermissionToComplete = false;
          }
        }
      }

      if (hasPermissionToComplete) {
        showCompleteTaskAlert(RAFActionStatus.Completed);
      }
    }
  };

  const showCompleteTaskAlert = (taskStatus: string) => {
    updateTaskStatusAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "No",
      },
      closeOnEscape: false,
      content: "Are you sure you want to complete this task?",
      okButton: {
        text: "Yes",
        click: onClickCompleteTask.bind(this, taskStatus),
      },
      showCloseIcon: false,
      title: "Complete Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  const showReopenTaskAlert = (taskStatus: string) => {
    updateTaskStatusAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "No",
        click: undoTaskStatusChange.bind(this, taskStatus),
      },
      closeOnEscape: false,
      content: "Are you sure you want to re-open this task?",
      okButton: {
        text: "Yes",
        click: reopenCurrentTask.bind(this, taskStatus),
      },
      showCloseIcon: false,
      title: "Re-open Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  };

  const undoTaskStatusChange = () => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();
    let { taskRow } = state;
    if (isNotNullAndUndefined(taskRow))
      setFormValue(rafForm, "TaskStatus", taskRow.TaskStatus);
  };

  const reopenCurrentTask = (taskStatus: string) => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();
    setFormValue(rafForm, "TaskStatus", taskStatus);
    setFormValue(rafForm, "CompletedDate", null);
    setFormValue(rafForm, "CompletedBy", null);
    setFormValue(rafForm, "CompletedByUID", null);
    rafForm && rafForm.form.submit();
  };

  const onClickCompleteTask = (taskStatus: string) => {
    updateTaskStatusAlertDialog && updateTaskStatusAlertDialog.hide();

    let taskFormRow: TaskRow = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(taskFormRow) &&
      isNotNullAndUndefined(taskFormRow.UID)
    ) {
      if (isNotNullAndUndefined(rafForm) && rafForm.dirty === true) {
        if (
          isNotNullAndUndefined(taskFormRow) &&
          isNotNullAndUndefined(taskFormRow.UID)
        ) {
          let isMyTeamExist = isNotNullAndUndefined(taskFormRow.AssignTeamUID)
            ? myTeams.find((x) => x.UID === taskFormRow.AssignTeamUID)
            : null;

          if (
            (isNotNullAndUndefined(taskFormRow.AssigneeUID) &&
              taskFormRow.AssigneeUID === msalInstance.currentUserId) ||
            (isNotNullAndUndefined(taskFormRow.AssignTeamUID) &&
              isNotNullAndUndefined(isMyTeamExist))
          ) {
            let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
            saveTaskWithReminders(taskFormRow).then((entityId) => {
              hideProgress(progressDiv);
              if (isNotNullAndUndefined(entityId)) {
                if (props.complete) props.complete(taskFormRow, taskStatus);
              }
            });
          } else {
            //ColmpleteTaskAlert(taskStatus);
            showCompleteTaskAlertDialog();
          }
        }
      } else {
        if (
          isNotNullAndUndefined(taskFormRow) &&
          isNotNullAndUndefined(taskFormRow.UID)
        ) {
          let isMyTeamExist = isNotNullAndUndefined(taskFormRow.AssignTeamUID)
            ? myTeams.find((x) => x.UID === taskFormRow.AssignTeamUID)
            : null;
          if (
            (isNotNullAndUndefined(taskFormRow.AssigneeUID) &&
              taskFormRow.AssigneeUID === msalInstance.currentUserId) ||
            (isNotNullAndUndefined(taskFormRow.AssignTeamUID) &&
              isNotNullAndUndefined(isMyTeamExist))
          ) {
            if (props.complete) props.complete(taskFormRow, taskStatus);
          } else {
            showCompleteTaskAlertDialog();
          }
        }
      }
    }
  };

  const completeTask = (taskStatus: string) => {
    let taskFormRow: TaskRow = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(taskFormRow) &&
      isNotNullAndUndefined(taskFormRow.UID)
    ) {
      if (isNotNullAndUndefined(rafForm) && rafForm.dirty === true) {
        let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
        saveTaskWithReminders(taskFormRow).then((entityId) => {
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(entityId)) {
            if (props.complete) props.complete(taskFormRow, taskStatus);
          }
        });
      } else {
        if (props.complete) props.complete(taskFormRow, taskStatus);
      }
    }
  };

  const showCompleteTaskAlertDialog = () => {
    setState({ showCompleteTaskAlertDialogContent: true });
  };

  const completeTaskAlertContent = () => {
    if (state.showCompleteTaskAlertDialogContent === true) {
      let taskFormRow: TaskRow = getFormValue(rafForm);
      let assignedTo = isNotNullAndUndefined(taskFormRow.Assignee)
        ? taskFormRow.Assignee
        : taskFormRow.AssignTeam;

      let completeFormInitialValue = {
        Action: "No",
        Message: null,
        Mentions: [],
      };
      return (
        <div className="px-2">
          <RAFForm
            initialValues={completeFormInitialValue}
            submitOnEnterKey={false}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={onSubmitCompleteTask}
          >
            <div className="e-dlg-body-content">
              <div className="row">
                <div className="col-auto">
                  <label>
                    This task is assigned to {assignedTo}. Do you want to
                    complete this task?
                  </label>
                </div>
                <div className="col">
                  <RAFRadioButtonList
                    field="Action"
                    label="Complete ?"
                    showLabel={false}
                    labelClassName="col-auto"
                    inputFieldClassName="col radio-btn-m-0"
                    formGroupClassName="mb-0"
                    uitype="customButton"
                    radioBtnClassName="col-auto"
                  >
                    <RAFChoiceOption
                      label="Yes"
                      value={"Yes"}
                    ></RAFChoiceOption>
                    <RAFChoiceOption label="No" value={"No"}></RAFChoiceOption>
                  </RAFRadioButtonList>
                </div>
                <WhenFieldChanges field={"Action"} set={"Message"} to={null} />
                <WhenFieldChanges field={"Action"} set={"Mentions"} to={null} />
                <Condition when="Action" is="Yes">
                  <div className="w-100" id="actionTextAreaDiv">
                    <RAFTextArea<ActivityRow>
                      field="Message"
                      label="Add Reason"
                      required
                      placeholder="Add notes, comments, or @ to mention a person"
                      rows={5}
                      onInputs={(e) => { }}
                      onChanged={(e) => { }}
                      useMentions
                      mentionsField="Mentions"
                    ></RAFTextArea>
                  </div>
                </Condition>
              </div>
            </div>
            <div className="e-dlg-footerContent ">
              <ButtonComponent className="me-2" cssClass="e-flat e-info">
                OK
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat"
                onClick={() => showCompleteTaskAlertDialogClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const onSubmitCompleteTask = (value) => {
    let taskFormRow: TaskRow = getFormValue(rafForm);
    if (
      isNotNullAndUndefined(value) &&
      value.Action === "Yes" &&
      isNotNullAndUndefined(taskFormRow)
    ) {
      let progressDiv = showProgress("#completeTaskAlertDialogContent");

      let activityRow: ActivityRow = new ActivityRow();
      activityRow.UserUID = msalInstance.currentUserId;
      activityRow.UserName = msalInstance.currentUserName;

      activityRow.RelatedToType = taskFormRow.RelatedToType;
      activityRow.IsSystem = false;
      activityRow.RelatedToUID = isNotNullAndUndefined(taskFormRow.RelatedToUID)
        ? taskFormRow.RelatedToUID
        : "";
      activityRow.RelatedTo = isNotNullAndUndefined(taskFormRow.RelatedTo)
        ? taskFormRow.RelatedTo
        : "";

      activityRow.SecondaryRelatedTo = taskFormRow.Title;
      activityRow.SecondaryRelatedToType = RAFEntityName.Task;
      activityRow.SecondaryRelatedToUID = taskFormRow.UID;

      activityRow.Mentions = value.Mentions;
      activityRow.Message = value.Message;
      activityRow.CommentType = RAFActivityCommentType.Reason;
      activityRow.Action = RAFActivityAction.Added;

      repositoryActions
        .postDataAndGetResponse(
          "Activity/Save",
          //"Activity/SaveStream",
          getSaveRequest(activityRow, null),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            completeTask(RAFActionStatus.Completed);
            // updateactivitySummaryKey();
            setTimeout(() => {
              showCompleteTaskAlertDialogClose();
            }, 200);
          } else {
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    } else {
      showCompleteTaskAlertDialogClose();
    }
  };

  const showCompleteTaskAlertDialogClose = () => {
    setState({ showCompleteTaskAlertDialogContent: false });
  };

  const clearTaskRelatedTo = () => {
    rafLookupSelectorForm.form.mutators.setValue("RelatedTo", null);
    rafLookupSelectorForm.form.mutators.setValue("RelatedToUID", null);
    rafLookupSelectorForm.form.mutators.setValue("RelatedToType", null);
  };

  //showRelatedToTaskDialogContent start
  const openRelatedToTaskDialog = () => {
    setState({ showRelatedToTaskDialogContent: true });
  };
  const closeRelatedToTaskDialog = () => {
    setState({ showRelatedToTaskDialogContent: false });
  };

  const relatedToTaskDialogContent = () => {
    if (state.showRelatedToTaskDialogContent === true) {
      const taskRow = isNotNullAndUndefined(rafForm)
        ? getFormValue(rafForm)
        : state.taskRow;
      return (
        <div className="px-2">
          <RAFForm
            className="h-100"
            type={TaskRow}
            initialValues={taskRow}
            formRef={(g) => {
              enableSubmitButton(g);
              return (rafLookupSelectorForm = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            //primaryKey={taskRow.UID}
            onSubmit={onSubmitRelatedToTask}
            submitOnEnterKey={false}
          >
            <div className="e-dlg-body-content">
              <RAFRelatedLookupSelector
                field="RelatedTo"
                relatedField="RelatedToType"
                moduleName={moduleName}
                enableFloatingPopup={false}
              ></RAFRelatedLookupSelector>
            </div>
            <div className="e-dlg-footerContent justify-content-start">
              <RAFButtonComponent
                action={RAFButtonConstant.Clear}
                onClick={() => clearTaskRelatedTo()}
                idString={taskRow.UID}
                showIcon={false}
              />
              <RAFButtonComponent
                action={RAFButtonConstant.Cancel}
                onClick={() => closeRelatedToTaskDialog()}
                idString={taskRow.UID}
                showIcon={false}
                className="ms-auto me-2"
              />
              <RAFPermissionRender
                permissionName={
                  props.isCreateTask === true
                    ? PermissionConstants.TaskCreate
                    : PermissionConstants.TaskUpdate
                }
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Save}
                  type="submit"
                  isPrimary
                  idString={taskRow.UID}
                  showIcon={false}
                />
              </RAFPermissionRender>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  //showRelatedToTaskDialogContent end

  const onMoreMenuClicked = (args: MenuEventArgs) => {
    let taskRow: TaskRow = state.taskRow as TaskRow;
    if (args.item.id === "focuslist") {
      onFocusClicked();
    }
    if (args.item.id === "Delete") {
      DeleteTaskClicked(taskRow);
    }
    // if (args.item.id === "Edit") {
    //   toggleEditDetails();
    // }
  };

  // const toggleEditDetails = () => {
  //   if (
  //     (isNotNullAndUndefined(state.isEditTask) && state.isEditTask === true) ||
  //     (isNotNullAndUndefined(props.isCreateTask) && props.isCreateTask === true)
  //   ) {
  //     if (props.onClose) {
  //       setState({
  //         taskRow: null,
  //       });
  //       props.onClose();
  //     }
  //   } else {
  //     if (state.isEditTask === true) {
  //       setState({
  //         isEditTask: false,
  //         taskDetailsSectionKey: Math.random(),
  //       });
  //     } else {
  //       setState({
  //         isEditTask: true,
  //         taskDetailsSectionKey: Math.random(),
  //       });
  //     }
  //   }
  // };

  const onCloseDialogClick = () => {
    setTimeout(() => {
      if (props.onClose) {
        setState({
          taskRow: null,
        });
        props.onClose();
      }
    }, 100);
  };

  /*const onCloseDialogClick = () => {
      if (isNotNullAndUndefined(state.isEditTask) && state.isEditTask === true) {
        if (props.onClose) {
          props.onClose();
        }
      } else {
        if (isNotNullAndUndefined(state.updatedTaskRow)) {
          if (props.onSave) {
            props.onSave(state.updatedTaskRow);
          }
        } else {
          if (props.onClose) {
            props.onClose();
          }
        }
      }
    };
    */

  const onFocusClicked = () => {
    showAddToFocusListDialog();
  };

  const showAddToFocusListDialog = () => {
    setState({ showAddToFocusListDialog: true });
  };

  const closeAddToFocusListDialog = () => {
    setState({ showAddToFocusListDialog: false });
  };

  const updateactivitySummaryKey = () => {
    //setState({ activitySummaryKey: Math.random() });
    if (props.relatedCommentsUpdated) {
      props.relatedCommentsUpdated(true);
    }
  };

  const onChangeTaskDate = (value) => {
    let taskDueDate = getTaskDueDate(null, value);
    setFormValue(rafForm, "DueDate", taskDueDate);
  };

  const ManageTaskTabSelected = (selectedTabName) => {
    // let selectedTitle = selectedTabName.innerText;
    setState({ selectedManageTaskTabIndex: selectedTabName as any });
  };

  const functionforscroll = (x, id) => {
    let element = document.getElementById(id);
    if (isNotNullAndUndefined(element)) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    let scrollspyItems = document.getElementsByClassName("scrollspyItem");
    for (let i = 0; i < scrollspyItems.length; i++) {
      scrollspyItems[i].classList.remove("active");
    }
    x.currentTarget.classList.add("active");
  };

  const showEditableField = (fieldId?: string) => {
    let openEditDivs = isNotNullAndUndefined(document)
      ? document.querySelectorAll('div[id^="editable_"]')
      : null;

    if (isNotNullAndUndefined(openEditDivs)) {
      openEditDivs.forEach((x) => {
        x.classList.add("hidden");
      });
    }

    if (isNotNullAndUndefined(fieldId)) {
      let editDiv = document.querySelector("#editable_" + fieldId);
      if (isNotNullAndUndefined(editDiv)) {
        editDiv.classList.remove("hidden");
      }
      //if (isNotNullAndUndefined(valueDiv)) {
      //    valueDiv.classList.add('hidden');
      //}
    }
  };

  const tagsListJsonCustomContent = (fieldName) => {
    return (
      <div className="p-3">
        <RAFTaskTagInput<TaskRow>
          field="TagsListJson"
          label="Tags"
          showLabel={false}
          // showLabel={true}
          placeholder="Type and press enter to add a tag"
          showAddBtn
        />
      </div>
    );
  };

  const rRuleExpressionCustomContent = (fieldName) => {
    return (
      <div className="p-3">
        <RAFRepeatProcesssDropdown<TaskRow>
          field="RepeatProcesss"
          rRuleExpressionPropertyName={propertyOf<TaskRow>("RRuleExpression")}
          showLabel={false}
          label="Recurring"
          onChanged={() => {
            showEditableField();
          }}
        />
      </div>
    );
  };

  const RelatedToClick = async (objectid, contextModuleName, item) => {
    let entityName = getURLNameByModuleName(contextModuleName);
    if (
      isNotNullAndUndefined(contextModuleName) &&
      isNotNullAndUndefined(objectid)
    ) {
      let selectedEntity = await getEntityByName(contextModuleName);
      if (selectedEntity.EntityType === RAFEntityType.DataList) {
        if (
          entityName === RAFEntityName.Document ||
          entityName === RAFEntityName.ContentLibrary
        ) {
        } else {
          props.navigate("/" + entityName + "/view/" + objectid);
        }
      } else {
        props.navigate("/RAF/View/" + contextModuleName + "/" + objectid);
      }
    }
  };

  const renderCompletedTaskContent = (taskRow: TaskRow) => {
    const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
      taskRow.TaskDate,
      taskRow.TaskType,
      true
    );
    const taskDisplayDate = taskDisplayDateAndBadge.taskDueDate;

    return (
      <div className={"row gx-0 gy-3"}>
        <div className="col-12">
          <div className="row gy-2">
            <div className="col-12">
              <div className="gx-2 gy-1 row">
                <div className="col-auto">
                  <RAFDetailFieldCustom
                    value={
                      isNotNullAndUndefined(taskRow.TaskType)
                        ? taskRow.TaskType
                        : "Not set"
                    }
                    displayValue={taskRow.TaskType}
                    title="TaskType"
                    moduleName={RAFEntityName.Task}
                    isColorOption
                    field="TaskType"
                    mode="opacityColorView"
                    showLabel={false}
                    valueClassName="raf_badge raf_sm"
                  />
                </div>
                <div className="col-12 subtitle_2">
                  <span className="subtitle_2">{taskRow.Title}</span>
                </div>
              </div>
            </div>
            {isNotNullAndUndefined(taskRow.Description) ? (
              <div className="col-12">
                <CustomCardWidget cardContentClassName="p-2">
                  {taskRow.Description}
                </CustomCardWidget>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFDetailFieldCustom
            value={
              isNotNullAndUndefined(taskRow.TaskStatus)
                ? taskRow.TaskStatus
                : "Not set"
            }
            displayValue={taskRow.TaskStatus}
            title="TaskStatus"
            moduleName={RAFEntityName.Task}
            isColorOption
            field="TaskStatus"
            mode="textOnly"
            showLabel={true}
            // valueClassName="body_3_dark medium"
            // labelClassName="body_3_light"
            // labelDivClassName="body_3_light col-auto"
            // valueDivClassName="body_3_dark col-auto"
            rowClassName="gx-0 gy-1"
          />
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFDetailFieldCustom
            value={taskDisplayDate}
            title="Due date"
            colClassName="col-12"
            rowClassName="gy-1"
          //fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATETIME }}
          />
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFDetailFieldCustom
            value={taskRow.Assignee}
            title="Assigned to"
            colClassName="col-12"
            rowClassName="gy-1"
            customValue={
              <div>
                <div className="row gx-2">
                  <div className="col-auto">
                    <UserProfilePhoto
                      uid={taskRow.AssigneeUID}
                      relatedToType={RAFEntityName.User}
                      canEdit={false}
                      profileImgCss={"profileImg-container-xxsm"}
                      title={taskRow.Assignee ?? "Not Set"}
                      iconSize="16"
                      fontSize="8"
                      emptyAvatarType="Initial"
                    />
                  </div>
                  <div className="col">
                    <div className="details-value">{taskRow.Assignee}</div>
                  </div>
                </div>
              </div>
            }
          />
        </div>

        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <RAFFieldLabel
              field="RelatedTo"
              label="Related To"
              labelClassName="col-12"
            ></RAFFieldLabel>
            <div className="col-12">{renderRelatedToContent(taskRow)}</div>
          </div>
        </div>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-md-12">
          <RAFTaskTagInput<TaskRow>
            field="TagsListJson"
            label="Tags"
            showLabel={false}
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
            disabled
          />
        </div>
        {false && currentTenantName !== "skytrust" && (
          <div className="col-md-12">
            <RAFDetailInlineEdit1
              label="Recurring"
              fieldName="RRuleExpression"
              tooltipContent={rRuleExpressionCustomContent("RRuleExpression")}
              // labelClassName="col-3"
              // inputClassName="col-9"
              UiMode="floatingCustomDiv"
              valueUiMode="card"
              disabled
              customValue={
                <div className="d-flex align-items-baseline">
                  <i className="fas fa-timer me-2"></i>{" "}
                  <Field
                    name="RRuleExpression"
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                  >
                    {({ input }) => {
                      let RecurringValue =
                        isNotNullAndUndefined(input) &&
                          !IsNullOrWhiteSpace(input.value)
                          ? RRule.fromString(
                            input.value.slice(0, input.value.length - 1)
                          ).toText()
                          : "Never";
                      return (
                        <span className="text-wrap form-value">
                          {RecurringValue}
                        </span>
                      );
                    }}
                  </Field>
                </div>
              }
            />
          </div>
        )}

        {false && (
          <>
            {isNotNullAndUndefined(taskRow.Reminders) &&
              isNotNullAndUndefined(taskRow.Reminders) &&
              taskRow.Reminders.length > 0 ? (
              taskRow.Reminders.map((item, index) => {
                return (
                  <div className="col-12">
                    <RAFDetailFieldCustom
                      value={item.ReminderType}
                      title={"Reminders"}
                      colClassName="col-12"
                      rowClassName="gy-1"
                    />
                  </div>
                );
              })
            ) : (
              <div className="col-12">
                <RAFDetailFieldCustom
                  value={"Not set"}
                  title="Reminders"
                  colClassName="col-12"
                  rowClassName="gy-1"
                />
              </div>
            )}
          </>
        )}

        {/* <RAFDetailFieldEditable
          title="Type"
          disabled
          value={
            isNotNullAndUndefined(taskRow.Title) ? taskRow.TaskType : "Not set"
          }
          valueClassName="labelPrimartyText"
          type={RAFInputType.Text}
          name="Type"
          isColorOption
        ></RAFDetailFieldEditable> */}
        {/* <RAFDetailFieldEditable
          title="Title"
          disabled
          value={
            isNotNullAndUndefined(taskRow.Title) ? taskRow.Title : "Not set"
          }
          valueClassName="labelPrimartyText"
          type={RAFInputType.Text}
          name="Title"
        ></RAFDetailFieldEditable>
        <RAFDetailFieldEditable
          title="Due date"
          disabled
          value={
            isNotNullAndUndefined(taskRow.TaskDate) &&
              Constants.MaxDate.valueOf() !==
              convertUTCDateToLocalTimezone(taskRow.TaskDate).valueOf()
              ? taskRow.TaskDate
              : "Unplanned"
          }
          valueClassName="labelPrimartyText"
          type={
            isNotNullAndUndefined(taskRow.TaskDate) &&
              Constants.MaxDate.valueOf() !==
              convertUTCDateToLocalTimezone(taskRow.TaskDate).valueOf()
              ? RAFInputType.Date
              : RAFInputType.Text
          }
          name="TaskDate"
        ></RAFDetailFieldEditable>
        {isNotNullAndUndefined(taskRow.AssigneeUID) &&
          taskRow.AssigneeUID !== msalInstance.currentUserId && (
            <RAFDetailFieldEditable
              title="ASSIGNED TO"
              disabled
              value={
                isNotNullAndUndefined(taskRow.Assignee)
                  ? taskRow.Assignee
                  : "Not set"
              }
              valueClassName="labelPrimartyText"
              type={RAFInputType.Text}
              name="Assignee"
            ></RAFDetailFieldEditable>
          )}
        <div className="row">
          <div className="col-12">
            <RAFDetailFieldEditable
              title="Priority"
              disabled
              value={
                isNotNullAndUndefined(taskRow.Priority)
                  ? taskRow.Priority
                  : "Not set"
              }
              valueClassName="labelPrimartyText"
              type={RAFInputType.Text}
              name="Priority"
            ></RAFDetailFieldEditable>
          </div> */}
        {/* <div className="col-12">
                            <RAFDetailFieldEditable title="Tags" disabled
                                customValue={
                                    <div className='ms-2'>
                                        <RAFTagDetails
                                            initialTags={isNotNullAndUndefined(taskRow) ? taskRow.TagsListJson : null}
                                            relatedToUID={isNotNullAndUndefined(taskRow) ? taskRow.UID : null}
                                            moduleName={moduleName}
                                        //onSave={refreshOnUpdateAccount.bind(this)}
                                        />
                                    </div>
                                }
                                name="Tags"
                            ></RAFDetailFieldEditable>
                        </div> */}
        {/* <div className="col-12">
            <RAFDetailFieldEditable
              title="Recurring"
              disabled
              value={
                !IsNullOrWhiteSpace(taskRow.RRuleExpression)
                  ? RRule.fromString(
                    taskRow.RRuleExpression.slice(
                      0,
                      taskRow.RRuleExpression.length - 1
                    )
                  ).toText()
                  : "Never"
              }
              valueClassName="labelPrimartyText"
              type={RAFInputType.Text}
              name="CronExpression"
            ></RAFDetailFieldEditable>
          </div>
        </div> */}
        {/* <RAFDetailFieldEditable
            title="Related To"
            disabled
            customValue={
              <div className="mb-2">
                <RAFRelatedToWithIcon item={taskRow}></RAFRelatedToWithIcon>
              </div>
            }
            valueClassName="labelPrimartyText"
            type={RAFInputType.Text}
            name="RelatedTo"
          ></RAFDetailFieldEditable> */}
        {/* <RAFDetailFieldEditable
          title="Description"
          disabled
          value={
            isNotNullAndUndefined(taskRow.Description)
              ? taskRow.Description
              : "Not set"
          }
          valueClassName="labelPrimartyText"
          type={RAFInputType.Text}
          name="Description"
        ></RAFDetailFieldEditable>
        {isNotNullAndUndefined(taskRow.Reminders) &&
          isNotNullAndUndefined(taskRow.Reminders) &&
          taskRow.Reminders.length > 0 ? (
          taskRow.Reminders.map((item, index) => {
            return (
              <RAFDetailFieldEditable
                key={index}
                title={item.ReminderType}
                disabled
                value={item.RemindDate}
                valueClassName="labelPrimartyText"
                type={RAFInputType.Date}
                name="Reminders"
              ></RAFDetailFieldEditable>
            );
          })
        ) : (
          <RAFDetailFieldEditable
            title="Reminders"
            disabled
            value={"Not set"}
            valueClassName="labelPrimartyText"
            type={RAFInputType.Text}
            name="Reminders"
          ></RAFDetailFieldEditable>
        )} */}
      </div>
    );
  };

  const onChangeTaskTitle = async (label, value, taskUID: string) => {
    if (isNullOrUndefined(taskUID)) {
      if (isNotNullAndUndefined(value)) {
        let progressDiv = showProgress(`#manage_Task_outerDiv_${objGuid}`);
        let taskTitleRow: TaskTitleRow = await RetrieveRecord(
          value,
          RAFEntityName.TaskTitle
        );
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(taskTitleRow) &&
          isNotNullAndUndefined(taskTitleRow.UID)
        ) {
          setFormValue(rafForm, "TagsListJson", taskTitleRow.TagsListJson);
        } else {
          setFormValue(rafForm, "TagsListJson", []);
        }
      } else {
        setFormValue(rafForm, "TagsListJson", []);
      }
    }
  };

  const openFloatingPopupContent = (field) => {
    let floatingPopupContent = document.getElementById(
      `floatingCustomDiv${field}`
    );
    let btnFloatingPopupContent = document.getElementById(
      `btn_open_floatingCustomDiv_${field}`
    );
    floatingPopupContent.classList.remove("hidden");
    btnFloatingPopupContent.classList.add("hidden");
  };

  const onClickClearField = (field) => {
    setFormValue(rafForm, field, null);
  };

  const onClickCreateRepeatProcesss = () => {
    onClickClearField("RepeatProcesss");
    onClickClearField("RRuleExpression");
  };

  const onChangeTaskType = (value) => {
    if (value !== RAFTaskType.Event && value !== RAFTaskType.Appointment) {
      setFormValue(rafForm, propertyOf<TaskRow>("EndDate"), null);
    } else {
      const inputValue = getFormValue(rafForm, propertyOf<TaskRow>("TaskDate"));
      if (isNotNullAndUndefined(inputValue)) {
        const localTime = convertUTCDateToLocalTimezone(inputValue);
        if (
          isRAFMaxDate(inputValue, localTime) ||
          isRAFMinDate(inputValue, localTime)
        ) {
          const currentTime = new Date(); // roundToNearestInterval(new Date(), 15);
          setFormValue(rafForm, propertyOf<TaskRow>("TaskDate"), currentTime);
          const endDate = new Date();
          endDate.setHours(endDate.getHours() + 1);
          const newEndTime = endDate; // roundToNearestInterval(endDate, 15);
          setFormValue(rafForm, propertyOf<TaskRow>("EndDate"), newEndTime);

          const duration = getTimeDifferenceInMinutes(currentTime, newEndTime);
          setFormValue(rafForm, propertyOf<TaskRow>("Duration"), duration);
        } else {
          const endDate = new Date(inputValue);
          endDate.setHours(endDate.getHours() + 1);
          const newEndTime = endDate; // roundToNearestInterval(endDate, 15);
          setFormValue(rafForm, propertyOf<TaskRow>("EndDate"), newEndTime);

          const duration = getTimeDifferenceInMinutes(inputValue, newEndTime);
          setFormValue(rafForm, propertyOf<TaskRow>("Duration"), duration);
        }
      }
    }
  };

  const getTitleActionTypeActivityDropdown = () => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Task");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);

    let filter2: RAFCustomFilter = {};
    let filterVal2: string[] = [];
    filterVal2.push("Action");
    filter2.Operator = RAFCustomOperator.Equal;
    filter2.Value = filterVal2;
    filter2.Field = "Parent";
    customFilter.Rules.push(filter2);

    return (
      <ACAutoCompleteMUI
        label="Title"
        field="Title"
        placeholder="Select Title"
        showLabel
        showCreateButton={false}
        url="StandardizedLibrary/LookUp"
        moduleName={RAFEntityName.StandardizedLibrary}
        customFilter={customFilter}
        SearchCreateOptionMode={"Footer"}
        required
        formGroupClassName="mb-0"
      />
    );
  };

  const getTypeTitleInputContent = (taskRow: TaskRow, originalQueryAttribute: QueryAttributeJM) => {
    if (taskRow.RelatedToType === "incident") {
      return (
        getTitleActionTypeActivityDropdown()
      );
    } else {
      let taskTypeQueryAttribute: QueryAttributeJM = R.clone(originalQueryAttribute);
      if (
        taskTypeQueryAttribute &&
        isNotEmptyArray(taskTypeQueryAttribute.ValueJson)
      ) {
        taskTypeQueryAttribute.ValueJson =
          taskTypeQueryAttribute.ValueJson.filter(
            (x) =>
              x.DisplayName === RAFTaskType.Appointment ||
              x.DisplayName === RAFTaskType.ToDo
          );
      }

      return (
        <>
          <div className="col-md-12">
            <RAFDropdownCC<TaskRow>
              field="TaskType"
              label="Type"
              placeholder="Select Type"
              isColorOption
              // mode="discView"
              uitype="colorpicker"
              createOptionMode={"Footer"}
              moduleName={moduleName}
              required
              displayDropdownType={"customDropdownBtnForMinCount"}
              onChanged={onChangeTaskType}
              allowAdd={false}
              //allowAdd={IsSuperAdmin() ? true : false}
              queryAttribute={taskTypeQueryAttribute}
            />
          </div>

          <div className="col-md-12 titleFieldOuter">
            <label className="form-label required hidden">Title</label>
            {isNotNullAndUndefined(taskRow) &&
              taskRow.RelatedToType !== RAFEntityName.Complaint ? (
              <Fragment>
                {/* <ACLookupComboBox<TaskRow>
                label="Title"
                field="Title"
                placeholder="Select Title"
                showLabel
                showCreateButton
                // showCreateButton={true}
                url="TaskTitle/LookUp"
                createform={RAFCreate}
                moduleName={RAFEntityName.TaskTitle}
                SearchCreateOptionMode={"Footer"}
                onChanged={(label, value) =>
                  onChangeTaskTitle(label, value, taskRow.UID)
                }
                required
              /> */}
                {false && (
                  <ACLookupAutoComplete<TaskRow>
                    label="Title"
                    field="Title"
                    placeholder="Select Title"
                    showLabel
                    //showCreateButton
                    url="TaskTitle/LookUp"
                    createform={RAFCreate}
                    moduleName={RAFEntityName.TaskTitle}
                    SearchCreateOptionMode={"Footer"}
                    onChanged={(label, value) =>
                      onChangeTaskTitle(label, value, taskRow.UID)
                    }
                    required
                  // formGroupClassName="inline-Editable"
                  // labelClassName="col-3"
                  // inputFieldClassName="col-9"
                  />
                )}
                <ACAutoCompleteMUI
                  label="Title"
                  field="Title"
                  placeholder="Select Title"
                  showLabel
                  //showCreateButton
                  url="TaskTitle/LookUp"
                  createform={RAFCreate}
                  moduleName={RAFEntityName.TaskTitle}
                  SearchCreateOptionMode={"Footer"}
                  onChanged={(label, value) =>
                    onChangeTaskTitle(label, value, taskRow.UID)
                  }
                  required
                />
              </Fragment>
            ) : (
              <ACTextBox<TaskRow>
                field="Title"
                label="Title"
                showLabel
                required
              // formGroupClassName="inline-Editable"
              // labelClassName="col-3"
              // inputFieldClassName="col-9"
              ></ACTextBox>
            )}
          </div>
        </>
      );
    }
  };

  const renderEditTaskContent = (taskRow: TaskRow, originalQueryAttribute: QueryAttributeJM) => {

    return (
      <div className="row gx-2 gx-md-3 gx-xl-3 overflow-hidden gy-3 form-group-margin-0 mb-3">
        {/* <div className="col-md-12">
          <ConditionIncludes
            when={"TaskType"}
            is={[RAFTaskType.ToDo, RAFTaskType.Action]}
          >
          <RAFRadioButtonList<TaskRow>
            field="TaskType"
            showLabel={false}
            uitype={"customButton"}
            formGroupClassName="custom-radio-btn-m-0 mt-2"
          >
            <RAFChoiceOption
              label={RAFTaskType.ToDo}
              value={RAFTaskType.ToDo}
            />
            <RAFChoiceOption
              label={RAFTaskType.Action}
              value={RAFTaskType.Action}
            />
          </RAFRadioButtonList>
          </ConditionIncludes>
        </div> */}
        {getTypeTitleInputContent(taskRow, originalQueryAttribute)}
        <div className="col-md-12">
          <RAFTextArea<TaskRow>
            field="Description"
            showLabel
            label="Description"
            placeholder="Description"
            // rows={2}
            formGroupClassName="m-0"
          ></RAFTextArea>
        </div>
        {/* <ConditionIncludes
          when={"TaskType"}
          is={[RAFTaskType.ToDo, RAFTaskType.Action]}
        >
          <div className="col-md-12">
            <RAFRadioButtonList<TaskRow>
              field="TaskType"
              showLabel={false}
              uitype={"quickselect"}
              formGroupClassName="custom-radio-btn-m-0 taskRadioBtn cssradio-m-0"
            >
              <RAFChoiceOption
                label={RAFTaskType.ToDo}
                value={RAFTaskType.ToDo}
              />
              <RAFChoiceOption
                label={RAFTaskType.Action}
                value={RAFTaskType.Action}
              />
            </RAFRadioButtonList>
          </div>
        </ConditionIncludes> */}
        <ConditionNotIncludes
          when={propertyOf<TaskRow>("TaskType")}
          isNot={[RAFTaskType.Event, RAFTaskType.Appointment]}
        >
          <div className="col-md-12 cssradio-mb-0">
            <RAFDatePickerCustom
              field="TaskDate"
              secondaryField="DueDate"
              label="Due date"
              displayStyle="splitButton"
              showLabel
              // formGroupClassName="inline-Editable"
              // showLabel={true}
              // onChanged={(days, date) =>
              //   onModifyQuickDateSelected(days, date)
              // }
              dropdownDateOptions={[
                { id: 0, text: "ASAP", value: "ASAP" },
                // { id: 1, text: 'Today', value: '0' },
                { id: 2, text: "1D", value: "1" },
                { id: 3, text: "3D", value: "3" },
                { id: 4, text: "7D", value: "7" },
                { id: 5, text: "15D", value: "15" },
                { id: 6, text: "1M", value: "30" },
                { id: 7, text: "2M", value: "60" },
              ]}
              allowMaxValue
              hideMinAndMaxValue
              showClearTextBtn
              onChanged={(value) => onChangeTaskDate(value)}
            // labelClassName="col-3"
            // inputFieldClassName="col-9"
            />
            {/* <div className="col-12">
            <RAFDatePickerCustom
              field="TaskDate"
              showLabel={false}
              label="Due Date"
              disabled={
                taskRow && taskRow.TaskStatus === RAFActionStatus.Planned
                  ? false
                  : true
              }
              onChanged={(value) => onChangeTaskDate(value)}
              showEmpty
            />
          </div> */}
          </div>
        </ConditionNotIncludes>
        <ConditionIncludes
          when={propertyOf<TaskRow>("TaskType")}
          is={[RAFTaskType.Event, RAFTaskType.Appointment]}
        >
          <div className="col-md-12">
            <RAFStartDateEndDatePicker
              field={propertyOf<TaskRow>("TaskDate")}
              endDateField={propertyOf<TaskRow>("EndDate")}
              label="Start Date"
              required
              interval={5}
              formGroupClassName="mb-0"
            />
          </div>
        </ConditionIncludes>
        {(taskRow && state.isEditTask === true) ||
          props.isCreateTask === true ? (
          <div className="col-md-12">
            <div className="row gx-1 gx-md-2 gx-xl-3">
              <RAFFieldLabel
                field="Assignee"
                label="Assigned To"
                //required
                labelClassName="col-12"
              ></RAFFieldLabel>
              <div className="col-12">
                <RAFUserTeamDropdown<TaskRow>
                  field="Assignee"
                  label="Assigned To"
                  showLabel={false}
                  usersAndTeams={state.usersAndTeams}
                //required
                // formGroupClassName="inline-Editable"
                />
              </div>
            </div>
          </div>
        ) : null}
        {false && (
          <div className="row py-2">
            <div className="col-6">
              <RAFCheckBox
                field={propertyOf<TaskRow>("IsRequired")}
                label="Is Required"
              />
            </div>
            <div className="col-6">
              <RAFNumber
                field={propertyOf<TaskRow>("SortOrder")}
                label="Sort Order"
              />
            </div>
          </div>
        )}
        {taskRow && taskRow.TaskStatus === RAFActionStatus.Completed ? (
          <div className="col-md-12">
            <RAFDropdownCC<TaskRow>
              field="TaskStatus"
              label="Status"
              placeholder="TaskStatus"
              isColorOption
              // mode="discView"
              uitype="colorpicker"
              createOptionMode={"Footer"}
              moduleName={moduleName}
              allowAdd
            />
          </div>
        ) : (
          ""
        )}
        {contextModuleName === RAFEntityName.Dashboard && (
          <div className="col-md-12">
            <div className="row">
              <RAFFieldLabel
                field="RelatedTo"
                label="Related To"
                labelClassName="col-12"
              ></RAFFieldLabel>
              <div className="col-12">{renderRelatedToContent(taskRow)}</div>
            </div>
          </div>
        )}
        {/* <div className="col-md-12 mt-3">
          <ACTagInput<TaskRow>
            field="TagsListJson"
            label="Tags"
            showLabel
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
            labelClassName="col-3"
            inputFieldClassName="col-9"
            disabled
          />
        </div> */}
        <div className="col-md-12">
          <RAFAutoCompleteMUITagInput<TaskRow>
            field="TagsListJson"
            label="Tags"
            showLabel={true}
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
            rowClassName="gy-0 gx-2"
            moduleName={moduleName}
          />
        </div>
        {/* <div className="col-md-12">
          <RAFTaskTagInput<TaskRow>
            field="TagsListJson"
            label="Tags"
            showLabel={false}
            // showLabel={true}
            placeholder="Type and press enter to add a tag"
            showAddBtn
          />
        </div> */}
        {false && currentTenantName !== "skytrust" && (
          <div className="col-md-12">
            <RAFDetailInlineEdit1
              label="Recurring"
              fieldName="RRuleExpression"
              tooltipContent={rRuleExpressionCustomContent("RRuleExpression")}
              onClickClearField={() => onClickCreateRepeatProcesss()}
              // labelClassName="col-3"
              // inputClassName="col-9"
              UiMode="floatingCustomDiv"
              valueUiMode="card"
              customValue={
                <div className="d-flex align-items-baseline">
                  <i className="fas fa-timer me-2"></i>{" "}
                  <Field
                    name="RRuleExpression"
                    allowNull
                    parse={(value) => (value === "" ? null : value)}
                  >
                    {({ input }) => {
                      let RecurringValue =
                        isNotNullAndUndefined(input) &&
                          !IsNullOrWhiteSpace(input.value)
                          ? RRule.fromString(
                            input.value.slice(0, input.value.length - 1)
                          ).toText()
                          : "Never";
                      return (
                        <span className="text-wrap form-value">
                          {RecurringValue}
                        </span>
                      );
                    }}
                  </Field>
                </div>
              }
            />
            {/* <RAFDetailFieldInlineEditWithPopup
              label="Recurring"
              fieldName="RRuleExpression"
              tooltipContent={rRuleExpressionCustomContent("RRuleExpression")}
              onClickField={(fieldName) => showEditableField(fieldName)}
              labelClassName={"col-md-4 align-items-center"}
              inputFieldClassName={"col-md-8"}
              customValue={
                <Field
                  name="RRuleExpression"
                  allowNull
                  parse={(value) => (value === "" ? null : value)}
                >
                  {({ input }) => {
                    let RecurringValue =
                      isNotNullAndUndefined(input) &&
                        !IsNullOrWhiteSpace(input.value)
                        ? RRule.fromString(
                          input.value.slice(0, input.value.length - 1)
                        ).toText()
                        : "Never";
                    return <span className="text-wrap">{RecurringValue}</span>;
                  }}
                </Field>
              }
              mode="formEdit"
            ></RAFDetailFieldInlineEditWithPopup> */}
          </div>
        )}
        {/* {isNotNullAndUndefined(taskRow) &&
          isNotNullAndUndefined(taskRow.Description) ? (
          <div className="col-md-12">
            <RAFTextArea<TaskRow>
              field="Description"
              showLabel
              label="Description"
              placeholder="Description"
              rows={3}
              formGroupClassName="m-0"
            ></RAFTextArea>
          </div>
        ) : (
          <div className="col-md-12 mt-2">
            <ButtonComponent
              id="btn_open_floatingCustomDiv_Description"
              content="Add Description"
              cssClass="link-button"
              iconCss="fas fa-plus"
              type="button"
              onClick={() => openFloatingPopupContent("Description")}
            ></ButtonComponent>
            <div
              className="mt-1 hidden"
              id="floatingCustomDivDescription"
            >
              <RAFTextArea<TaskRow>
                field="Description"
                showLabel
                label="Description"
                placeholder="Description"
                rows={3}
              ></RAFTextArea>
            </div>
          </div>
        )} */}
        {/* <div className="col-md-12 d-flex flex-column mb-3">
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label">Tags</label>
                        </div>

                        <div className="col-12">
                            <RAFTagDetails
                                initialTags={isNotNullAndUndefined(taskRow) ? taskRow.TagsListJson : null}
                                relatedToUID={isNotNullAndUndefined(taskRow) ? taskRow.UID : null}
                                moduleName={moduleName}
                            //onSave={refreshOnUpdateAccount.bind(this)}
                            />
                        </div>
                    </div>
                </div> */}
        {/* <div className="col-md-12">
          <RAFDropdownCC<TaskRow>
            field="Priority"
            label="Priority"
            iconName="fas fa-flag"
            showLabel
            labelClassName={"col-md-4"}
            inputFieldClassName={"col-md-8"}
            placeholder="Select Priority"
            isColorOption={false}
            uitype="colorpicker"
            mode="iconView"
            moduleName={"task"}
            displayDropdownType={"customDropdownBtn"}
            addEmpty={false}
            clearSelectionOnClick
          ></RAFDropdownCC>
        </div> */}
        {false && currentTenantName !== "skytrust" && (
          <div className="col-md-12">
            <RAFReminderDropdownWithAddBtn<TaskRow>
              field="Reminders"
              showLabel
              label="Reminder"
              checkUnique={false}
              showAdd={false}
            />
          </div>
        )}
        <Condition when="IsApprovalTask" is="No">
          <div className="col-md-12">
            <ButtonComponent
              iconCss="fas fa-check"
              cssClass="btn-muted btn-active-success"
            >
              Mark Complete
            </ButtonComponent>
          </div>
        </Condition>
        <Condition when="IsApprovalTask" is="Yes">
          <div className="col-md-12 d-flex mb-3">
            <ButtonComponent
              iconCss="far fa-check-circle"
              cssClass="btn-muted btn-active-primary w-100 m-2 ms-0"
            >
              Approve
            </ButtonComponent>
            <ButtonComponent
              iconCss="far fa-times-circle"
              cssClass="btn-muted btn-active-danger w-100 m-2"
            >
              Reject
            </ButtonComponent>
            <ButtonComponent cssClass="btn-muted btn-active-warning w-100 m-2 me-0">
              <span className="position-relative">
                <i className="fas fa-redo e-btn-icon e-icon-left"></i>
                <i
                  className="fas fa-check position-absolute"
                  style={{
                    top: "calc(50% - 2px)",
                    left: "4px",
                    fontSize: "6px",
                  }}
                ></i>
              </span>
              Request Changes
            </ButtonComponent>
          </div>
        </Condition>
        {/*{taskRow.TaskStatus === RAFActionStatus.Planned && moduleName === "dashboard" && taskRow.RelatedToType !== 'business_process' ?
                <div className="row g-3 mt-1">
                    <div className="col-12 mt-0 d-flex">
                        <label className="form-label" >Link this task to</label>
                    </div>
                    <div className="col-sm-4 col-xl-3 mt-0">
                        <RAFDropdown<TaskRow> field="RelatedToType" showLabel={false} label="Link this task to" placeholder="Select Relatedto">
                            <RAFChoiceOption value="contact" label="Contact" />
                            <RAFChoiceOption value="account" label="Company" />
                            <RAFChoiceOption value="deal" label="Deal" />
                            <RAFChoiceOption value="ticket" label="Ticket" />
                            <RAFChoiceOption value="complaint" label="Complaint" />
                        </RAFDropdown>
                        <WhenFieldChangesTo field="RelatedToType" becomes="contact" set={'RelatedTo'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="contact" set={'RelatedToUID'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="account" set={'RelatedTo'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="account" set={'RelatedToUID'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="deal" set={'RelatedTo'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="deal" set={'RelatedToUID'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="ticket" set={'RelatedTo'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="ticket" set={'RelatedToUID'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="complaint" set={'RelatedTo'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes="complaint" set={'RelatedToUID'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes={null} set={'RelatedTo'} to={null} />
                        <WhenFieldChangesTo field="RelatedToType" becomes={null} set={'RelatedToUID'} to={null} />
                    </div>
                    <div className="col-sm-8 col-xl-9 mt-0">
                        <Condition when="RelatedToType" is="contact">
                            <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={ContactRow} placeholder="Select Contact" selectedField={"ContactName"} url="Contact/Lookup" moduleName={RAFEntityName.Contact}></RAFLookup>
                        </Condition>
                        <Condition when="RelatedToType" is="account">
                            <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={AccountRow} placeholder="Select Company" selectedField={"AccountName"} url="Account/Lookup" moduleName={RAFEntityName.Account}></RAFLookup>
                        </Condition>
                        <Condition when="RelatedToType" is="deal">
                            <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={DealRow} placeholder="Select Deal" selectedField={"DealName"} url="Deal/Lookup" moduleName={RAFEntityName.Deal}></RAFLookup>
                        </Condition>
                        <Condition when="RelatedToType" is="ticket">
                            <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={TicketRow} placeholder="Select Ticket" selectedField={"Subject"} url="Ticket/Lookup" moduleName={RAFEntityName.Ticket} ></RAFLookup>
                        </Condition>
                        <Condition when="RelatedToType" is="complaint">
                            <RAFLookup<TaskRow> field="RelatedTo" showLabel={false} label="Link this task to" type={ComplaintRow} placeholder="Select Complaint" selectedField={"ComplaintId"} url="Complaint/Lookup" moduleName={RAFEntityName.Complaint}></RAFLookup>
                        </Condition>
                    </div>
                </div>
                : null
            }*/}
        {/* {taskRow &&
          taskRow.TaskStatus === RAFActionStatus.Planned &&
          moduleName === RAFEntityName.Dashboard &&
          taskRow.RelatedToType !== "business_process" ? (
          <div className="col-md-12">
            <RAFRelatedLookupSelector
              field="RelatedTo"
              relatedField="RelatedToType"
              moduleName={"task"}
            ></RAFRelatedLookupSelector>
          </div>
        ) : null} */}
      </div>
    );
  };

  const renderCommentContent = (taskRow: TaskRow) => {
    return (
      <div className="e-card border-0" id="comments">
        <div className="e-card-content py-0 px-0">
          <RAFCollapse
            arrowPosistion="title"
            title={getCollectionNameByModuleName(RAFEntityName.Activity)}
            iconName="Comments"
            contentCardClassName="p-0 manageTask_related_documents"
            headerRowClassName="custom-section-header remover-margin"
            layout={RAFLayout.OneColumnLayout}
            IsCollapsed={false}
            removeContentCardPadding
            showSectionHeader={false}
          >
            <CreateActivitySummary
              onChangeRelatedActivity={() => {
                updateactivitySummaryKey();
              }}
              relatedToUID={taskRow.RelatedToUID}
              relatedTo={taskRow.RelatedTo}
              moduleName={taskRow.RelatedToType}
              secondaryRelatedToUID={taskRow.UID}
              secondaryRelatedTo={taskRow.Title}
              secondaryRelatedToType={RAFEntityName.Task}
              activityItemClassname="px-1"
              excludeRelatedFilter
            />

            {/* <div className="pt-3 pb-2">
              <CreateActivity
                onUpdate={() => {
                  updateactivitySummaryKey();
                }}
                relatedToUID={taskRow.RelatedToUID}
                relatedTo={taskRow.RelatedTo}
                entityName={taskRow.RelatedToType}
                secondaryRelatedToUID={taskRow.UID}
                secondaryRelatedTo={taskRow.Title}
                secondaryRelatedToType={RAFEntityName.Task}
              // menuContainer={"manage_Task_outerDiv"}
              />
            </div>
            <ActivitySummary
              key={state.activitySummaryKey}
              //relatedToUID={taskRow.RelatedToUID}
              relatedCommentsUpdated={() => {
                if (props.relatedCommentsUpdated) {
                  props.relatedCommentsUpdated(true);
                }
              }}
              //entityName={taskRow.RelatedToType}
              activityItemClassname="px-1"
              secondaryRelatedToUID={taskRow.UID}
              {...props}
            /> */}
          </RAFCollapse>
        </div>
      </div>
    );
  };

  const renderDocumentContent = (taskRow) => {
    return (
      <div className="e-card border-0" id="documents">
        <div className="e-card-content py-0 px-0">
          <DocumentSummary
            key={state.documentSummaryKey}
            allowFiltering={false}
            isRelatedSection
            relatedUID={taskRow.UID}
            relatedTo={taskRow.Title}
            relatedToType={contextModuleName}
            moduleName={RAFModuleName.Task}
            showFilterBtn={false}
            isRelatedDocument
            gridStyle={"List"}
            // isAdaptive={true}
            isAdaptive
            collapsePanelArrowPosistion="title"
            showExpandBtn={false}
            headerRowClassName="custom-section-header remover-margin"
            contentCardClassName="manageTask_related_documents"
            // addBtnDivId="manageTask_relatedDocument_div"
            enableAddBtn={false}
            showSectionHeader={false}
            IsCollapsed={false}
            addBtnDisplayStyle="SectionFooter"
            readPermissionName={PermissionConstants.TaskViewDocuments}
            createPermissionName={PermissionConstants.TaskManageDocuments}
            updatePermissionName={PermissionConstants.TaskManageDocuments}
            deletePermissionName={PermissionConstants.TaskDeleteDocuments}
          />
        </div>
      </div>
    );
  };

  // const renderBusinessProcess = (taskRow) => {
  //   return (
  //     <div className="e-card" id="process">
  //       <div className="e-card-content p-0">
  //         <RAFRelatedBusinessProcess
  //           key={state.relatedBPWidgetKey}
  //           moduleName={moduleName}
  //           relatedObject={taskRow}
  //           onProcessStart={() => updateBPStatusAndRefreshTask()}
  //           relatedSectionUpdated={() => updateBPStatusAndRefreshTask()}
  //           relatedObjectName={taskRow.Title}
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  const renderRelatedToContent = (taskRow) => {
    return (
      <Fragment>
        <Field name={"RelatedToUID"}>
          {({ input }) => {
            const relatedToUID = input.value;
            return (
              <Field name={"RelatedToType"}>
                {({ input }) => {
                  const relatedToType = input.value;
                  return (
                    <Field name={"RelatedTo"}>
                      {({ input }) => {
                        const relatedTo = input.value;
                        return (
                          <div
                            className="e-card pointer"
                            key={state.relatedToContentKey}
                          >
                            <div className="e-card-content p-3 py-2">
                              {isNotNullAndUndefined(relatedToType) &&
                                isNotNullAndUndefined(relatedToUID) &&
                                !IsNullOrWhiteSpace(relatedToType) &&
                                !IsNullOrWhiteSpace(relatedToUID) ? (
                                <div className="d-flex align-items-center justify-content-between">
                                  <div
                                    onClick={() =>
                                      RelatedToClick(
                                        relatedToUID,
                                        relatedToType,
                                        taskRow
                                      )
                                    }
                                  >
                                    <RAFRelatedToWithIcon
                                      relatedToType={relatedToType}
                                      relatedTo={relatedTo}
                                      {...(props.isCreateTask !== true
                                        ? {
                                          showTextAsLink: true,
                                        }
                                        : {})}
                                    />
                                  </div>

                                  {(state.isEditTask === true ||
                                    props.isCreateTask === true) &&
                                    contextModuleName ===
                                    RAFEntityName.Dashboard &&
                                    taskRow.RelatedToType !==
                                    "business_process" ? (
                                    isNotNullAndUndefined(relatedToUID) ? (
                                      <ButtonComponent
                                        type="button"
                                        cssClass="custom-button-sm primary-custom-button"
                                        content={
                                          RAFButtonConstant.Edit.DisplayName
                                        }
                                        iconCss={RAFButtonConstant.Edit.IconCss}
                                        id={`btn_${RAFButtonConstant.Edit.Id}_relatedSection`}
                                        onClick={() =>
                                          openRelatedToTaskDialog()
                                        }
                                      ></ButtonComponent>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ) : (
                                <div className="d-flex align-items-center justify-content-center">
                                  {/* <div className="mt-3">
                                <RAFEmptyState title="No record related with this task"
                                  {...(taskRow &&
                                    taskRow.TaskStatus === RAFActionStatus.Planned) ? { body: "Please click on add button to relate the record." } : {}}
                                  displayStyle="TextOnly"
                                />
                              </div> */}
                                  {(state.isEditTask === true ||
                                    props.isCreateTask === true) &&
                                    contextModuleName ===
                                    RAFEntityName.Dashboard &&
                                    taskRow.RelatedToType !==
                                    "business_process" ? (
                                    isNotNullAndUndefined(
                                      taskRow.RelatedToUID
                                    ) ? (
                                      // <ButtonComponent type="button" cssClass="link-button" content={RAFBtnContentString.Edit} iconCss={RAFBtnIconCss.Edit} id={`btn_${RAFBtnIdString.Edit}_relatedSection`} onClick={() => manageTaskRelatedSectionClicked()}></ButtonComponent>
                                      ""
                                    ) : (
                                      <ButtonComponent
                                        type="button"
                                        cssClass="link-button"
                                        isPrimary
                                        content={"Link Record"}
                                        iconCss={RAFButtonConstant.Add.IconCss}
                                        id={`btn_${RAFButtonConstant.Add.Id}_relatedSection`}
                                        onClick={() =>
                                          openRelatedToTaskDialog()
                                        }
                                      ></ButtonComponent>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  );
                }}
              </Field>
            );
          }}
        </Field>
        {state.showRelatedToTaskDialogContent && (
          <DialogComponent
            header="Link this task to"
            showCloseIcon={true}
            visible={state.showRelatedToTaskDialogContent}
            cssClass="rightDialog rightDialog-sm full-height createEditForm form-center-dialog"
            id="RelatedToDialogContent"
            content={relatedToTaskDialogContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeRelatedToTaskDialog.bind(this)}
            animationSettings={animationSettings}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </Fragment>
    );
  };

  // const renderTabContent = (taskRow) => {
  //   let selectedManageTaskTabIndex = state.selectedManageTaskTabIndex;
  //   if (selectedManageTaskTabIndex === 0) {
  //     return (
  //       isNotNullAndUndefined(taskRow) &&
  //         taskRow.TaskStatus === RAFActionStatus.Completed
  //         ? renderCompletedTaskContent(taskRow)
  //         : renderEditTaskContent(taskRow)
  //     );
  //   }
  //   else if (props.isCreateTask !== true) {
  //     if (selectedManageTaskTabIndex === 1) {
  //       return (
  //         renderCommentContent(taskRow)
  //       );
  //     }
  //     else if (selectedManageTaskTabIndex === 2) {
  //       return (
  //         renderDocumentContent(taskRow)
  //       );
  //     }
  //     else if (selectedManageTaskTabIndex === 3) {
  //       return (
  //         renderRelatedToContent(taskRow)
  //       );
  //     }
  //   }
  //   else if (props.isCreateTask === true && selectedManageTaskTabIndex === 1) {
  //     return (
  //       // renderBusinessProcess(taskRow)
  //       renderRelatedToContent(taskRow)
  //     );
  //   }
  // };

  const renderTabContent = (taskRow: TaskRow, taskTypeQueryAttribute) => {
    let selectedManageTaskTabIndex = state.selectedManageTaskTabIndex;
    if (
      selectedManageTaskTabIndex.text === "Details" ||
      selectedManageTaskTabIndex.text === "Related to"
    ) {
      return renderFormContent(taskRow, taskTypeQueryAttribute);
    } else if (selectedManageTaskTabIndex.text === "Comments") {
      return renderCommentContent(taskRow);
    } else if (selectedManageTaskTabIndex.text === "Documents") {
      return renderDocumentContent(taskRow);
    }
  };

  const renderFormContent = (taskRow: TaskRow, taskTypeQueryAttribute) => {
    let selectedManageTaskTabIndex = state.selectedManageTaskTabIndex;
    return (
      <Fragment>
        <RAFForm
          className="h-100"
          type={TaskRow}
          initialValues={taskRow}
          formRef={(g) => {
            enableSubmitButton(g);
            return (rafForm = g);
          }}
          layout={RAFLayout.TwoColumnLayout}
          //primaryKey={taskRow.UID}
          onSubmit={onSubmitTask}
          submitOnEnterKey={false}
        >
          <div
            className={
              selectedManageTaskTabIndex.text === "Details" ? "" : "d-none"
            }
          >
            {state.isEditTask === true || props.isCreateTask === true
              ? renderEditTaskContent(taskRow, taskTypeQueryAttribute)
              : renderCompletedTaskContent(taskRow)}
          </div>
          {selectedManageTaskTabIndex.text === "Related to" && (
            <div
              className={
                selectedManageTaskTabIndex.text === "Related to" ? "" : "d-none"
              }
            >
              {renderRelatedToContent(taskRow)}
            </div>
          )}
        </RAFForm>
      </Fragment>
    );
  };

  const onClickTaskSaveBtn = () => {
    if (isNotNullAndUndefined(rafForm)) {
      rafForm.form.submit();
    }
  };

  // const updateBPStatusAndRefreshTask = () => {
  //   setState({ isActive: false });
  //   refresh(props.taskUID, true);
  // };

  const onEditClicked = () => {
    setState({ isEditTask: true });
  };

  if (state.isActive === true) {
    let selectedManageTaskTabIndex = state.selectedManageTaskTabIndex;
    return (
      <RAFEntityProvider moduleName={RAFModuleName.Task}>
        <RAFAttributeRelatedListProvider
          moduleName={RAFModuleName.Task}
          progressDivId={`#manage_Task_outerDiv_${objGuid}`}
        >
          <RAFAttributesContext.Consumer>
            {({ queryAttributes }) => {
              return (
                <MyTeamsContext.Consumer>
                  {({ teams }) => {
                    myTeams = teams;
                    let { isLoading, taskRow } = state;
                    let isCreateTask = props.isCreateTask;

                    if (isNotNullAndUndefined(taskRow) && isLoading === false) {
                      const taskTabItems: {
                        id: number;
                        text: string;
                        permissionName?: string;
                      }[] = [
                          {
                            id: 0,
                            text: "Details",
                          },
                        ];
                      // if (contextModuleName === RAFEntityName.Dashboard &&
                      //   taskRow.RelatedToType !== "business_process") {
                      //   taskTabItems.push({
                      //     id: 1,
                      //     text: "Related to",
                      //   });
                      // }
                      if (isCreateTask !== true) {
                        taskTabItems.push(
                          {
                            id: 2,
                            text: "Comments",
                            permissionName: PermissionConstants.CommentsRead,
                          },
                          {
                            id: 3,
                            text: "Documents",
                            permissionName:
                              PermissionConstants.TaskViewDocuments,
                          }
                        );
                      }

                      const queryAttributeClone: QueryAttributeJM[] =
                        R.clone(queryAttributes);
                      const taskTypeQueryAttribute =
                        queryAttributeClone &&
                        queryAttributeClone.find(
                          (x) => x.AttributeName === "task_type"
                        );

                      return (
                        <div
                          className="h-100 bg-white"
                          id={`manage_Task_outerDiv_${objGuid}`}
                          key={`manage_Task_outerDiv_${objGuid}_${state.taskDetailsSectionKey}_${selectedManageTaskTabIndex.id}`}
                        >
                          <div
                            className={
                              props.isDetailsMode
                                ? "h-100 d-flex flex-column bg-white"
                                : "dlg-new-style"
                            }
                          >
                            {props.isDetailsMode !== true &&
                              props.hideHeader !== true && (
                                <div
                                  className={
                                    props.isDetailsMode
                                      ? "sectionHeader border-bottom d-flex align-items-center justify-content-between"
                                      : "e-dlg-headerContent h-auto tab_fixedHeader_scrollableContent_dialog_header"
                                  }
                                  id="manage_Task_outerDiv_dialog-header"
                                >
                                  <div className="d-flex justify-content-between align-items-center w-100">
                                    <div
                                      className={
                                        props.isDetailsMode
                                          ? "header-text"
                                          : "e-dlg-header"
                                      }
                                      id="_title"
                                    >
                                      {isCreateTask === true ? (
                                        "Add Task"
                                      ) : (
                                        <div className="d-flex flex-column align-items-start">
                                          <div className="header_5 mb-1">
                                            <span className="ecllipseFirstLine header_5 semi_bold">
                                              {" "}
                                              {state.isEditTask === false
                                                ? "Details"
                                                : "Modify Task"}
                                            </span>
                                          </div>
                                          <RAFRecordInfo
                                            createdDate={taskRow.CreatedDate}
                                            createdBy={taskRow.CreatedBy}
                                            modifiedBy={taskRow.ModifiedBy}
                                            modifiedDate={taskRow.ModifiedDate}
                                            lastActivityDate={
                                              taskRow.LastActivityDate
                                            }
                                            {...(window.innerWidth > 768 && {
                                              content: `<span class='d-flex flex-nowrap body_1_light'>Updated by <span class='ecllipseFirstLine word-break-all text-inherit text-wrap px-1 body_1_light'> ${taskRow.ModifiedBy}</span></span>`,
                                            })}
                                            spanContentClassName="body_1 text-decoration-underline"
                                            hideClockIcon
                                          ></RAFRecordInfo>
                                        </div>
                                      )}
                                    </div>
                                    {props.isDetailsMode ? (
                                      <ButtonComponent
                                        type="button"
                                        cssClass="e-small link-button grey-link-button unset-custom-button-md"
                                        iconCss="fas fa-xmark"
                                        onClick={() => {
                                          onCloseDialogClick();
                                        }}
                                      ></ButtonComponent>
                                    ) : (
                                      <RAFButtonComponent
                                        displayMode="DialogCloseBtn"
                                        onClick={() => onCloseDialogClick()}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            {taskTabItems.length <= 1 ? (
                              ""
                            ) : (
                              <div
                                className="border-bottom ps-1"
                                style={{ marginTop: "-0.125rem" }}
                              >
                                {/* <TabComponent
                          cssClass="e-fill custom-tab-footer-border bp-tab active-bg-gray active-bg-white"
                          loadOn="Demand"
                          heightAdjustMode="Auto"
                          selectedItem={selectedTabItemIndex}
                          // animation={tabAnimationSettings}
                          selected={(e) =>
                            ManageTaskTabSelected(e.selectedItem)
                          }
                        >
                          <div className="d-flex align-items-center justify-content-between bg-blue w-100 pe-4">
                            <div className="e-tab-header">
                              {taskTabItems.map((item, index) => {
                                return <div key={index}>{item.text}</div>;
                              })}
                            </div>
                          </div>
                          <div className="e-content"></div>
                        </TabComponent> */}
                                <div
                                  id="raf_tab_header_manage_task_container"
                                  className="raf_tab_header raf_lg secondary_tab hideScrollBar full_width_tab"
                                >
                                  {!BrowserIsDevice && (
                                    <RAFButtonComponent
                                      iconBtn
                                      iconCss="fas fa-chevron-left"
                                      className="bg-white border-0 custom-button-lg"
                                      id="raf_tab_header_manage_task_left_scroll"
                                      enableToolTip={false}
                                    ></RAFButtonComponent>
                                  )}
                                  <div
                                    id="raf_tab_header_manage_task"
                                    className="raf_tab_header raf_lg hideScrollBar"
                                  >
                                    {taskTabItems.map((x) => {
                                      const permission = isNotNullAndUndefined(
                                        x.permissionName
                                      )
                                        ? hasPermission(
                                          permissionValue,
                                          x.permissionName
                                        )
                                        : true;

                                      if (permission) {
                                        return (
                                          <div
                                            className={`raf_tab_header_item${state.selectedManageTaskTabIndex
                                              .id === x.id
                                              ? " active"
                                              : ""
                                              }`}
                                            key={x.id}
                                            onClick={() =>
                                              ManageTaskTabSelected(x)
                                            }
                                          >
                                            <span>{x.text}</span>
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                  {!BrowserIsDevice && (
                                    <RAFButtonComponent
                                      iconBtn
                                      iconCss="fas fa-chevron-right"
                                      className="bg-white border-0 custom-button-lg"
                                      id="raf_tab_header_manage_task_right_scroll"
                                      enableToolTip={false}
                                    ></RAFButtonComponent>
                                  )}
                                </div>
                              </div>
                            )}
                            <div
                              key={`manage_Task_outerDiv_dialog-body_${objGuid}_${state.taskDetailsSectionKey}_${selectedManageTaskTabIndex.id}`}
                              className={
                                props.isDetailsMode
                                  ? "p-3 h-100 overflow-auto customScrollBar position-relative"
                                  : selectedManageTaskTabIndex.text ===
                                    "Details" ||
                                    selectedManageTaskTabIndex.text ===
                                    "Related to"
                                    ? "e-dlg-body-content taskUpdateForm position-relative pb-3 mb-3 px-lg-4"
                                    : "e-dlg-body-content taskUpdateForm position-relative p-0 mb-3"
                              }
                            >
                              {renderTabContent(
                                taskRow,
                                taskTypeQueryAttribute
                              )}
                            </div>
                            {state.selectedManageTaskTabIndex.text ===
                              "Details" &&
                              (state.isEditTask === false &&
                                isCreateTask !== true ? (
                                <div
                                  className={
                                    props.isDetailsMode
                                      ? "p-2 border-top"
                                      : "e-dlg-footerContent"
                                  }
                                >
                                  <div className="w-100">
                                    <div className="row gx-2 align-items-center">
                                      {(taskRow.TaskStatus !==
                                        RAFStatusNameWithColor.Completed
                                          .Value ||
                                        taskRow.TaskStatus !==
                                        RAFStatusNameWithColor.Completed
                                          .DisplayName) &&
                                        state.selectedManageTaskTabIndex
                                          .text === "Details" && (
                                          <div className="col-auto">
                                            <RAFPermissionRender
                                              permissionName={
                                                PermissionConstants.TaskDelete
                                              }
                                            >
                                              <RAFButtonComponent
                                                action={
                                                  RAFButtonConstant.Delete
                                                }
                                                iconBtn
                                                className="btn_state_danger transparent"
                                                onClick={() =>
                                                  DeleteTaskClicked(taskRow)
                                                }
                                              ></RAFButtonComponent>
                                            </RAFPermissionRender>
                                          </div>
                                        )}
                                      <div className="col-auto ms-auto">
                                        <RAFButtonComponent
                                          action={RAFButtonConstant.Edit}
                                          onClick={() => onEditClicked()}
                                          idString={taskRow.UID}
                                          showIcon={false}
                                          className="btn_brand_primary semi_dark"
                                        />
                                        {/* <div className="w-100">
                                      <div
                                        className="row radio-button"
                                        id="rafdiv_TaskStatus"
                                      >
                                        <div className="mb-0 col-md-12 form-group align-items-center py-0">
                                          <div className="row gx-1 align-items-center">
                                            <div className="col-auto">
                                              <label className="form-label">
                                                Completed ?
                                              </label>
                                            </div>
                                            <div className="col-auto radio-btn-m-0">
                                              <div>
                                                <div className="cssradio customButton min-width">
                                                  <div
                                                    className="d-flex align-items-center customButton-item w-100"
                                                    key={0}
                                                  >
                                                    <input
                                                      style={{ display: "none" }}
                                                      type="radio"
                                                      id={"radio_" + 0}
                                                      name={"TaskStatus"}
                                                      value={RAFActionStatus.Completed}
                                                      // checked={true}
                                                      checked
                                                      onChange={(e) => {
                                                        onChangeTaskStatus(
                                                          RAFActionStatus.Completed
                                                        );
                                                      }}
                                                      // disabled={true}
                                                      disabled
                                                    />
                                                    <label htmlFor={"radio_" + 0}>
                                                      Yes
                                                    </label>
                                                  </div>
                                                  <div
                                                    className="d-flex align-items-center customButton-item w-100"
                                                    key={1}
                                                  >
                                                    <input
                                                      style={{ display: "none" }}
                                                      type="radio"
                                                      id={"radio_" + 1}
                                                      name={"TaskStatus"}
                                                      value={RAFActionStatus.Planned}
                                                      onChange={(e) => {
                                                        onChangeTaskStatus(
                                                          RAFActionStatus.Planned
                                                        );
                                                      }}
                                                      checked={false}
                                                    />
                                                    <label htmlFor={"radio_" + 1}>
                                                      No
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                      </div>

                                      {state.selectedManageTaskTabIndex.text ===
                                        "Details" &&
                                        taskRow &&
                                        taskRow.TaskStatus ===
                                        RAFActionStatus.Planned &&
                                        (taskRow.TaskType === RAFTaskType.ToDo ||
                                          taskRow.TaskType ===
                                          RAFTaskType.Approval ||
                                          taskRow.TaskType ===
                                          RAFTaskType.Appointment ||
                                          taskRow.TaskType ===
                                          RAFTaskType.Action ||
                                          taskRow.TaskType === RAFTaskType.Event)
                                        ? (hasPermission(
                                          permissionValue,
                                          PermissionConstants.TaskUpdate
                                        ) ||
                                          taskRow.AssigneeUID ===
                                          msalInstance.currentUserId) && (
                                          <div className="col-auto">
                                            <ButtonComponent
                                              type="button"
                                              className="btn_state_success semi_dark"
                                              onClick={() =>
                                                onChangeTaskStatus(
                                                  RAFActionStatus.Completed
                                                )
                                              }
                                              content={
                                                RAFButtonConstant.MarkAsDone
                                                  .DisplayName
                                              }
                                              // iconCss={RAFBtnIconCss.Save}
                                              id={`btn_${RAFButtonConstant.MarkAsDone.Id}_${taskRow.TitleUID}`}
                                            />
                                          </div>
                                        )
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={
                                    props.isDetailsMode
                                      ? "p-2 border-top d-flex align-items-center justify-content-end"
                                      : "e-dlg-footerContent"
                                  }
                                >
                                  <div className="w-100">
                                    <div className="row gx-2">
                                      {taskRow.TaskStatus !==
                                        RAFStatusNameWithColor.Completed
                                          .DisplayName &&
                                        isCreateTask !== true &&
                                        state.selectedManageTaskTabIndex.text ===
                                        "Details" && (
                                          <RAFPermissionRender
                                            permissionName={
                                              PermissionConstants.TaskDelete
                                            }
                                          >
                                            <div className="col-auto">
                                              <RAFButtonComponent
                                                action={
                                                  RAFButtonConstant.Delete
                                                }
                                                iconBtn
                                                className="btn_state_danger transparent"
                                                onClick={() =>
                                                  DeleteTaskClicked(taskRow)
                                                }
                                              ></RAFButtonComponent>
                                            </div>
                                          </RAFPermissionRender>
                                        )}
                                      {isCreateTask === true ||
                                        state.selectedManageTaskTabIndex.text ===
                                        "Details" ? (
                                        <RAFPermissionRender
                                          permissionName={
                                            isCreateTask === true
                                              ? PermissionConstants.TaskCreate
                                              : PermissionConstants.TaskUpdate
                                          }
                                        >
                                          <div className="col-auto ms-auto">
                                            <ButtonComponent
                                              type="button"
                                              className="btn_brand_primary semi_dark"
                                              onClick={onClickTaskSaveBtn}
                                              content={
                                                RAFButtonConstant.Save.DisplayName
                                              }
                                              // iconCss={RAFBtnIconCss.Save}
                                              id={`btn_${RAFButtonConstant.Save.Id}_${taskRow.TitleUID}`}
                                            ></ButtonComponent>
                                          </div>
                                        </RAFPermissionRender>
                                      ) : (
                                        ""
                                      )}
                                      {state.selectedManageTaskTabIndex.text ===
                                        "Details" &&
                                        taskRow &&
                                        taskRow.TaskStatus ===
                                        RAFActionStatus.Planned &&
                                        // (taskRow.TaskType === RAFTaskType.ToDo ||
                                        //   taskRow.TaskType === RAFTaskType.Approval ||
                                        //   taskRow.TaskType ===
                                        //     RAFTaskType.Appointment ||
                                        //   taskRow.TaskType === RAFTaskType.Action ||
                                        //   taskRow.TaskType === RAFTaskType.Event) &&
                                        isCreateTask !== true
                                        ? (hasPermission(
                                          permissionValue,
                                          PermissionConstants.TaskUpdate
                                        ) ||
                                          taskRow.AssigneeUID ===
                                          msalInstance.currentUserId) && (
                                          <div className="col-auto">
                                            <ButtonComponent
                                              type="button"
                                              className="btn_state_success semi_dark"
                                              onClick={() =>
                                                onChangeTaskStatus(
                                                  RAFActionStatus.Completed
                                                )
                                              }
                                              content={
                                                RAFButtonConstant.MarkAsDone
                                                  .DisplayName
                                              }
                                              // iconCss={RAFBtnIconCss.Save}
                                              id={`btn_${RAFButtonConstant.MarkAsDone.Id}_${taskRow.TitleUID}`}
                                            />
                                          </div>
                                        )
                                        : // <div className="row align-items-center g-0" onClick={() => onChangeTaskStatus(RAFActionStatus.Completed)}>
                                        //       <div className="col d-flex" >
                                        //         <CheckBoxComponent
                                        //           name={"Status"}
                                        //           change={() => onChangeTaskStatus(RAFActionStatus.Completed)}
                                        //           checked={false}
                                        //         />
                                        //       </div>
                                        //       <div className="col-auto d-flex" >
                                        //         <div className="w-100">
                                        //           <label
                                        //             className="form-label"
                                        //           >
                                        //             Complete
                                        //           </label>
                                        //         </div>
                                        //       </div>
                                        //     </div>
                                        null}
                                      {state.isEditTask === true &&
                                        isCreateTask !== true &&
                                        state.selectedManageTaskTabIndex.text ===
                                        "Details" ? (
                                        <div className="col-auto d-none">
                                          <DropDownButtonComponent
                                            items={[
                                              {
                                                id: "Delete",
                                                text: "Delete",
                                                iconCss: "fas fa-trash",
                                              },
                                            ]}
                                            cssClass="primary-custom-button e-flat default e-caret-hide square-btn d-none task-more-button"
                                            select={onMoreMenuClicked.bind(this)}
                                          >
                                            <div className="icon-ellipsis-horizontal icon-ellipsis-sm">
                                              <span className="icon-ellipsis-dot"></span>
                                            </div>
                                          </DropDownButtonComponent>
                                        </div>
                                      ) : null}
                                      {false &&
                                        state.isEditTask === true &&
                                        isCreateTask !== true &&
                                        state.selectedManageTaskTabIndex.text ===
                                        "Details" ? (
                                        <div className="col-auto">
                                          <div className="row g-2">
                                            <div className="col-auto d-none d-sm-inline-block">
                                              <DropDownButtonComponent
                                                items={[
                                                  // {
                                                  //   id: "focuslist",
                                                  //   text:
                                                  //     //isFocused  ? "Remove from focus list" :
                                                  //     "Add to focus list",
                                                  //   iconCss: "fas fa-bookmark",
                                                  // },
                                                  {
                                                    id: "Delete",
                                                    text: "Delete",
                                                    iconCss: "fas fa-trash",
                                                  },
                                                ]}
                                                cssClass="primary-custom-button task-more-button e-caret-hide"
                                                // content="More"
                                                select={onMoreMenuClicked.bind(
                                                  this
                                                )}
                                                iconCss={
                                                  RAFButtonConstant.More.IconCss
                                                }
                                              ></DropDownButtonComponent>
                                            </div>
                                            <div className="col-auto d-sm-none">
                                              <DropDownButtonComponent
                                                items={[
                                                  // {
                                                  //   id: "focuslist",
                                                  //   text:
                                                  //     //isFocused? "Remove from focus list":
                                                  //     "Add to focus list",
                                                  //   iconCss: "fas fa-bookmark",
                                                  // },
                                                  {
                                                    id: "Delete",
                                                    text: "Delete",
                                                    iconCss: "fas fa-trash",
                                                  },
                                                ]}
                                                cssClass="primary-custom-button e-flat default e-caret-hide square-btn me-2 task-more-button"
                                                select={onMoreMenuClicked.bind(
                                                  this
                                                )}
                                              >
                                                <div className="icon-ellipsis-horizontal icon-ellipsis-sm">
                                                  <span className="icon-ellipsis-dot"></span>
                                                </div>
                                              </DropDownButtonComponent>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                      {/* <div className="col-auto hidden">
                                  {isNotNullAndUndefined(taskRow) && (
                                    <RAFAddToFocusList
                                      relatedTo={taskRow.Title}
                                      relatedToUID={taskRow.UID}
                                      relatedToType={contextModuleName}
                                      showAddToFocusListDialog={showAddToFocusListDialog}
                                      onClose={() => closeAddToFocusListDialog()}
                                    />
                                  )}
                                </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div>
                            {state.showCompleteTaskAlertDialogContent ===
                              true ? (
                              <DialogComponent
                                id={`completeTaskAlertDialogContent_${moduleName}`}
                                header="Complete Task"
                                showCloseIcon
                                visible={
                                  state.showCompleteTaskAlertDialogContent
                                }
                                cssClass="centerDialog-sm createEditForm full-height form-center-dialog"
                                content={completeTaskAlertContent.bind(this)}
                                isModal
                                target="body"
                                closeOnEscape={false}
                                close={showCompleteTaskAlertDialogClose.bind(
                                  this
                                )}
                                zIndex={1200}
                                open={PreventFocusOnDialogOpen}
                              ></DialogComponent>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="mb-1" id="manage_Task_outerDiv"></div>
                      );
                    }
                  }}
                </MyTeamsContext.Consumer>
              );
            }}
          </RAFAttributesContext.Consumer>
        </RAFAttributeRelatedListProvider>
      </RAFEntityProvider>
    );
  } else {
    return <div className="mb-1" id={`manage_Task_outerDiv_${objGuid}`}></div>;
  }
}

export default React.memo(withRouter(ManageTask));
