import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from '../../../RAFComponents/models/Common/RAFEntityBase';

//export class UserGroupRow extends RAFEntityBase {
export class IUserGroupRow {
    //Id?: number;
    //Id?: number;
    UID?: string;
    TeamName?: string;
    Description?: string;
    UserGroupType?: string;
    IsGlobal?: boolean;
    IsSystem?: boolean;
    TagsListJson?: string[];
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    LastActivityDate?: Date;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}
export class UserGroupRow extends IUserGroupRow implements RAFEntityBase {

    getClassName?() {//use entityname
        return 'team';
        //return 'team';
    }
    getIdField?(): string {
        return propertyOf<UserGroupRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "Team/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "Team/Save";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "Team/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}

